export type FixCurrencies =
  | 'USDKRW'
  | 'USDINR'
  | 'USDIDR'
  | 'USDMYR'
  | 'USDTWD'
  | 'USDPHP'
  | 'EURKRW'
  | 'EURINR'
  | 'EURIDR'
  | 'EURMYR'
  | 'EURTWD'
  | 'EURPHP'
  | 'JPYKRW'
  | 'USDBRL'
  | 'USDCOP'
  | 'USDCLP'
  | 'EURBRL'
  | 'EURCOP'
  | 'EURCLP'
  | 'BRLMXN'
  | 'CLPCOP'
  | 'BRLJPY'
  | 'GBPBRL'

const Fixes: Record<FixCurrencies, Array<string>> = {
  USDKRW: ['KFTC18'],
  USDINR: ['FBIL', 'WMR 1600LDN'],
  USDIDR: ['JISDOR'],
  USDMYR: ['MYR1400KL'],
  USDTWD: ['TAIFX1'],
  USDPHP: ['BAPPESO'],
  EURKRW: [
    'KFTC30/BFIX1100SEO',
    'KFTC30/BFIX1100TOK',
    'KFTC30/BFIX1400SE2D',
    'KFTC30/BFIX1400SEO',
    'KFTC30/BFIX1400TOK',
    'KFTC30/WMR1100SEO',
    'KFTC30/WMR1100TOK',
    'KFTC30/WMR1400SEO',
    'KFTC30/WMR1400TOK'
  ],
  EURINR: ['FBIL/BFIX1530TOK', 'FBIL/WMR1530TOK'],
  EURIDR: [
    'JISDOR/BFIX1000JAK',
    'JISDOR/BFIX1200TOK',
    'JISDOR/WMR0800LON',
    'JISDOR/WMR1000JAK',
    'JISDOR/WMR1200TOK'
  ],
  EURMYR: ['MYR1400KL/BFIX1500TOK'],
  EURTWD: [
    'TAIFX1/BFIX100SG',
    'TAIFX1/BFIX100TAI',
    'TAIFX1/BFIX1200TK',
    'TAIFX1/WMR1100TAI',
    'TAIFX1/WMR1200TOK'
  ],
  EURPHP: ['BAPPESO/BFIX1100MNL', 'BAPPESO/BFIX1200TOK', 'BAPPESO/WMR1200TOK'],
  JPYKRW: [
    'KFTC18/WMR1600LON',
    'KFTC30/BFIX1100SEO',
    'KFTC30/BFIX1100TOK',
    'KFTC30/BFIX1400SEO',
    'KFTC30/BFIX1400TOK',
    'KFTC30/WMR1100SEO',
    'KFTC30/WMR1100TOK',
    'KFTC30/WMR1400SEO',
    'KFTC30/WMR1400TOK'
  ],
  USDBRL: ['PTAX', 'WMR 1600LDN'],
  USDCOP: ['COTCRM'],
  USDCLP: ['CLPOB', 'OBSERVADO'],
  EURBRL: [
    'PTAX',
    'PTAX/BFIX 1300LON',
    'PTAX/BFIX 1300LON 5DP',
    'PTAX/BFIX 1600LON',
    'WMR 1600LON',
    'WMRPTAX'
  ],
  EURCOP: ['BFIX 1600LON/COP', 'WMR/COP'],
  EURCLP: ['WMRCLPOB'],
  BRLMXN: ['WMRPTAX'],
  CLPCOP: ['CLPOB / COTCRM'],
  BRLJPY: ['WMRPTAX'],
  GBPBRL: ['WMRPTAX']
}

export default Fixes
