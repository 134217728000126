import { FieldArrayName } from '../../../input/input'
import { tableValueIsString, RowType } from '../../tableRow'

interface GenerateModifiedRowInputParams {
  fieldArrayName?: FieldArrayName
  columnIndex?: number
  rowInputProps: RowType['rowInputProps']
}

export default function generateModifiedRowInputProps({
  columnIndex,
  fieldArrayName,
  rowInputProps
}: GenerateModifiedRowInputParams) {
  if (tableValueIsString(rowInputProps) || !fieldArrayName) {
    return rowInputProps
  }

  return {
    ...rowInputProps,
    name: `${fieldArrayName}[${columnIndex}].${rowInputProps.name}`,
    fieldArrayPrefix: `${fieldArrayName}[${columnIndex}]`
  }
}
