import { enum_axe_ccypair_enum } from '../../components/dialog/editAxeDialog/__generated__/editAxeDialogFragment.graphql'

type ccyPairPrecision = Record<
  enum_axe_ccypair_enum,
  {
    pipPrecision: number
    spotForwardTogglePrecision: number
    strikeTogglePrecision: number
  }
>

const CCYPairPrecision: ccyPairPrecision = {
  EURUSD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  EURJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  GBPUSD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  EURGBP: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDCAD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDCHF: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  EURCHF: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  AUDUSD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  NZDUSD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDSEK: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0025,
    strikeTogglePrecision: 0.0025
  },
  USDNOK: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0025,
    strikeTogglePrecision: 0.0025
  },
  EURSEK: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0025,
    strikeTogglePrecision: 0.0025
  },
  EURNOK: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0025,
    strikeTogglePrecision: 0.0025
  },
  EURNZD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  EURAUD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  EURCAD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  GBPAUD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  GBPNZD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  GBPCAD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  GBPNOK: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  GBPSEK: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0025,
    strikeTogglePrecision: 0.0025
  },
  GBPCHF: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  CHFNOK: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0025,
    strikeTogglePrecision: 0.0025
  },
  CHFSEK: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0025,
    strikeTogglePrecision: 0.0025
  },
  AUDNZD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  NZDCAD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  AUDCAD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  CADCHF: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  GBPJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  CADJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  CHFJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  AUDJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  NZDJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  SEKJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.0025,
    strikeTogglePrecision: 0.0025
  },
  NOKJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.0025,
    strikeTogglePrecision: 0.0025
  },
  NOKSEK: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.0001
  },
  TRYJPY: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  MXNJPY: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDCNH: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDMXN: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0025
  },
  USDTRY: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0025
  },
  USDZAR: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0025
  },
  USDRUB: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0025
  },
  USDILS: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDSGD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDHKD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  EURCNH: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  EURMXN: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0025
  },
  EURTRY: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0025
  },
  EURZAR: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0025
  },
  EURRUB: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0025
  },
  EURSGD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  EURHKD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  CNHJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  TRYZAR: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  GBPHKD: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  HKDJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  EURPLN: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  EURHUF: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  EURCZK: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.001,
    strikeTogglePrecision: 0.001
  },
  EURDKK: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDPLN: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDHUF: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.01
  },
  USDCZK: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.001,
    strikeTogglePrecision: 0.001
  },
  USDKRW: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.001,
    strikeTogglePrecision: 0.25
  },
  USDINR: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.005,
    strikeTogglePrecision: 0.005
  },
  USDIDR: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.5,
    strikeTogglePrecision: 1
  },
  USDMYR: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0025
  },
  USDTWD: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.005,
    strikeTogglePrecision: 0.005
  },
  USDPHP: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.005,
    strikeTogglePrecision: 0.005
  },
  EURKRW: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.025
  },
  EURINR: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.005,
    strikeTogglePrecision: 0.005
  },
  EURIDR: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.5,
    strikeTogglePrecision: 1
  },
  EURMYR: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  EURTWD: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.005,
    strikeTogglePrecision: 0.005
  },
  EURPHP: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.005,
    strikeTogglePrecision: 0.005
  },
  JPYKRW: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0025
  },
  USDBRL: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  USDCOP: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.5,
    strikeTogglePrecision: 1
  },
  USDCLP: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.01,
    strikeTogglePrecision: 0.25
  },
  EURBRL: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  BRLMXN: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.001,
    strikeTogglePrecision: 0.0001
  },
  BRLJPY: {
    pipPrecision: 0.01,
    spotForwardTogglePrecision: 0.005,
    strikeTogglePrecision: 0.005
  },
  GBPBRL: {
    pipPrecision: 0.0001,
    spotForwardTogglePrecision: 0.0001,
    strikeTogglePrecision: 0.0001
  },
  // This value is required but we don't want the warning as it won't be used
  // eslint-disable-next-line relay/no-future-added-value
  '%future added value': {
    pipPrecision: 0,
    spotForwardTogglePrecision: 0,
    strikeTogglePrecision: 0
  }
} as const

export default CCYPairPrecision
