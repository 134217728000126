import React from 'react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import PopOver, { GenericPopOverProps, Variant } from '../popOver/popOver'

export type PopOverProps = GenericPopOverProps & {
  popOverText: string
}

export default function PopOverWithText({
  popOverText,
  variant = Variant.DEFAULT,
  ...props
}: PopOverProps) {
  return (
    <PopOver variant={variant} {...props}>
      <div className="flex gap-x-3">
        {variant === Variant.ERROR && (
          <ExclamationTriangleIcon
            aria-label="Error icon"
            className="fill-errorRed grow-0"
            width="24px"
          />
        )}
        <p className="flex-1">{popOverText}</p>
      </div>
    </PopOver>
  )
}
