import { InputProps } from '../../components/input/input'

export type ReducerAction = {
  type:
    | 'reset'
    | 'set_over_typed'
    | 'set_reset_request'
    | 'set_reset_request_all'
    | 'unset_reset_request'
    | 'set_contains_fetched_value'
    | 'set_to_changing'
    | 'unset_from_changing'
    | 'set_warning'
    | 'unset_warning'
    | 'set_is_disabled'
    | 'unset_is_disabled'
  key: InputProps['name'] | 'all'
  payload?: string | number
}

export type InputState = {
  overTyped?: boolean
  overTypedValue?: string | number
  resetRequest?: boolean
  containsFetchedValue?: boolean
  updatesDisabled?: boolean
  changing?: boolean
  warning?: boolean
  warningMessage?: string
  isDisabled?: boolean
}

export type InputStateReducerState = {
  [key: string]: InputState
}

export default function inputStateReducer(
  prevState: InputStateReducerState,
  action: ReducerAction
): InputStateReducerState {
  const key = action.key

  switch (action.type) {
    case 'set_is_disabled':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          isDisabled: true
        }
      }
    case 'unset_is_disabled':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          isDisabled: false
        }
      }
    case 'set_over_typed':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          overTyped: true,
          overTypedValue: action.payload
        }
      }
    case 'set_warning':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          warning: true,
          warningMessage: action.payload as string
        }
      }
    case 'unset_warning':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          warning: false,
          warningMessage: undefined
        }
      }
    case 'set_reset_request':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          resetRequest: true
        }
      }
    case 'set_reset_request_all':
      return Object.keys(prevState).reduce(
        (acc, key) => {
          if (prevState[key].overTyped) {
            acc[key] = {
              ...prevState[key],
              resetRequest: true
            }
          }

          return acc
        },
        { ...prevState }
      )
    case 'unset_reset_request':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          resetRequest: false,
          overTyped: false,
          overTypedValue: undefined
        }
      }
    case 'set_contains_fetched_value':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          containsFetchedValue: true
        }
      }
    case 'set_to_changing':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          changing: true
        }
      }
    case 'unset_from_changing':
      return {
        ...prevState,
        [key]: {
          ...prevState[key],
          changing: false
        }
      }
    case 'reset':
      return {}
    default:
      throw Error('Unknown action.')
  }
}
