import { Combobox } from '@headlessui/react'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { ChangeEvent } from 'react'

interface SearchProps {
  name: string
  placeholder?: string
  variant?: SearchVariant
  dropDownItems?: string[]
  onComboBoxChange?: (event: string) => void
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void
  value?: string
}

export enum SearchVariant {
  Filter,
  History,
  Tier
}

export default function Search({
  name,
  placeholder,
  onComboBoxChange,
  onInputChange,
  dropDownItems,
  variant = SearchVariant.Filter,
  value
}: SearchProps) {
  let iconColor = 'black'

  if (variant === SearchVariant.History) {
    iconColor = 'lightGray'
  }

  return (
    <Combobox name={name} onChange={onComboBoxChange}>
      <Combobox.Button
        className={classNames({
          'relative w-full cursor-default text-left sm:text-sm rounded-xl mt-2':
            variant === SearchVariant.Filter,
          'relative w-[250px] font-normal cursor-default text-left sm:text-sm rounded-xl':
            variant === SearchVariant.History,
          'relative w-[300px] font-normal cursor-default text-left sm:text-sm rounded-xl':
            variant === SearchVariant.Tier
        })}
      >
        <span className="pointer-events-none absolute z-10 inset-y-0 left-0 flex items-center px-2">
          <MagnifyingGlassIcon
            height="16px"
            width="16px"
            aria-hidden="true"
            color={iconColor}
            className="text-gray dark:text-white"
          />
        </span>
        <input
          placeholder={placeholder}
          onChange={onInputChange}
          className={classNames(
            {
              'dark:text-white bg-white dark:bg-darkBlueGray':
                variant === SearchVariant.Filter,
              'dark:text-white bg-white dark:bg-overlayBlue':
                variant === SearchVariant.History,
              'bg-lightGray dark:text-textLightGray dark:bg-overlayBlue':
                variant === SearchVariant.Tier
            },
            'block truncate relative w-full cursor-default rounded-xl py-2 pl-10 pr-6 text-baseGray text-left border-lightGray ring-lightGray text-[12px] leading-4 dark:border-none'
          )}
          value={value}
        />
        <span className="absolute z-10 inset-y-0 right-0 flex items-center px-2 cursor-pointer">
          {value && (
            <XMarkIcon
              height="16px"
              width="16px"
              aria-hidden="true"
              color={iconColor}
              className="text-gray dark:text-white"
              onClick={() =>
                onInputChange({
                  target: { value: '' }
                } as ChangeEvent<HTMLInputElement>)
              }
            />
          )}
        </span>
      </Combobox.Button>
      {dropDownItems && (
        <Combobox.Options
          className={classNames(
            {
              'bg-white dark:bg-darkBlueGray': variant === SearchVariant.Filter,
              'bg-white dark:bg-overlayBlue': variant === SearchVariant.History,
              'dark:text-textLightGray dark:bg-overlayBlue':
                variant === SearchVariant.Tier
            },
            'absolute z-10 mt-1 min-w-[250px] max-h-60 overflow-auto rounded-md dark:text-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
          )}
        >
          {dropDownItems.map((option) => (
            <Combobox.Option
              key={option}
              value={option}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-3 pr-4 ${
                  active
                    ? 'bg-fadedBlue dark:bg-royalBlue dark:text-white text-deepBlue'
                    : 'text-textGray dark:text-white'
                }`
              }
            >
              <span>{option}</span>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      )}
    </Combobox>
  )
}
