import { ValidPathArray } from '../../formDataProcessor'

/**
 * Checks if the diff object output from the deep-diff package contains:
 * - kind === 'A' (indicates change occurred within an array - see https://www.npmjs.com/package/deep-diff#differences)
 * - arrayName === 'legs' (indicates change occurred within the 'legs' array)
 * - index === undefined (indicates change did not happen in an existing leg but was caused by adding a new leg)
 *
 * If all conditions are met, the change was caused by adding a new leg.
 */
const isDiffCausedByAddingNewLeg = (path: ValidPathArray, kind: string) => {
  const [arrayName, index] = path

  return kind === 'A' && arrayName === 'legs' && index === undefined
}

export default isDiffCausedByAddingNewLeg
