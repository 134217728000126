/* eslint-disable relay/must-colocate-fragment-spreads */

import { groupBy } from 'lodash'
import React, { useEffect } from 'react'
import { PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import { TierNames } from '../../../components/forms/createAxe/createAxeTiers'
import { Entity } from '../../../components/tierSelector/tierList/tierList'
import { v4 as uuidv4 } from 'uuid'
import { Enum_Tiers_Enum } from '../../../gql'
import { RemovedTierColumn, TierColumn } from './tiers'
import {
  userTierComponentQuery,
  userTierComponentQuery$data
} from './__generated__/userTierComponentQuery.graphql'
import { createAxeDialogButtonTiersFragment$data } from '../../../components/buttons/createAxeDialogButton/__generated__/createAxeDialogButtonTiersFragment.graphql'
import SuspendUserDialog from '../../../components/dialog/suspendUserDialog/suspendUserDialog'
import Dialog from '../../../components/dialog/dialog'
import { DndProvider } from 'react-dnd'
import TierSelectorContextProvider from '../../../components/tierSelector/hooks/tierSelectorContext'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TopOfTierPage } from './topOfTierPage'
import SendQueryDialog from '../../../components/dialog/sendQueryDialog/sendQueryDialog'
import getIDFromBase64 from '../../../utils/getIDFromBase64/getIDFromBase64'
import useSubOrgUserRef from '../../../components/tierSelector/hooks/useSubOrgUserRef'
import { Tier } from '../../../components/tierSelector/tierSelector'

export const UserTierComponentQuery = graphql`
  query userTierComponentQuery($id: String!) {
    user_main_tiering_connection(where: { ownerId: { _eq: $id } }) {
      edges {
        node {
          id
          organizationId
          subOrganizationId
          tier
          isRemoved
          user_main_tiering_subOrganization {
            id
            name
          }
          user_main_tiering_organization {
            id
            displayName
          }
        }
      }
    }
    users_connection(where: { id: { _eq: $id } }) {
      ...suspendUserDialogFragment
    }
    organizations_connection {
      ...sendQueryDialogFragment
    }
  }
`

export const UserTierComponentMutation = graphql`
  mutation userTierComponentMutation($objects: [user_main_tiering_updates!]!) {
    update_user_main_tiering_many(updates: $objects) {
      returning {
        id
        subOrganizationId
        tier
        organizationId
        isRemoved
      }
    }
  }
`

/**
 * Create the entities for each organization and sub-organization
 * but without users. Users are added in the TierColumn component.
 */
export const entityCreation = (
  edges: userTierComponentQuery$data['user_main_tiering_connection']['edges']
): Entity[] => {
  const orgsAndSubOrgs = groupBy(edges, (edge) => edge.node.organizationId)

  const entities: Entity[] = Object.keys(orgsAndSubOrgs).map((key) => {
    const orgName =
      orgsAndSubOrgs[key].length > 0
        ? orgsAndSubOrgs[key][0].node.user_main_tiering_organization
            ?.displayName
        : key

    return {
      id: getIDFromBase64(orgsAndSubOrgs[key][0].node.id) || key,
      name: orgName || key,
      open: true,
      members: orgsAndSubOrgs[key].map((edge) => {
        const memberName =
          edge.node.user_main_tiering_subOrganization === null
            ? edge.node.subOrganizationId
            : edge.node.user_main_tiering_subOrganization?.name

        return {
          id: edge.node.subOrganizationId,
          name: memberName || edge.node.subOrganizationId,
          organizationId: key,
          isRemoved: edge.node.isRemoved || undefined
        }
      })
    }
  })

  return entities
}

// In the scenario we are editing existing Tiering data, we need to create a static id for each tier
// Otherwise on each rerender of the component we get a different UUID
export const getTierData = (
  entityEdges:
    | userTierComponentQuery$data['user_main_tiering_connection']['edges']
    | createAxeDialogButtonTiersFragment$data['edges'],
  staticTierId = false
): Tier[] => {
  const t1IdForMainTier = 't1IdForMainTier'
  const t2IdForMainTier = 't2IdForMainTier'
  const t3IdForMainTier = 't3IdForMainTier'

  return [
    {
      name: TierNames.Tier1,
      id: staticTierId ? t1IdForMainTier : uuidv4(),
      entities: entityEdges
        ? entityCreation(
            entityEdges.filter((edge) => edge.node.tier === Enum_Tiers_Enum.T_1)
          )
        : []
    },
    {
      name: TierNames.Tier2,
      id: staticTierId ? t2IdForMainTier : uuidv4(),
      entities: entityEdges
        ? entityCreation(
            entityEdges.filter((edge) => edge.node.tier === Enum_Tiers_Enum.T_2)
          )
        : []
    },
    {
      name: TierNames.Tier3,
      id: staticTierId ? t3IdForMainTier : uuidv4(),
      entities: entityEdges
        ? entityCreation(
            entityEdges.filter((edge) => edge.node.tier === Enum_Tiers_Enum.T_3)
          )
        : []
    }
  ]
}

export const getRemovedData = (
  entityEdges:
    | userTierComponentQuery$data['user_main_tiering_connection']['edges']
    | createAxeDialogButtonTiersFragment$data['edges']
) => {
  const removedEdges = entityEdges.filter((edge) => edge.node.isRemoved)

  return entityCreation(removedEdges)
}

interface UserTierComponentProps {
  colorScheme: string
  queryRef: PreloadedQuery<userTierComponentQuery>
  onTraderSelect: (data: { id: string; fullName: string }) => void
  isSales: boolean
}

export default function UserTierComponent({
  colorScheme,
  queryRef,
  onTraderSelect,
  isSales
}: UserTierComponentProps) {
  const tierData = usePreloadedQuery<userTierComponentQuery>(
    UserTierComponentQuery,
    queryRef
  )

  const tiers: Tier[] = getTierData(
    tierData.user_main_tiering_connection.edges,
    true
  )

  const subOrgUsersQueryRef = useSubOrgUserRef(
    tierData.user_main_tiering_connection
  )

  const [openSuspend, setOpenSuspend] = React.useState<boolean>(false)
  const [openSendQuery, setOpenSendQuery] = React.useState<boolean>(false)
  const [tierLists, setTiersLists] = React.useState<Tier[]>(tiers)
  const [openRemovedTiers, setOpenRemovedTiers] = React.useState(false)

  const removedSubOrgs: Entity[] = getRemovedData(
    tierData.user_main_tiering_connection.edges
  )

  useEffect(() => {
    setTiersLists(tiers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const allSubOrgs = tierData.user_main_tiering_connection.edges.map((edge) => {
    return {
      name: edge.node.user_main_tiering_subOrganization?.name || '',
      id: edge.node.user_main_tiering_subOrganization?.id || ''
    }
  })

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <TierSelectorContextProvider
          tiers={tierLists}
          removedSubOrgs={removedSubOrgs}
        >
          <TopOfTierPage
            colorScheme={colorScheme}
            setOpenRemovedTiers={setOpenRemovedTiers}
            tierData={tierData}
            tierLists={tierLists}
            setOpenSuspend={setOpenSuspend}
            setOpenSendQuery={setOpenSendQuery}
            isSales={isSales}
            onTraderSelect={onTraderSelect}
          />

          {subOrgUsersQueryRef && (
            <div className="flex-grow">
              <div className="grid grid-cols-3 py-4 px-2 h-full">
                <TierColumn
                  name={TierNames.Tier1}
                  entities={tierLists[0].entities}
                  id={tierLists[0].id}
                  setTiersLists={setTiersLists}
                  isSales={isSales}
                  subOrgUsersQueryRef={subOrgUsersQueryRef}
                />
                <TierColumn
                  name={TierNames.Tier2}
                  entities={tierLists[1].entities}
                  id={tierLists[1].id}
                  setTiersLists={setTiersLists}
                  isSales={isSales}
                  subOrgUsersQueryRef={subOrgUsersQueryRef}
                />
                <TierColumn
                  name={TierNames.Tier3}
                  entities={tierLists[2].entities}
                  id={tierLists[2].id}
                  setTiersLists={setTiersLists}
                  isSales={isSales}
                  subOrgUsersQueryRef={subOrgUsersQueryRef}
                />
              </div>
            </div>
          )}

          {setOpenRemovedTiers && (
            <Dialog
              open={openRemovedTiers}
              setOpen={setOpenRemovedTiers}
              title={'Removed'}
            >
              <RemovedTierColumn
                name={'Removed'}
                id={'removed'}
                tiers={tierLists}
              />
            </Dialog>
          )}
        </TierSelectorContextProvider>
      </DndProvider>
      {setOpenSuspend && (
        <SuspendUserDialog
          setOpenSuspend={setOpenSuspend}
          openSuspend={openSuspend}
          allSubOrgs={allSubOrgs}
          currentUser={tierData.users_connection}
        />
      )}

      {setOpenSendQuery && (
        <SendQueryDialog
          setOpen={setOpenSendQuery}
          opened={openSendQuery}
          currentUser={tierData.organizations_connection}
        />
      )}
    </>
  )
}
