import {
  FieldArrayName,
  FieldArrayPrefix
} from './../../components/input/input'
import {
  PostPriceForwardRequestBody,
  PostPriceForwardResponse,
  PostPriceOptionRequestBody,
  PostPriceOptionResponse,
  PostPriceSpotRequestBody,
  PostPriceSpotResponse
} from '../../types/marketData/service'
import {
  AxeFormData,
  AxeFormLegData
} from '../../components/dialog/editAxeDialog/editAxeDialog'
import {
  enum_axe_callput_enum,
  enum_axe_buysell_enum
} from '../../components/rfqBlotterRow/__generated__/rfqBlotterTableRowRfqFragment.graphql'
import { PostMessage } from '../../workers/marketDataWorker/marketDataWorker'
import generateFieldName from '../generateFieldName/generateFieldName'
import { Enum_Axe_Buysell_Enum, Enum_Axe_Callput_Enum } from '../../gql'

export type MarketData =
  | PostPriceSpotResponse
  | PostPriceForwardResponse
  | PostPriceOptionResponse

export type RequestBody =
  | PostPriceOptionRequestBody
  | PostPriceForwardRequestBody
  | PostPriceSpotRequestBody

export const marketDataKeyMap = {
  Spot: 'spot',
  SpotDate: 'spotDate',
  ForwardPoints: 'swaps',
  Forward: 'forward',
  DepoCcy1: 'depoCcy1',
  DepoCcy1Daycount: 'depoCcy1DayCount',
  DepoCcy2: 'depoCcy2',
  DepoCcy2Daycount: 'depoCcy2DayCount',
  Premium: 'premium',
  PremiumDate: 'premiumDate',
  DeliveryDate: 'deliveryDate',
  ExpiryDate: 'expiryDate',
  Delta: 'delta',
  Gamma: 'gamma',
  Vega: 'vega',
  Strike: 'marketStrike'
} as const

const findKey = (key: keyof MarketData): keyof AxeFormLegData => {
  return marketDataKeyMap[key]
}

/**
 * The delta/premium value we get back from the market endpoint doesn't take into account the buy/sell
 * direction of the trade. This puts the delta/premium in the correct sign based on the option type.
 * e.g:
 * BUY base currency call - delta/premium positive
 * SELL base currency call - delta/premium negative
 * BUY base currency put - delta/premium negative
 * SELL base currency put - delta/premium positive
 */
export const putDeltaAndPremiumInCorrectSign = (
  value: number,
  callPut?: enum_axe_callput_enum | null,
  buySell?: enum_axe_buysell_enum | null
): number => {
  // We need to do check the value value hasn't already been corrected
  const isPositive = value >= 0
  const isCall = callPut === Enum_Axe_Callput_Enum.Call
  const isBuy = buySell === Enum_Axe_Buysell_Enum.Buy

  const sign = isCall === isBuy ? 1 : -1

  return isPositive ? value * sign : value * -sign
}

export const putGammaAndVegaInCorrectSign = (
  value: number,
  buySell?: enum_axe_buysell_enum | null
): number => {
  // We need to do check the value hasn't already been corrected
  const isPositive = value >= 0
  const isBuy = buySell === Enum_Axe_Buysell_Enum.Buy

  const sign = isBuy ? 1 : -1

  return isPositive ? value * sign : value * -sign
}

const getValue = (value: MarketData, index = 0) =>
  Array.isArray(value) ? value[index] : value

/**
 * Maps the keys of the market data response to the keys expected by the UI
 * @param marketData
 * @param arrayNamePrefix
 */
export default function mapMarketDataKeys(
  marketData: MarketData,
  arrayNamePrefix: FieldArrayPrefix | null
): Partial<AxeFormLegData> | null {
  if (Object.keys(marketData).includes('error')) {
    self.postMessage({
      requestError: true,
      fetching: false
    } as PostMessage)

    return null
  }

  const entries = Object.entries(marketData) as Array<
    [keyof MarketData | 'error', MarketData]
  >

  const formData = entries.reduce((acc: Partial<AxeFormData>, [key, value]) => {
    const formKey = findKey(key as keyof MarketData) || key

    // @ts-expect-error - When asserting to "keyof AxeFormData", TS throws readonly error
    acc[generateFieldName(formKey, arrayNamePrefix)] = getValue(value)

    return acc
  }, {})

  return formData
}

interface MapMarketDataPriceOptionKeysParams {
  arrayName: FieldArrayName | null
  axeFormValues: Partial<AxeFormData>
  legsCheckedStatus: boolean[]
  marketData: PostPriceOptionResponse
  requestBody: PostPriceOptionRequestBody
}

/**
 * Maps the keys of the market data response to the keys expected by the UI
 * @param marketData
 * @param requestBody
 */
export function mapMarketDataPriceOptionKeys({
  arrayName,
  axeFormValues,
  legsCheckedStatus,
  marketData,
  requestBody
}: MapMarketDataPriceOptionKeysParams): Partial<AxeFormData> | null {
  if (Object.keys(marketData).includes('error')) {
    self.postMessage({
      requestError: true,
      fetching: false
    } as PostMessage)

    return null
  }

  let marketDataIndex = 0

  return legsCheckedStatus.reduce(
    (acc: Partial<AxeFormData>, isLegValid, index: number) => {
      if (!isLegValid) return acc

      const entries = Object.entries(marketData) as Array<
        [keyof MarketData | 'error', MarketData]
      >

      const fieldArrayPrefix = arrayName
        ? (`${arrayName}[${index}]` as FieldArrayPrefix)
        : null

      const legValues = arrayName
        ? axeFormValues?.legs?.[index]
        : (axeFormValues as AxeFormLegData)

      entries.forEach(([key, value]) => {
        const formKey = findKey(key as keyof MarketData) || key

        // @ts-expect-error - When asserting to "keyof AxeFormData", TS throws readonly error
        acc[generateFieldName(formKey, fieldArrayPrefix)] = getValue(
          value,
          marketDataIndex
        )

        if (formKey === 'premium') {
          // @ts-expect-error - When asserting to "keyof AxeFormData", TS throws readonly error
          acc[generateFieldName('premiumCurrency', fieldArrayPrefix)] =
            requestBody.PriceOption.Premium.Currency
        }

        if (formKey === 'delta') {
          // The RFQ table needs to display the original BS delta
          // @ts-expect-error - When asserting to "keyof AxeFormData", TS throws readonly error
          acc[generateFieldName('bsDelta', fieldArrayPrefix)] =
            putDeltaAndPremiumInCorrectSign(
              getValue(value, marketDataIndex),
              legValues?.callPut,
              legValues?.buySell
            )
        }

        // Overwrites the accumulator's key-value pair with the correctly signed delta or premium
        if (formKey === 'delta' || formKey === 'premium') {
          // @ts-expect-error - When asserting to "keyof AxeFormData", TS throws readonly error
          acc[generateFieldName(formKey, fieldArrayPrefix)] =
            putDeltaAndPremiumInCorrectSign(
              getValue(value, marketDataIndex),
              legValues?.callPut,
              legValues?.buySell
            )
        }

        if (formKey === 'vega' || formKey === 'gamma') {
          // @ts-expect-error - When asserting to "keyof AxeFormData", TS throws readonly error
          acc[generateFieldName(formKey, fieldArrayPrefix)] =
            putGammaAndVegaInCorrectSign(
              getValue(value, marketDataIndex),
              legValues?.buySell
            )
        }
      })

      marketDataIndex++

      return acc
    },
    {}
  )
}
