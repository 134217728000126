import { useFormikContext } from 'formik'
import { useInputStateContext } from '../../../../context/inputStateContext/inputStateContext'
import { FormDataProcessorConfig } from '../../config/config'
import { FieldNamePathArray } from '../../formDataProcessor'
import processFieldConfig from '../../utils/processFieldConfig/processFieldConfig'
import { FormData } from '../../config/config'

const useProcessFieldConfig = (config: FormDataProcessorConfig) => {
  const formik = useFormikContext<FormData>()
  const inputStateContext = useInputStateContext()

  return (path: FieldNamePathArray) => {
    return processFieldConfig({
      config,
      formik,
      inputStateContext,
      path
    })
  }
}

export default useProcessFieldConfig
