import isObject from 'lodash/isObject'
import isEmpty from 'lodash/isEmpty'

const nestedObjectHasAllNilValues = (obj: object): boolean => {
  if (!isObject(obj) || isEmpty(obj)) return false

  return Object.values(obj).every((value) => {
    if (isObject(value)) {
      return nestedObjectHasAllNilValues(value)
    }

    return value === null || value === undefined
  })
}

export default nestedObjectHasAllNilValues
