import { InputProps } from '../../../../components/input/input'
import { Axe_Legs } from '../../../../gql'
import { InputStateContextProps } from '../../../inputStateContext/inputStateContext'
import {
  MarketDataValues,
  AxeFormValues,
  leg1SpotFieldName,
  calculateForward
} from '../../marketDataWorkerContext'
import calculateSwapsForwardUsingSpot from '../calculateSwapsForwardUsingSpot/calculateSwapsForwardUsingSpot'

interface CalculateSpotSwapsForwardOnRefreshAllParams {
  dispatch: InputStateContextProps['dispatch']
  marketDataValues: MarketDataValues
  sourceLegIndex: number
  state: InputStateContextProps['state']
  values: AxeFormValues
}

function calculateSpotSwapsForwardOnRefreshAll({
  dispatch,
  marketDataValues,
  sourceLegIndex,
  state,
  values
}: CalculateSpotSwapsForwardOnRefreshAllParams) {
  // Identify the source leg as the first leg with a spot value. While typically the first leg, if it lacks a spot value, subsequent legs are checked.
  const sourceLegSpotValueFromService =
    marketDataValues[`legs[0].spot`] ||
    marketDataValues[`legs[1].spot`] ||
    marketDataValues[`legs[2].spot`]

  // Calculate the forward value for every leg by taking the spot value from each leg, along with the swaps, for the calculation.
  return values.legs.reduce(
    (acc: MarketDataValues, leg: Axe_Legs, legIndex: number) => {
      const swapsName: InputProps['name'] = `legs[${legIndex}].swaps`
      const forwardName: InputProps['name'] = `legs[${legIndex}].forward`

      // If a leg lacks an expiry date, only update its spot value using market data, then calculate either swaps or forward.
      if (!leg.expiryDate) {
        acc[leg1SpotFieldName] = sourceLegSpotValueFromService

        dispatch({
          type: 'unset_reset_request',
          key: leg1SpotFieldName
        })

        return {
          ...acc,
          ...calculateSwapsForwardUsingSpot({
            dispatch,
            forwardName,
            legData: leg,
            spotValue: sourceLegSpotValueFromService,
            state,
            swapsName
          })
        }
      }

      // Update the leg with market data: if it's a source leg, refresh the spot/swaps/forward. Otherwise, only update the swaps and calculate the forward.
      if (legIndex === sourceLegIndex) {
        acc[leg1SpotFieldName] = sourceLegSpotValueFromService
        acc[swapsName] = marketDataValues[swapsName]
        acc[forwardName] = marketDataValues[forwardName]

        dispatch({ type: 'unset_reset_request', key: swapsName })
        dispatch({ type: 'unset_reset_request', key: forwardName })
      } else {
        acc[swapsName] = marketDataValues[swapsName]
        acc[forwardName] = calculateForward(
          sourceLegSpotValueFromService,
          marketDataValues[swapsName]
        )

        dispatch({ type: 'unset_reset_request', key: swapsName })
      }

      dispatch({
        type: 'unset_reset_request',
        key: leg1SpotFieldName
      })

      return acc
    },
    {} as MarketDataValues
  )
}

export default calculateSpotSwapsForwardOnRefreshAll
