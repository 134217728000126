import { graphql, useFragment } from 'react-relay/hooks'
import React from 'react'
import classNames from 'classnames'
import { colorSchemeForwardRefFragment$key } from './__generated__/colorSchemeForwardRefFragment.graphql'

type ColorSchemeForwardRefProps = React.PropsWithChildren<{
  ref?: React.ForwardedRef<HTMLDivElement>
  data: colorSchemeForwardRefFragment$key
  rootRef: React.RefObject<HTMLElement>
}>

const ColorSchemeForwardRefFragment = graphql`
  fragment colorSchemeForwardRefFragment on usersConnection {
    edges {
      node {
        colorSchemePreference
      }
    }
  }
`

const ColorSchemeForwardRef = React.forwardRef<
  HTMLDivElement,
  ColorSchemeForwardRefProps
>(function ColorSchemeForwardRef(
  { children, data, rootRef }: ColorSchemeForwardRefProps,
  forwardedRef
) {
  const { edges } = useFragment(ColorSchemeForwardRefFragment, data)
  const colorSchemePreference = edges[0]?.node?.colorSchemePreference

  if (rootRef && colorSchemePreference === 'LIGHT') {
    rootRef.current?.classList.remove('dark')
  }

  return (
    <div
      data-testid="colorSchemeForwardRef"
      ref={forwardedRef}
      className={
        (classNames({ dark: colorSchemePreference === 'DARK' }), 'h-full')
      }
    >
      <div className="bg-white dark:bg-deepBlue dark:text-white h-full">
        {children}
      </div>
    </div>
  )
})

export default ColorSchemeForwardRef
