import { graphql } from 'react-relay/hooks'
import { useFragment } from 'react-relay'
import { usersListBoxFragment$key } from './__generated__/usersListBoxFragment.graphql'
import ComboBox from '../../comboBox/comboBox'
import { useField } from 'formik'
import { VanillaFormNames } from '../../input/input'

export const UsersListBoxFragment = graphql`
  fragment usersListBoxFragment on usersConnection {
    edges {
      node {
        fullName
        id
      }
    }
  }
`

interface UsersListBox {
  isDisabled?: boolean
  name: VanillaFormNames
  users: usersListBoxFragment$key
}

export default function UsersListBox({
  isDisabled,
  name,
  users
}: UsersListBox) {
  const data = useFragment(UsersListBoxFragment, users)
  const [, { error, touched }] = useField(name)
  const options = [
    {
      name: 'Select User',
      unavailable: true
    },
    ...data.edges.map(({ node: { fullName, id } }) => ({
      name: fullName ?? 'NAME_MISSING',
      value: id
    }))
  ]

  return (
    <ComboBox
      name={name}
      options={options}
      error={touched && error}
      disabled={isDisabled}
    />
  )
}
