import { groupBy } from 'lodash'
import { PreloadedQuery, graphql, usePreloadedQuery } from 'react-relay'
import getIDFromBase64 from '../../../utils/getIDFromBase64/getIDFromBase64'
import logBrowserError from '../../../utils/logBrowserError/logBrowserError'
import { Entity, Member, MemberUser } from '../tierList/tierList'
import { useSubOrgUserDataSubOrganizationUsersQuery } from './__generated__/useSubOrgUserDataSubOrganizationUsersQuery.graphql'

export const useSubOrgUserDataQuery = graphql`
  query useSubOrgUserDataSubOrganizationUsersQuery(
    $subOrganizationUUIDs: [uuid!]
  ) {
    users_connection(
      where: { subOrganizationId: { _in: $subOrganizationUUIDs } }
    ) {
      edges {
        node {
          fullName
          subOrganization {
            id
          }
          id
        }
      }
    }
  }
`

const useSubOrgUserData = (
  subOrgUsersQueryRef: PreloadedQuery<useSubOrgUserDataSubOrganizationUsersQuery>
): ((entities: Entity[]) => Entity[]) => {
  const subOrgUserData =
    usePreloadedQuery<useSubOrgUserDataSubOrganizationUsersQuery>(
      useSubOrgUserDataQuery,
      subOrgUsersQueryRef
    )

  const groupedSubOrgUserData = groupBy(
    subOrgUserData.users_connection.edges.map(({ node }) => {
      return {
        id: node.id,
        name: node.fullName,
        subOrgId:
          node.subOrganization && getIDFromBase64(node.subOrganization?.id)
      }
    }),
    'subOrgId'
  )

  const buildEntityWithUserData = (entities: Entity[]): Entity[] =>
    entities.map((entity) => {
      const members = entity.members.map((member) => {
        const subOrgId = member.subOrganizationId || member.id

        if (!subOrgId) {
          logBrowserError('SubOrg Id missing for member: ', member.name)

          return member
        }

        const subOrgMembers: MemberUser[] = groupedSubOrgUserData[subOrgId]

        return {
          ...member,
          users: subOrgMembers || []
        } as Member
      })

      return {
        ...entity,
        members
      }
    })

  return buildEntityWithUserData
}

export default useSubOrgUserData
