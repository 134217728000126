import get from 'lodash/get'
import { InputProps } from '../../../../components/input/input'
import {
  AxeFormValues,
  MarketDataValues,
  calculateForward,
  calculateSwaps,
  leg1SpotFieldName
} from '../../marketDataWorkerContext'
import { InputStateContextProps } from '../../../inputStateContext/inputStateContext'
import { Axe_Legs } from '../../../../gql'

interface CalculateSpotSwapsForwardOnCcyPairChangeParams {
  dispatch: InputStateContextProps['dispatch']
  marketDataValues: MarketDataValues
  values: AxeFormValues
}

const calculateSpotSwapsForwardOnCcyPairChange = ({
  dispatch,
  marketDataValues,
  values
}: CalculateSpotSwapsForwardOnCcyPairChangeParams) => {
  // Retrieve the spot value for leg 1 from the market data service - this will be used to calculate the swaps and forward for each leg where applicable
  const leg1SpotValueFromService = marketDataValues[leg1SpotFieldName]

  // Loop over each leg and update spot/swaps/forward values
  return values.legs.reduce(
    (acc: MarketDataValues, leg: Axe_Legs, index: number) => {
      // Update the spot value for the current leg with the leg 1 spot value
      acc[leg1SpotFieldName] = leg1SpotValueFromService

      const swapsName: InputProps['name'] = `legs[${index}].swaps`
      const forwardName: InputProps['name'] = `legs[${index}].forward`

      const swapsValueFromService = marketDataValues[swapsName]
      const forwardValueFromService = marketDataValues[forwardName]
      const swapsValueFromFormik = get(leg, 'swaps')
      const forwardValueFromFormik = get(leg, 'forward')

      // Where we have swaps market data, update the swaps and forward for the first leg using market data
      if (swapsValueFromService && index === 0) {
        acc[swapsName] = swapsValueFromService
        acc[forwardName] = forwardValueFromService
      }
      // For other legs, where we have swaps market data, update swaps from market data and calculate forward.
      else if (swapsValueFromService) {
        acc[swapsName] = swapsValueFromService
        acc[forwardName] = calculateForward(
          leg1SpotValueFromService,
          swapsValueFromService
        )
      }
      // Use Formik's swaps value to calculate and update forward
      else if (swapsValueFromFormik) {
        acc[forwardName] = calculateForward(
          leg1SpotValueFromService,
          swapsValueFromFormik
        )
      }
      // Use Formik's forward value to calculate and update swaps.
      else if (forwardValueFromFormik) {
        acc[swapsName] = calculateSwaps(
          leg1SpotValueFromService,
          forwardValueFromFormik
        )
      }

      // Clear any overtyped flags for the spot, swaps, and forward fields.
      dispatch({ type: 'unset_reset_request', key: leg1SpotFieldName })
      dispatch({ type: 'unset_reset_request', key: swapsName })
      dispatch({ type: 'unset_reset_request', key: forwardName })

      return acc
    },
    {} as MarketDataValues
  )
}

export default calculateSpotSwapsForwardOnCcyPairChange
