const roundToDecimal = (value: number, decimalPlaces: number) => {
  if (value === 0) return 0

  return (
    Math.round((value + Number.EPSILON) * 10 ** decimalPlaces) /
    10 ** decimalPlaces
  )
}

export default roundToDecimal
