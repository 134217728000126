import Dialog from '../dialog'
import { Form, Formik } from 'formik'
import { graphql } from 'relay-runtime'
import React, { useMemo } from 'react'
import Button, { ButtonSize, ButtonVariant } from '../../buttons/button/button'
import DialogInput from '../../input/dialogInput'
import * as Yup from 'yup'
import ListBox from '../../listBox/listBox'
import { InputProps, VanillaFormNames } from '../../input/input'
import { sendQueryDialogFragment$key } from './__generated__/sendQueryDialogFragment.graphql'
import { useFragment } from 'react-relay'
import classNames from 'classnames'
import { QUERY_EMAIL_TYPE } from '../../../types/emailTypes'
import useEmailApi from '../../../hooks/useEmailApi/useEmailApi'

const SendQueryDialogFragment = graphql`
  fragment sendQueryDialogFragment on organizationsConnection {
    edges {
      node {
        displayName
      }
    }
  }
`

interface SendQueryDialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  opened: boolean
  currentUser: sendQueryDialogFragment$key
}

const schema = Yup.object().shape({
  name: Yup.string().required('Field is required').min(3),
  queryType: Yup.string().required('Field is required'),
  email: Yup.string().when('queryType', {
    is: (value: string) => value === 'user',
    then: () => Yup.string().email().required('Field is required'),
    otherwise: () => Yup.string()
  }),
  organization: Yup.string().when('queryType', {
    is: (value: string) => value === 'user',
    then: () => Yup.string().required('Field is required'),
    otherwise: () => Yup.string()
  })
})

const queryTypeProvider = [
  {
    name: 'Select',
    value: '',
    unavailable: true
  },
  {
    name: 'Missing participant',
    value: QUERY_EMAIL_TYPE.PARTICIPANT
  },
  {
    name: 'Missing user',
    value: QUERY_EMAIL_TYPE.USER
  }
]

export default function SendQueryDialog({
  setOpen,
  opened,
  currentUser
}: SendQueryDialogProps) {
  const [submitting, setSubmitting] = React.useState(false)

  const organizations = useFragment(SendQueryDialogFragment, currentUser)

  const sendEmail = useEmailApi({ setSubmitting, setOpen })

  const organizationsList = useMemo(() => {
    return [
      {
        name: 'Select',
        value: '',
        unavailable: true
      },
      ...organizations.edges.map((org: { node: { displayName: string } }) => ({
        name: org.node.displayName,
        value: org.node.displayName
      }))
    ]
  }, [organizations.edges])

  return (
    <Dialog open={opened} setOpen={setOpen} title={'Query'}>
      <Formik
        initialValues={{
          name: '',
          queryType: '',
          email: '',
          organization: ''
        }}
        validationSchema={schema}
        onSubmit={sendEmail}
        onReset={() => {
          setOpen(false)
        }}
      >
        {({ errors, touched, values }) => {
          return (
            <Form className="h-full text-sm ">
              <div
                className={classNames(
                  'flex flex-col justify-between gap-6 mt-3 min-h-[200px]'
                )}
              >
                <div className="flex flex-col">
                  <label htmlFor="type" className="mb-2">
                    Query type
                  </label>
                  <div className="shrink-0 basis-[30px] dark:border-r-lightBlue">
                    <ListBox
                      className="rounded-lg border-borderGray dark:border-darkBlueGray dark:bg-darkBlueGray border-[1px]"
                      options={queryTypeProvider}
                      name={`queryType` as InputProps<VanillaFormNames>['name']}
                      placeholder=""
                    />
                  </div>
                </div>
                {values.queryType === 'participant' && (
                  <DialogInput
                    name="name"
                    label="Name of the participant"
                    disabled={false}
                    placeholder=""
                    error={
                      touched.name && errors.name ? errors.name : undefined
                    }
                  />
                )}
                {values.queryType === 'user' && (
                  <>
                    <DialogInput
                      name="name"
                      label="User name"
                      disabled={false}
                      placeholder=""
                      error={
                        touched.name && errors.name ? errors.name : undefined
                      }
                    />
                    <DialogInput
                      name="email"
                      label="User email address"
                      disabled={false}
                      placeholder=""
                      error={
                        touched.email && errors.email ? errors.email : undefined
                      }
                    />
                    <div className="flex flex-col">
                      <label htmlFor="organization" className="mb-2">
                        Organization name
                      </label>
                      <div className="shrink-0 basis-[30px] dark:border-r-lightBlue">
                        <ListBox
                          className="rounded-lg border-borderGray dark:border-darkBlueGray dark:bg-darkBlueGray border-[1px]"
                          options={organizationsList}
                          name={
                            `organization` as InputProps<VanillaFormNames>['name']
                          }
                          placeholder=""
                        />
                        {touched.organization && errors.organization && (
                          <div className="text-red mt-2">
                            {errors.organization}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div className="flex justify-end items-end">
                  <Button
                    className="w-[84px] mr-2"
                    onClick={() => {
                      setOpen(false)
                    }}
                    size={ButtonSize.DEFAULT}
                    styleVariant={ButtonVariant.SECONDARY}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={submitting}>
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
