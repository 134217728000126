import {
  enum_axe_callput_enum,
  enum_axe_ccypair_enum
} from '../../components/rfqBlotterRow/__generated__/rfqBlotterTableRowRfqFragment.graphql'

const buildOptionString = (
  callPutType: enum_axe_callput_enum | null | undefined,
  ccyPair: enum_axe_ccypair_enum
) => {
  if (!callPutType) return 'CALL_PUT_NOT_SET'

  const ccyCurrencyBase = ccyPair.slice(0, 3)
  const ccyCurrencySecondary = ccyPair.slice(3, 6)

  const optionValue =
    callPutType === 'CALL'
      ? `${ccyCurrencyBase}c${ccyCurrencySecondary}p`
      : `${ccyCurrencyBase}p${ccyCurrencySecondary}c`

  return optionValue
}

export default buildOptionString
