import React from 'react'
import ListBox, { ListBoxProps } from '../listBox'
import { useField } from 'formik'
import { enum_axe_callput_enum } from '../../rfqBlotterRow/__generated__/rfqBlotterTableRowRfqFragment.graphql'
import { usePrevious } from 'react-use'
import buildOptionString from '../../../utils/buildOptionString/buildOptionString'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'

const CallPutListBox = ({ fieldArrayPrefix, ...props }: ListBoxProps) => {
  const [ccyPairField] = useField(
    generateFieldName('ccyPair', fieldArrayPrefix)
  )
  const [options, setOptions] = React.useState(props.options)
  const previousCcyPair = usePrevious(ccyPairField.value)
  React.useEffect(() => {
    if (!ccyPairField.value || ccyPairField.value === previousCcyPair) return
    setOptions(
      options.map((option) =>
        option.unavailable
          ? option
          : {
              ...option,
              name: buildOptionString(
                option.type as enum_axe_callput_enum,
                ccyPairField.value
              )
            }
      )
    )
  }, [ccyPairField.value, options, previousCcyPair])

  return <ListBox {...props} options={options} />
}

export default CallPutListBox
