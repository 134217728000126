import { AnimatePresence, motion } from 'framer-motion'
import classNames from 'classnames'

import { FormikErrors } from 'formik'
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Axes } from '../../gql'
import Popover from '../popOverWithText/popOverWithText'

export enum FieldStateIconVariant {
  ERROR,
  WARNING
}

const displayMessage = (message: FieldStateIconProps['message']): string => {
  if (Array.isArray(message)) {
    return message.map((e) => displayMessage(e)).join(' ')
  } else if (typeof message === 'object') {
    return Object.values(message)
      .map((e) => displayMessage(e))
      .join('')
  } else {
    return message
  }
}

interface FieldStateIconProps {
  message: string | string[] | FormikErrors<Axes> | FormikErrors<Axes>[]
  variant?: FieldStateIconVariant
}

export function FieldStateIcon({
  message,
  variant = FieldStateIconVariant.ERROR
}: FieldStateIconProps) {
  return (
    <Popover
      button={
        <InformationCircleIcon
          className={classNames(
            'rounded-full h-[20px] w-[20px] cursor-pointer bg-white dark:bg-darkBlue shadow-2xl',
            {
              'bg-red-300 text-errorRed':
                variant === FieldStateIconVariant.ERROR
            },
            {
              'bg-processYellow text-processYellow':
                variant === FieldStateIconVariant.WARNING
            }
          )}
        />
      }
      popOverText={displayMessage(message)}
    />
  )
}

export enum FieldStateIconPosition {
  LEFT,
  RIGHT
}

interface AnimatedFieldStateIconProps {
  message?: string
  position?: FieldStateIconPosition
  variant: FieldStateIconVariant
}

export default function AnimatedFieldStateIcon({
  message,
  position = FieldStateIconPosition.LEFT,
  variant = FieldStateIconVariant.ERROR
}: AnimatedFieldStateIconProps) {
  return (
    <div
      data-testid="icon-container"
      className={classNames('absolute top-[calc(50%-10px)]', {
        '-left-[10px]': position === FieldStateIconPosition.LEFT,
        '-right-[10px]': position === FieldStateIconPosition.RIGHT
      })}
    >
      <AnimatePresence>
        {message && (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
          >
            <FieldStateIcon variant={variant} message={message} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
