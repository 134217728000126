import { useField } from 'formik'
import { InputProps, NumericInputProps } from '../input'
import Numeric from '../numeric/numeric'
import React from 'react'
import { useInputStateContext } from '../../../context/inputStateContext/inputStateContext'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import useFieldSyncBasedOnExpiryDate from '../../../hooks/useFieldSyncBasedOnExpiryDate/useFieldSyncBasedOnExpiryDate'
import { calculateSwaps } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'

const Forward = ({ fieldArrayPrefix, ...props }: NumericInputProps) => {
  const { state, dispatch } = useInputStateContext()
  const [spotField] = useField(generateFieldName('spot', fieldArrayPrefix))
  const [swapsField, , swapsHelpers] = useField(
    generateFieldName('swaps', fieldArrayPrefix)
  )
  const [forwardField] = useField(
    generateFieldName('forward', fieldArrayPrefix)
  )

  const { isDisabled } = useFieldSyncBasedOnExpiryDate(props.name)

  const isForwardChanging = React.useMemo(
    () => Boolean(state[forwardField.name]?.changing),
    [forwardField.name, state]
  )

  React.useEffect(() => {
    /**
     * When the forward value is in focus, we want to prevent updates
     * as changes to the spot value will cause a spot update and an infinite loop.
     * See onFocus and onBlur events
     */
    if (isForwardChanging && !isNaN(forwardField.value) && !isDisabled) {
      const value = calculateSwaps(spotField.value, forwardField.value)

      if (isNaN(value) || value === parseFloat(swapsField.value)) return

      swapsHelpers.setValue(value)

      dispatch({
        type: 'unset_reset_request',
        key: swapsField.name as InputProps['name']
      })
    }
  }, [
    dispatch,
    forwardField.value,
    isDisabled,
    isForwardChanging,
    spotField.value,
    swapsField.name,
    swapsField.value,
    swapsHelpers
  ])

  return (
    <Numeric
      {...props}
      isRefreshable={true}
      onFocus={() =>
        dispatch({
          type: 'set_to_changing',
          key: forwardField.name as InputProps['name']
        })
      }
      onBlur={() =>
        dispatch({
          type: 'unset_from_changing',
          key: forwardField.name as InputProps['name']
        })
      }
      disabled={props.disabled || isDisabled}
    />
  )
}

export default Forward
