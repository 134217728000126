import { InputProps } from '../../../../components/input/input'
import { extractArrayReferenceFromFieldName } from '../../../../utils/extractArrayReferenceFromFieldName/extractArrayReferenceFromFieldName'
import { InputStateContextProps } from '../../../inputStateContext/inputStateContext'
import {
  AxeFormValues,
  MarketDataValues,
  leg1SpotFieldName
} from '../../marketDataWorkerContext'
import calculateSpotSwapsForwardUsingMarketSpot from './utils/calculateSpotSwapsForwardUsingMarketSpot/calculateSpotSwapsForwardUsingMarketSpot'
import calculateSpotSwapsForwardUsingMarketSwaps from './utils/calculateSpotSwapsForwardUsingMarketSwaps/calculateSpotSwapsForwardUsingMarketSwaps'

interface CalculateSpotSwapsForwardOnExpiryDataChange {
  dispatch: InputStateContextProps['dispatch']
  lastChangedField: InputProps['name']
  marketDataValues: MarketDataValues
  state: InputStateContextProps['state']
  values: AxeFormValues
}

const calculateSpotSwapsForwardOnExpiryDataChange = ({
  dispatch,
  lastChangedField,
  marketDataValues,
  state,
  values
}: CalculateSpotSwapsForwardOnExpiryDataChange) => {
  const { arrayIndex: sourceLegIndex } =
    extractArrayReferenceFromFieldName(lastChangedField)

  if (sourceLegIndex === null) return

  // Update the spot/swaps/forward values across all legs if it's not overtyped or if the triggering field is the spot field
  if (
    !state[leg1SpotFieldName]?.overTyped ||
    lastChangedField?.endsWith('spot')
  ) {
    return calculateSpotSwapsForwardUsingMarketSpot({
      dispatch,
      marketDataValues,
      sourceLegIndex,
      state,
      values
    })
  }

  // If leg 1 spot is overtyped or if the triggering field is the spot field, update the swaps with the value from the market data service and calculate forward
  return calculateSpotSwapsForwardUsingMarketSwaps({
    dispatch,
    marketDataValues,
    sourceLegIndex,
    values
  })
}

export default calculateSpotSwapsForwardOnExpiryDataChange
