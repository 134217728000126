/**
 * @generated SignedSource<<60591ec95afb47ea083766ff78ecdc73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type sendQueryDialogFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly displayName: string;
    };
  }>;
  readonly " $fragmentType": "sendQueryDialogFragment";
};
export type sendQueryDialogFragment$key = {
  readonly " $data"?: sendQueryDialogFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"sendQueryDialogFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "sendQueryDialogFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "organizationsEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "organizations",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "organizationsConnection",
  "abstractKey": null
};

(node as any).hash = "3bfd82e0e297c4abd34c774a54032829";

export default node;
