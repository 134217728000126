import ComboBox, { ComboBoxProps } from '../comboBox'
import { useField } from 'formik'
import { getPartyBuySell } from '../../../utils/axeCalculations/axeCalculations'
import { usePartyBasedValue } from '../../../hooks/usePartyBasedValue/usePartyBasedValue'

const DirectionComboBox = ({ fieldArrayPrefix, ...props }: ComboBoxProps) => {
  const [directionField] = useField(props.name)
  const direction = directionField?.value

  const overrideValue = usePartyBasedValue({
    currentValue: direction,
    calculatePartyBasedValue: ({ currentValue, isInAuthorSubOrg }) => {
      return getPartyBuySell(isInAuthorSubOrg, currentValue)
    }
  })

  return <ComboBox {...props} overrideValue={overrideValue} />
}

export default DirectionComboBox
