import get from 'lodash/get'
import { InputProps } from '../../../../components/input/input'
import { Axe_Legs } from '../../../../gql'
import { InputStateContextProps } from '../../../inputStateContext/inputStateContext'
import { calculateSwaps, calculateForward } from '../../marketDataWorkerContext'

interface CalculateSwapsForwardUsingSpotParams {
  dispatch: InputStateContextProps['dispatch']
  forwardName: InputProps['name']
  legData: Axe_Legs
  spotValue: string | number
  state: InputStateContextProps['state']
  swapsName: InputProps['name']
}

function calculateSwapsForwardUsingSpot({
  dispatch,
  forwardName,
  legData,
  spotValue,
  state,
  swapsName
}: CalculateSwapsForwardUsingSpotParams) {
  // For legs that do not trigger a change, a /priceforward call is triggered for each leg with an expiry date.
  // Since we need to maintain a consistent spot value across all legs, and use the spot from leg 1 for all legs, we cannot directly use all values from the /priceforward response. Instead, swaps or forward values must be recalculated.
  // This recalculation is based on whether the forward field is overtyped:
  // * If the forward field is overtyped, we calculate swaps using the overtyped forward value and the spot from leg 1.
  // * If the forward field is not overtyped, we calculate forward using the swaps value from the /priceforward response and the spot from leg 1.
  // Example scenario:
  // 1. ccyPair and expiryDate are set for all 3 legs
  // 2. The spot for leg 1 is not overtyped (as per the condition above)
  // 3. A change in leg 2's expiryDate triggers a /priceforward call for each leg
  // 4. Leg 2 updates its spot/swaps/forward using the /priceforward response
  // 5. The spot for legs 1 & 3 is synchronized with the spot from leg 2
  // 6. If the forward in leg 1 is overtyped, swaps are recalculated using this overtyped forward value and the leg 1 spot
  // 7. If the swaps in leg 3 are overtyped, forward is recalculated using this overtyped swaps value and the leg 1 spot
  if (state[forwardName]?.overTyped) {
    const forwardValueFromFormik = get(legData, 'forward')

    dispatch({ type: 'unset_reset_request', key: swapsName })

    return {
      [swapsName]: calculateSwaps(spotValue, forwardValueFromFormik)
    }
  } else {
    const swapsValueFromFormik = get(legData, 'swaps')

    dispatch({ type: 'unset_reset_request', key: forwardName })

    return {
      [forwardName]: calculateForward(spotValue, swapsValueFromFormik)
    }
  }
}

export default calculateSwapsForwardUsingSpot
