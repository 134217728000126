import { putGammaAndVegaInCorrectSign } from '../../../utils/mapMarketDataKeys/mapMarketDataKeys'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import { InputStateContextProps } from '../../../context/inputStateContext/inputStateContext'
import setSameAcrossAllLegs from './utils/setSameAcrossAllLegs'
import { putDeltaAndPremiumInCorrectSign } from '../../../utils/mapMarketDataKeys/mapMarketDataKeys'
import { FormikContextType } from 'formik'
import {
  AxeFormData,
  AxeFormLegData
} from '../../dialog/editAxeDialog/editAxeDialog'
import { FieldNamePathArray } from '../formDataProcessor'
import { RFQFormValues } from '../../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'

type DataManipulationObject = {
  values: FormData
}

export type OnValuesDiffParams = {
  formik: {
    touched: FormikContextType<FormData>['touched']
    values: FormData
  }
  inputStateContext: InputStateContextProps
  path: FieldNamePathArray
}

export type FormDataProcessorConfigField = {
  onValuesDiff?: ({
    formik,
    inputStateContext,
    path
  }: OnValuesDiffParams) => DataManipulationObject | null
}

export type FormDataProcessorConfig = Record<
  string,
  FormDataProcessorConfigField
>

export type FormData = AxeFormData | RFQFormValues

const config: FormDataProcessorConfig = {
  buySell: {
    onValuesDiff: ({ formik: { values }, path }) => {
      const [arrayName, legIndex] = path
      const directionBasedFieldNames: Array<keyof AxeFormLegData> = [
        'delta',
        'premium',
        'gamma',
        'vega'
      ]
      const valuesToUpdate = {} as AxeFormData

      // When the buySell value changes, update the sign of the premium, delta, gamma, and vega values.
      // First, get the buySell and callPut values from the leg that triggered the change.
      const sourceLegValues = values.legs[legIndex] as AxeFormLegData
      const { buySell, callPut } = sourceLegValues

      // For each field we want updated, check if the field has a value and update it with the correct sign based on the buySell (and callPut) values.
      directionBasedFieldNames.forEach((fieldName) => {
        const value = sourceLegValues[fieldName]

        if (isNil(value)) return

        const isGammaOrVega = ['gamma', 'vega'].includes(fieldName)

        // Delta and premium need to be corrected based on both buySell and callPut values.
        // Gamma and vega need to be corrected based on the buySell value only.
        // Reference: https://linear.app/optaxe/issue/OPT-1446/bug-check-gamma-and-vega-signing-when-changing-the-buysell-on-an-axe#comment-c1413ef4
        const correctedValue = isGammaOrVega
          ? putGammaAndVegaInCorrectSign(value, buySell)
          : putDeltaAndPremiumInCorrectSign(value, callPut, buySell)

        set(valuesToUpdate, [arrayName, legIndex, fieldName], correctedValue)
      })

      return {
        values: valuesToUpdate
      }
    }
  },
  product: {
    onValuesDiff: ({ formik: { values }, inputStateContext, path }) => {
      return {
        values: setSameAcrossAllLegs({
          inputStateContext,
          path,
          values
        })
      }
    }
  },
  volatility: {
    onValuesDiff: ({ formik: { touched, values }, path }) => {
      const [arrayName, legIndex] = path

      // When the volatility value changes, update the pricingVolatility value unless the field has been touched.
      if (get(touched, [arrayName, legIndex, 'pricingVolatility'])) return null

      const valuesToUpdate = {} as AxeFormData

      set(
        valuesToUpdate,
        [arrayName, legIndex, 'pricingVolatility'],
        (values.legs[legIndex] as AxeFormLegData).volatility
      )

      return {
        values: valuesToUpdate
      }
    }
  }
}

export default config
