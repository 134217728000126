import { FormikContextType } from 'formik'
import { FormDataProcessorConfig } from '../../config/config'
import { InputStateContextProps } from '../../../../context/inputStateContext/inputStateContext'
import { FieldNamePathArray } from '../../formDataProcessor'
import { FormData } from '../../config/config'

interface ProcessFieldConfigParams {
  config: FormDataProcessorConfig
  formik: Pick<FormikContextType<FormData>, 'values' | 'touched'>
  inputStateContext: InputStateContextProps
  path: FieldNamePathArray
}

const processFieldConfig = ({
  config,
  formik,
  inputStateContext,
  path
}: ProcessFieldConfigParams) => {
  const fieldName = path[2]
  const fieldConfig = config[fieldName]

  if (!fieldConfig || !fieldConfig.onValuesDiff) return

  return fieldConfig.onValuesDiff({
    formik,
    inputStateContext,
    path
  })
}

export default processFieldConfig
