import { DateTime } from 'luxon'

type PresetFormatKey = keyof typeof presetFormats

export type FormatDateFromISOFormat = PresetFormatKey | string

const presetFormats = {
  shortDate: 'dd-MMM-yy',
  shortDateTime: 'dd-MMM-yy HH:mm:ss',
  shortDateTimeWithZone: 'dd-MMM-yy HH:mm:ss ZZZ',
  mediumDate: 'dd MMM yyyy',
  longDate: 'dd MMMM yyyy',
  compactDate: 'ddMMMyy'
}

const formatDateFromISO = (
  date: Date | DateTime<true> | DateTime<false> | string,
  format: FormatDateFromISOFormat = 'shortDate'
): string => {
  const resolvedFormat =
    format in presetFormats ? presetFormats[format as PresetFormatKey] : format

  if (typeof date === 'string') {
    return DateTime.fromISO(date).toFormat(resolvedFormat)
  } else if (date instanceof DateTime) {
    return date.toFormat(resolvedFormat)
  } else {
    return DateTime.fromJSDate(date).toFormat(resolvedFormat)
  }
}

export default formatDateFromISO
