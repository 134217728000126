import { useField } from 'formik'
import Numeric from '../numeric/numeric'
import { NumericInputProps } from '../input'
import React from 'react'
import { usePrevious } from 'react-use'
import { useWorkerContext } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import { invertSignUnlessInAuthorSubOrg } from '../../../utils/axeCalculations/axeCalculations'
import {
  calculatePercentage,
  formatPercentage
} from '../../tableRow/components/summaryCell/summaryCell'
import { usePartyBasedValue } from '../../../hooks/usePartyBasedValue/usePartyBasedValue'

// Delta field used for all delta inputs - forward/spot delta and delta
const Delta = ({ fieldArrayPrefix, ...props }: NumericInputProps) => {
  const [notionalField] = useField(
    generateFieldName('notional', fieldArrayPrefix)
  )
  const [deltaField] = useField(props.name)
  const [suffix, setSuffix] = React.useState<string | undefined>(undefined)
  const prevDelta = usePrevious(deltaField?.value)
  const { workerFetching } = useWorkerContext()
  const delta = deltaField?.value

  const overrideValue = usePartyBasedValue({
    currentValue: delta,
    calculatePartyBasedValue: ({ currentValue, isInAuthorSubOrg }) => {
      return invertSignUnlessInAuthorSubOrg(currentValue, isInAuthorSubOrg)
    }
  })

  React.useEffect(() => {
    if (
      delta === undefined ||
      (!overrideValue && (delta === prevDelta || workerFetching))
    )
      return

    const percentage = calculatePercentage(
      overrideValue || delta,
      notionalField?.value
    )
    const formattedPercentage = formatPercentage(percentage)

    setSuffix(formattedPercentage)
  }, [
    delta,
    notionalField?.value,
    overrideValue,
    prevDelta,
    props.disabled,
    workerFetching
  ])

  return (
    <Numeric
      {...props}
      isRefreshable={true}
      suffix={suffix}
      overrideValue={overrideValue}
    />
  )
}

export default Delta
