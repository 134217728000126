/**
 * @generated SignedSource<<049d40b7e940f3f7e8a00bdf85039a37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type axe_authors_constraint = "axe_authors_pkey" | "%future added value";
export type axe_authors_select_column = "axe_id" | "createdAt" | "id" | "subOrganizationId" | "updatedAt" | "user_id" | "%future added value";
export type axe_authors_update_column = "axe_id" | "createdAt" | "id" | "subOrganizationId" | "updatedAt" | "user_id" | "%future added value";
export type axe_legs_constraint = "legs_pkey" | "unique_order_per_axe" | "%future added value";
export type axe_legs_select_column = "axeId" | "baseCurrDepo" | "buySell" | "callPut" | "ccyPair" | "createdAt" | "cut" | "deliveryDate" | "delta" | "depoCcy1" | "depoCcy1DayCount" | "depoCcy2" | "depoCcy2DayCount" | "expiryDate" | "fix" | "forward" | "forwardPoints" | "gamma" | "hedgeType" | "id" | "minimumNotionalAmount" | "notional" | "notionalCurrency" | "orderIndex" | "premium" | "premiumCurrency" | "premiumDate" | "premiumType" | "pricingCurrDepo" | "pricingVolatility" | "product" | "spot" | "spotDate" | "strike" | "swaps" | "tenor" | "updatedAt" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_avg_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_corr_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_covar_samp_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_max_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_min_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_stddev_samp_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_sum_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_select_column_axe_legs_aggregate_bool_exp_var_samp_arguments_columns = "baseCurrDepo" | "depoCcy1" | "depoCcy2" | "forward" | "forwardPoints" | "gamma" | "pricingCurrDepo" | "pricingVolatility" | "spot" | "strike" | "swaps" | "vega" | "volatility" | "%future added value";
export type axe_legs_update_column = "axeId" | "baseCurrDepo" | "buySell" | "callPut" | "ccyPair" | "createdAt" | "cut" | "deliveryDate" | "delta" | "depoCcy1" | "depoCcy1DayCount" | "depoCcy2" | "depoCcy2DayCount" | "expiryDate" | "fix" | "forward" | "forwardPoints" | "gamma" | "hedgeType" | "id" | "minimumNotionalAmount" | "notional" | "notionalCurrency" | "orderIndex" | "premium" | "premiumCurrency" | "premiumDate" | "premiumType" | "pricingCurrDepo" | "pricingVolatility" | "product" | "spot" | "spotDate" | "strike" | "swaps" | "tenor" | "updatedAt" | "vega" | "volatility" | "%future added value";
export type axe_tier_pricing_constraint = "axe_tier_pricing_pkey" | "%future added value";
export type axe_tier_pricing_select_column = "axeId" | "createdAt" | "id" | "pricingVolatility" | "tier" | "updatedAt" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_avg_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_corr_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_max_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_min_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_stddev_samp_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_sum_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_var_samp_arguments_columns = "pricingVolatility" | "%future added value";
export type axe_tier_pricing_update_column = "axeId" | "createdAt" | "id" | "pricingVolatility" | "tier" | "updatedAt" | "%future added value";
export type axes_constraint = "axes_pkey" | "%future added value";
export type axes_select_column = "createdAt" | "filled" | "hidden" | "id" | "inRFQ" | "paused" | "state" | "tradedNotional" | "updatedAt" | "%future added value";
export type axes_select_column_axes_aggregate_bool_exp_bool_and_arguments_columns = "hidden" | "inRFQ" | "paused" | "%future added value";
export type axes_select_column_axes_aggregate_bool_exp_bool_or_arguments_columns = "hidden" | "inRFQ" | "paused" | "%future added value";
export type axes_update_column = "createdAt" | "filled" | "hidden" | "id" | "inRFQ" | "paused" | "state" | "tradedNotional" | "updatedAt" | "%future added value";
export type enum_axe_buysell_enum = "BUY" | "SELL" | "%future added value";
export type enum_axe_callput_enum = "CALL" | "PUT" | "%future added value";
export type enum_axe_ccypair_enum = "AUDCAD" | "AUDJPY" | "AUDNZD" | "AUDUSD" | "BRLJPY" | "BRLMXN" | "CADCHF" | "CADJPY" | "CHFJPY" | "CHFNOK" | "CHFSEK" | "CNHJPY" | "EURAUD" | "EURBRL" | "EURCAD" | "EURCHF" | "EURCNH" | "EURCZK" | "EURDKK" | "EURGBP" | "EURHKD" | "EURHUF" | "EURIDR" | "EURINR" | "EURJPY" | "EURKRW" | "EURMXN" | "EURMYR" | "EURNOK" | "EURNZD" | "EURPHP" | "EURPLN" | "EURRUB" | "EURSEK" | "EURSGD" | "EURTRY" | "EURTWD" | "EURUSD" | "EURZAR" | "GBPAUD" | "GBPBRL" | "GBPCAD" | "GBPCHF" | "GBPHKD" | "GBPJPY" | "GBPNOK" | "GBPNZD" | "GBPSEK" | "GBPUSD" | "HKDJPY" | "JPYKRW" | "MXNJPY" | "NOKJPY" | "NOKSEK" | "NZDCAD" | "NZDJPY" | "NZDUSD" | "SEKJPY" | "TRYJPY" | "TRYZAR" | "USDBRL" | "USDCAD" | "USDCHF" | "USDCLP" | "USDCNH" | "USDCOP" | "USDCZK" | "USDHKD" | "USDHUF" | "USDIDR" | "USDILS" | "USDINR" | "USDJPY" | "USDKRW" | "USDMXN" | "USDMYR" | "USDNOK" | "USDPHP" | "USDPLN" | "USDRUB" | "USDSEK" | "USDSGD" | "USDTRY" | "USDTWD" | "USDZAR" | "%future added value";
export type enum_axe_depoccy1daycount_enum = "ACT_360" | "ACT_365" | "%future added value";
export type enum_axe_depoccy2daycount_enum = "ACT_360" | "ACT_365" | "%future added value";
export type enum_axe_hedgetype_enum = "NDF" | "forward" | "spot" | "%future added value";
export type enum_axe_premiumtype_enum = "NDF" | "forward" | "spot" | "%future added value";
export type enum_axe_product_enum = "vanilla" | "%future added value";
export type enum_axe_state_enum = "DELETED" | "EXPIRED" | "LIVE" | "SUSPENDED_BY_ADMIN" | "SUSPENDED_BY_USER" | "TRADED" | "%future added value";
export type enum_color_scheme_enum = "DARK" | "LIGHT" | "%future added value";
export type enum_currency_notional_enum = "AUD" | "CAD" | "CHF" | "CNH" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ILS" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "TRY" | "USD" | "ZAR" | "%future added value";
export type enum_currency_premium_enum = "AUD" | "CAD" | "CHF" | "CNH" | "CZK" | "DKK" | "EUR" | "GBP" | "HKD" | "HUF" | "ILS" | "JPY" | "MXN" | "NOK" | "NZD" | "PLN" | "RON" | "RUB" | "SEK" | "SGD" | "TRY" | "USD" | "ZAR" | "%future added value";
export type enum_tenor_enum = "O_N" | "t_10M" | "t_11M" | "t_1M" | "t_1W" | "t_1Y" | "t_2D" | "t_2M" | "t_2W" | "t_3D" | "t_3M" | "t_3W" | "t_4D" | "t_4M" | "t_5D" | "t_5M" | "t_6D" | "t_6M" | "t_7M" | "t_8M" | "t_9M" | "%future added value";
export type enum_tiers_enum = "t_1" | "t_2" | "t_3" | "%future added value";
export type enum_users_axe_suspended_state_enum = "SUSPENDED_BY_ADMIN" | "SUSPENDED_BY_USER" | "SUSPENDED_BY_USER_AND_ADMIN" | "%future added value";
export type organizations_constraint = "organizations_name_key" | "organizations_pkey" | "%future added value";
export type organizations_update_column = "createdAt" | "displayName" | "id" | "lei" | "name" | "updatedAt" | "%future added value";
export type push_subscriptions_constraint = "push_subscriptions_pkey" | "%future added value";
export type push_subscriptions_select_column = "createdAt" | "id" | "subscription" | "updatedAt" | "userId" | "%future added value";
export type push_subscriptions_update_column = "createdAt" | "id" | "subscription" | "updatedAt" | "userId" | "%future added value";
export type sub_organizations_constraint = "sub_organizations_name_key" | "sub_organizations_pkey" | "%future added value";
export type sub_organizations_select_column = "createdAt" | "displayName" | "id" | "name" | "organizationId" | "updatedAt" | "%future added value";
export type sub_organizations_update_column = "createdAt" | "displayName" | "id" | "name" | "organizationId" | "updatedAt" | "%future added value";
export type users_constraint = "users_pkey" | "%future added value";
export type users_select_column = "colorSchemePreference" | "email" | "fullName" | "id" | "last_seen" | "organizationId" | "role" | "subOrganizationId" | "suspended_state" | "%future added value";
export type users_update_column = "colorSchemePreference" | "email" | "fullName" | "id" | "last_seen" | "organizationId" | "role" | "subOrganizationId" | "suspended_state" | "%future added value";
export type axes_insert_input = {
  axe_authors?: axe_authors_arr_rel_insert_input | null | undefined;
  axe_legs?: axe_legs_arr_rel_insert_input | null | undefined;
  axes_axe_tier_pricings?: axe_tier_pricing_arr_rel_insert_input | null | undefined;
  createdAt?: any | null | undefined;
  filled?: number | null | undefined;
  hidden?: boolean | null | undefined;
  id?: any | null | undefined;
  inRFQ?: boolean | null | undefined;
  paused?: boolean | null | undefined;
  state?: enum_axe_state_enum | null | undefined;
  tradedNotional?: number | null | undefined;
  updatedAt?: any | null | undefined;
};
export type axe_authors_arr_rel_insert_input = {
  data: ReadonlyArray<axe_authors_insert_input>;
  on_conflict?: axe_authors_on_conflict | null | undefined;
};
export type axe_authors_insert_input = {
  axe?: axes_arr_rel_insert_input | null | undefined;
  axe_id?: any | null | undefined;
  createdAt?: any | null | undefined;
  id?: any | null | undefined;
  subOrganization?: sub_organizations_arr_rel_insert_input | null | undefined;
  subOrganizationId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  user?: users_arr_rel_insert_input | null | undefined;
  user_id?: string | null | undefined;
};
export type axes_arr_rel_insert_input = {
  data: ReadonlyArray<axes_insert_input>;
  on_conflict?: axes_on_conflict | null | undefined;
};
export type axes_on_conflict = {
  constraint: axes_constraint;
  update_columns?: ReadonlyArray<axes_update_column>;
  where?: axes_bool_exp | null | undefined;
};
export type axes_bool_exp = {
  _and?: ReadonlyArray<axes_bool_exp> | null | undefined;
  _not?: axes_bool_exp | null | undefined;
  _or?: ReadonlyArray<axes_bool_exp> | null | undefined;
  axe_authors?: axe_authors_bool_exp | null | undefined;
  axe_authors_aggregate?: axe_authors_aggregate_bool_exp | null | undefined;
  axe_legs?: axe_legs_bool_exp | null | undefined;
  axe_legs_aggregate?: axe_legs_aggregate_bool_exp | null | undefined;
  axes_axe_tier_pricings?: axe_tier_pricing_bool_exp | null | undefined;
  axes_axe_tier_pricings_aggregate?: axe_tier_pricing_aggregate_bool_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  filled?: Int_comparison_exp | null | undefined;
  hidden?: Boolean_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  inRFQ?: Boolean_comparison_exp | null | undefined;
  paused?: Boolean_comparison_exp | null | undefined;
  state?: enum_axe_state_enum_comparison_exp | null | undefined;
  tradedNotional?: Int_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type axe_authors_bool_exp = {
  _and?: ReadonlyArray<axe_authors_bool_exp> | null | undefined;
  _not?: axe_authors_bool_exp | null | undefined;
  _or?: ReadonlyArray<axe_authors_bool_exp> | null | undefined;
  axe?: axes_bool_exp | null | undefined;
  axe_aggregate?: axes_aggregate_bool_exp | null | undefined;
  axe_id?: uuid_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  subOrganization?: sub_organizations_bool_exp | null | undefined;
  subOrganizationId?: uuid_comparison_exp | null | undefined;
  subOrganization_aggregate?: sub_organizations_aggregate_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  user?: users_bool_exp | null | undefined;
  user_aggregate?: users_aggregate_bool_exp | null | undefined;
  user_id?: String_comparison_exp | null | undefined;
};
export type axes_aggregate_bool_exp = {
  bool_and?: axes_aggregate_bool_exp_bool_and | null | undefined;
  bool_or?: axes_aggregate_bool_exp_bool_or | null | undefined;
  count?: axes_aggregate_bool_exp_count | null | undefined;
};
export type axes_aggregate_bool_exp_bool_and = {
  arguments: axes_select_column_axes_aggregate_bool_exp_bool_and_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axes_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type Boolean_comparison_exp = {
  _eq?: boolean | null | undefined;
  _gt?: boolean | null | undefined;
  _gte?: boolean | null | undefined;
  _in?: ReadonlyArray<boolean> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: boolean | null | undefined;
  _lte?: boolean | null | undefined;
  _neq?: boolean | null | undefined;
  _nin?: ReadonlyArray<boolean> | null | undefined;
};
export type axes_aggregate_bool_exp_bool_or = {
  arguments: axes_select_column_axes_aggregate_bool_exp_bool_or_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axes_bool_exp | null | undefined;
  predicate: Boolean_comparison_exp;
};
export type axes_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<axes_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: axes_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type Int_comparison_exp = {
  _eq?: number | null | undefined;
  _gt?: number | null | undefined;
  _gte?: number | null | undefined;
  _in?: ReadonlyArray<number> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: number | null | undefined;
  _lte?: number | null | undefined;
  _neq?: number | null | undefined;
  _nin?: ReadonlyArray<number> | null | undefined;
};
export type uuid_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type timestamptz_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type sub_organizations_bool_exp = {
  _and?: ReadonlyArray<sub_organizations_bool_exp> | null | undefined;
  _not?: sub_organizations_bool_exp | null | undefined;
  _or?: ReadonlyArray<sub_organizations_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  displayName?: String_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
  parent_organization?: organizations_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type String_comparison_exp = {
  _eq?: string | null | undefined;
  _gt?: string | null | undefined;
  _gte?: string | null | undefined;
  _ilike?: string | null | undefined;
  _in?: ReadonlyArray<string> | null | undefined;
  _iregex?: string | null | undefined;
  _is_null?: boolean | null | undefined;
  _like?: string | null | undefined;
  _lt?: string | null | undefined;
  _lte?: string | null | undefined;
  _neq?: string | null | undefined;
  _nilike?: string | null | undefined;
  _nin?: ReadonlyArray<string> | null | undefined;
  _niregex?: string | null | undefined;
  _nlike?: string | null | undefined;
  _nregex?: string | null | undefined;
  _nsimilar?: string | null | undefined;
  _regex?: string | null | undefined;
  _similar?: string | null | undefined;
};
export type organizations_bool_exp = {
  _and?: ReadonlyArray<organizations_bool_exp> | null | undefined;
  _not?: organizations_bool_exp | null | undefined;
  _or?: ReadonlyArray<organizations_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  displayName?: String_comparison_exp | null | undefined;
  id?: String_comparison_exp | null | undefined;
  lei?: String_comparison_exp | null | undefined;
  name?: String_comparison_exp | null | undefined;
  subOrganizations?: sub_organizations_bool_exp | null | undefined;
  subOrganizations_aggregate?: sub_organizations_aggregate_bool_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  users?: users_bool_exp | null | undefined;
  users_aggregate?: users_aggregate_bool_exp | null | undefined;
};
export type sub_organizations_aggregate_bool_exp = {
  count?: sub_organizations_aggregate_bool_exp_count | null | undefined;
};
export type sub_organizations_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<sub_organizations_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: sub_organizations_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type users_bool_exp = {
  _and?: ReadonlyArray<users_bool_exp> | null | undefined;
  _not?: users_bool_exp | null | undefined;
  _or?: ReadonlyArray<users_bool_exp> | null | undefined;
  colorSchemePreference?: enum_color_scheme_enum_comparison_exp | null | undefined;
  email?: String_comparison_exp | null | undefined;
  fullName?: String_comparison_exp | null | undefined;
  id?: String_comparison_exp | null | undefined;
  last_seen?: timestamp_comparison_exp | null | undefined;
  organization?: organizations_bool_exp | null | undefined;
  organizationId?: String_comparison_exp | null | undefined;
  pushSubscriptions?: push_subscriptions_bool_exp | null | undefined;
  pushSubscriptions_aggregate?: push_subscriptions_aggregate_bool_exp | null | undefined;
  role?: String_comparison_exp | null | undefined;
  subOrganization?: sub_organizations_bool_exp | null | undefined;
  subOrganizationId?: uuid_comparison_exp | null | undefined;
  suspended_state?: enum_users_axe_suspended_state_enum_comparison_exp | null | undefined;
};
export type enum_color_scheme_enum_comparison_exp = {
  _eq?: enum_color_scheme_enum | null | undefined;
  _in?: ReadonlyArray<enum_color_scheme_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_color_scheme_enum | null | undefined;
  _nin?: ReadonlyArray<enum_color_scheme_enum> | null | undefined;
};
export type timestamp_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type push_subscriptions_bool_exp = {
  _and?: ReadonlyArray<push_subscriptions_bool_exp> | null | undefined;
  _not?: push_subscriptions_bool_exp | null | undefined;
  _or?: ReadonlyArray<push_subscriptions_bool_exp> | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  subscription?: jsonb_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  user?: users_bool_exp | null | undefined;
  userId?: String_comparison_exp | null | undefined;
};
export type jsonb_comparison_exp = {
  _cast?: jsonb_cast_exp | null | undefined;
  _contained_in?: any | null | undefined;
  _contains?: any | null | undefined;
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _has_key?: string | null | undefined;
  _has_keys_all?: ReadonlyArray<string> | null | undefined;
  _has_keys_any?: ReadonlyArray<string> | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type jsonb_cast_exp = {
  String?: String_comparison_exp | null | undefined;
};
export type push_subscriptions_aggregate_bool_exp = {
  count?: push_subscriptions_aggregate_bool_exp_count | null | undefined;
};
export type push_subscriptions_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<push_subscriptions_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: push_subscriptions_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type enum_users_axe_suspended_state_enum_comparison_exp = {
  _eq?: enum_users_axe_suspended_state_enum | null | undefined;
  _in?: ReadonlyArray<enum_users_axe_suspended_state_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_users_axe_suspended_state_enum | null | undefined;
  _nin?: ReadonlyArray<enum_users_axe_suspended_state_enum> | null | undefined;
};
export type users_aggregate_bool_exp = {
  count?: users_aggregate_bool_exp_count | null | undefined;
};
export type users_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<users_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: users_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type axe_authors_aggregate_bool_exp = {
  count?: axe_authors_aggregate_bool_exp_count | null | undefined;
};
export type axe_authors_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<axe_authors_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: axe_authors_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type axe_legs_bool_exp = {
  _and?: ReadonlyArray<axe_legs_bool_exp> | null | undefined;
  _not?: axe_legs_bool_exp | null | undefined;
  _or?: ReadonlyArray<axe_legs_bool_exp> | null | undefined;
  axe?: axes_bool_exp | null | undefined;
  axeId?: uuid_comparison_exp | null | undefined;
  axe_aggregate?: axes_aggregate_bool_exp | null | undefined;
  baseCurrDepo?: float8_comparison_exp | null | undefined;
  buySell?: enum_axe_buysell_enum_comparison_exp | null | undefined;
  callPut?: enum_axe_callput_enum_comparison_exp | null | undefined;
  ccyPair?: enum_axe_ccypair_enum_comparison_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  cut?: String_comparison_exp | null | undefined;
  deliveryDate?: date_comparison_exp | null | undefined;
  delta?: bigint_comparison_exp | null | undefined;
  depoCcy1?: float8_comparison_exp | null | undefined;
  depoCcy1DayCount?: enum_axe_depoccy1daycount_enum_comparison_exp | null | undefined;
  depoCcy2?: float8_comparison_exp | null | undefined;
  depoCcy2DayCount?: enum_axe_depoccy2daycount_enum_comparison_exp | null | undefined;
  expiryDate?: date_comparison_exp | null | undefined;
  fix?: String_comparison_exp | null | undefined;
  forward?: float8_comparison_exp | null | undefined;
  forwardPoints?: float8_comparison_exp | null | undefined;
  gamma?: float8_comparison_exp | null | undefined;
  hedgeType?: enum_axe_hedgetype_enum_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  minimumNotionalAmount?: Int_comparison_exp | null | undefined;
  notional?: Int_comparison_exp | null | undefined;
  notionalCurrency?: enum_currency_notional_enum_comparison_exp | null | undefined;
  orderIndex?: Int_comparison_exp | null | undefined;
  premium?: Int_comparison_exp | null | undefined;
  premiumCurrency?: enum_currency_premium_enum_comparison_exp | null | undefined;
  premiumDate?: date_comparison_exp | null | undefined;
  premiumType?: enum_axe_premiumtype_enum_comparison_exp | null | undefined;
  pricingCurrDepo?: float8_comparison_exp | null | undefined;
  pricingVolatility?: float8_comparison_exp | null | undefined;
  product?: enum_axe_product_enum_comparison_exp | null | undefined;
  spot?: float8_comparison_exp | null | undefined;
  spotDate?: date_comparison_exp | null | undefined;
  strike?: float8_comparison_exp | null | undefined;
  swaps?: float8_comparison_exp | null | undefined;
  tenor?: enum_tenor_enum_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
  vega?: float8_comparison_exp | null | undefined;
  volatility?: float8_comparison_exp | null | undefined;
};
export type float8_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type enum_axe_buysell_enum_comparison_exp = {
  _eq?: enum_axe_buysell_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_buysell_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_buysell_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_buysell_enum> | null | undefined;
};
export type enum_axe_callput_enum_comparison_exp = {
  _eq?: enum_axe_callput_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_callput_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_callput_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_callput_enum> | null | undefined;
};
export type enum_axe_ccypair_enum_comparison_exp = {
  _eq?: enum_axe_ccypair_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_ccypair_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_ccypair_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_ccypair_enum> | null | undefined;
};
export type date_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type bigint_comparison_exp = {
  _eq?: any | null | undefined;
  _gt?: any | null | undefined;
  _gte?: any | null | undefined;
  _in?: ReadonlyArray<any> | null | undefined;
  _is_null?: boolean | null | undefined;
  _lt?: any | null | undefined;
  _lte?: any | null | undefined;
  _neq?: any | null | undefined;
  _nin?: ReadonlyArray<any> | null | undefined;
};
export type enum_axe_depoccy1daycount_enum_comparison_exp = {
  _eq?: enum_axe_depoccy1daycount_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_depoccy1daycount_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_depoccy1daycount_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_depoccy1daycount_enum> | null | undefined;
};
export type enum_axe_depoccy2daycount_enum_comparison_exp = {
  _eq?: enum_axe_depoccy2daycount_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_depoccy2daycount_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_depoccy2daycount_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_depoccy2daycount_enum> | null | undefined;
};
export type enum_axe_hedgetype_enum_comparison_exp = {
  _eq?: enum_axe_hedgetype_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_hedgetype_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_hedgetype_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_hedgetype_enum> | null | undefined;
};
export type enum_currency_notional_enum_comparison_exp = {
  _eq?: enum_currency_notional_enum | null | undefined;
  _in?: ReadonlyArray<enum_currency_notional_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_currency_notional_enum | null | undefined;
  _nin?: ReadonlyArray<enum_currency_notional_enum> | null | undefined;
};
export type enum_currency_premium_enum_comparison_exp = {
  _eq?: enum_currency_premium_enum | null | undefined;
  _in?: ReadonlyArray<enum_currency_premium_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_currency_premium_enum | null | undefined;
  _nin?: ReadonlyArray<enum_currency_premium_enum> | null | undefined;
};
export type enum_axe_premiumtype_enum_comparison_exp = {
  _eq?: enum_axe_premiumtype_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_premiumtype_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_premiumtype_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_premiumtype_enum> | null | undefined;
};
export type enum_axe_product_enum_comparison_exp = {
  _eq?: enum_axe_product_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_product_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_product_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_product_enum> | null | undefined;
};
export type enum_tenor_enum_comparison_exp = {
  _eq?: enum_tenor_enum | null | undefined;
  _in?: ReadonlyArray<enum_tenor_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_tenor_enum | null | undefined;
  _nin?: ReadonlyArray<enum_tenor_enum> | null | undefined;
};
export type axe_legs_aggregate_bool_exp = {
  avg?: axe_legs_aggregate_bool_exp_avg | null | undefined;
  corr?: axe_legs_aggregate_bool_exp_corr | null | undefined;
  count?: axe_legs_aggregate_bool_exp_count | null | undefined;
  covar_samp?: axe_legs_aggregate_bool_exp_covar_samp | null | undefined;
  max?: axe_legs_aggregate_bool_exp_max | null | undefined;
  min?: axe_legs_aggregate_bool_exp_min | null | undefined;
  stddev_samp?: axe_legs_aggregate_bool_exp_stddev_samp | null | undefined;
  sum?: axe_legs_aggregate_bool_exp_sum | null | undefined;
  var_samp?: axe_legs_aggregate_bool_exp_var_samp | null | undefined;
};
export type axe_legs_aggregate_bool_exp_avg = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_corr = {
  arguments: axe_legs_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_corr_arguments = {
  X: axe_legs_select_column_axe_legs_aggregate_bool_exp_corr_arguments_columns;
  Y: axe_legs_select_column_axe_legs_aggregate_bool_exp_corr_arguments_columns;
};
export type axe_legs_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<axe_legs_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_covar_samp = {
  arguments: axe_legs_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_covar_samp_arguments = {
  X: axe_legs_select_column_axe_legs_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: axe_legs_select_column_axe_legs_aggregate_bool_exp_covar_samp_arguments_columns;
};
export type axe_legs_aggregate_bool_exp_max = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_min = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_stddev_samp = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_sum = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_legs_aggregate_bool_exp_var_samp = {
  arguments: axe_legs_select_column_axe_legs_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_legs_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_bool_exp = {
  _and?: ReadonlyArray<axe_tier_pricing_bool_exp> | null | undefined;
  _not?: axe_tier_pricing_bool_exp | null | undefined;
  _or?: ReadonlyArray<axe_tier_pricing_bool_exp> | null | undefined;
  axe?: axes_bool_exp | null | undefined;
  axeId?: uuid_comparison_exp | null | undefined;
  axe_aggregate?: axes_aggregate_bool_exp | null | undefined;
  createdAt?: timestamptz_comparison_exp | null | undefined;
  id?: uuid_comparison_exp | null | undefined;
  pricingVolatility?: float8_comparison_exp | null | undefined;
  tier?: enum_tiers_enum_comparison_exp | null | undefined;
  updatedAt?: timestamptz_comparison_exp | null | undefined;
};
export type enum_tiers_enum_comparison_exp = {
  _eq?: enum_tiers_enum | null | undefined;
  _in?: ReadonlyArray<enum_tiers_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_tiers_enum | null | undefined;
  _nin?: ReadonlyArray<enum_tiers_enum> | null | undefined;
};
export type axe_tier_pricing_aggregate_bool_exp = {
  avg?: axe_tier_pricing_aggregate_bool_exp_avg | null | undefined;
  corr?: axe_tier_pricing_aggregate_bool_exp_corr | null | undefined;
  count?: axe_tier_pricing_aggregate_bool_exp_count | null | undefined;
  covar_samp?: axe_tier_pricing_aggregate_bool_exp_covar_samp | null | undefined;
  max?: axe_tier_pricing_aggregate_bool_exp_max | null | undefined;
  min?: axe_tier_pricing_aggregate_bool_exp_min | null | undefined;
  stddev_samp?: axe_tier_pricing_aggregate_bool_exp_stddev_samp | null | undefined;
  sum?: axe_tier_pricing_aggregate_bool_exp_sum | null | undefined;
  var_samp?: axe_tier_pricing_aggregate_bool_exp_var_samp | null | undefined;
};
export type axe_tier_pricing_aggregate_bool_exp_avg = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_avg_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_corr = {
  arguments: axe_tier_pricing_aggregate_bool_exp_corr_arguments;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_corr_arguments = {
  X: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_corr_arguments_columns;
  Y: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_corr_arguments_columns;
};
export type axe_tier_pricing_aggregate_bool_exp_count = {
  arguments?: ReadonlyArray<axe_tier_pricing_select_column> | null | undefined;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: Int_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_covar_samp = {
  arguments: axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments = {
  X: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments_columns;
  Y: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments_columns;
};
export type axe_tier_pricing_aggregate_bool_exp_max = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_max_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_min = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_min_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_stddev_samp = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_stddev_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_sum = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_sum_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type axe_tier_pricing_aggregate_bool_exp_var_samp = {
  arguments: axe_tier_pricing_select_column_axe_tier_pricing_aggregate_bool_exp_var_samp_arguments_columns;
  distinct?: boolean | null | undefined;
  filter?: axe_tier_pricing_bool_exp | null | undefined;
  predicate: float8_comparison_exp;
};
export type enum_axe_state_enum_comparison_exp = {
  _eq?: enum_axe_state_enum | null | undefined;
  _in?: ReadonlyArray<enum_axe_state_enum> | null | undefined;
  _is_null?: boolean | null | undefined;
  _neq?: enum_axe_state_enum | null | undefined;
  _nin?: ReadonlyArray<enum_axe_state_enum> | null | undefined;
};
export type sub_organizations_arr_rel_insert_input = {
  data: ReadonlyArray<sub_organizations_insert_input>;
  on_conflict?: sub_organizations_on_conflict | null | undefined;
};
export type sub_organizations_insert_input = {
  createdAt?: any | null | undefined;
  displayName?: string | null | undefined;
  id?: any | null | undefined;
  name?: string | null | undefined;
  organizationId?: string | null | undefined;
  parent_organization?: organizations_obj_rel_insert_input | null | undefined;
  updatedAt?: any | null | undefined;
};
export type organizations_obj_rel_insert_input = {
  data: organizations_insert_input;
  on_conflict?: organizations_on_conflict | null | undefined;
};
export type organizations_insert_input = {
  createdAt?: any | null | undefined;
  displayName?: string | null | undefined;
  id?: string | null | undefined;
  lei?: string | null | undefined;
  name?: string | null | undefined;
  subOrganizations?: sub_organizations_arr_rel_insert_input | null | undefined;
  updatedAt?: any | null | undefined;
  users?: users_arr_rel_insert_input | null | undefined;
};
export type users_arr_rel_insert_input = {
  data: ReadonlyArray<users_insert_input>;
  on_conflict?: users_on_conflict | null | undefined;
};
export type users_insert_input = {
  colorSchemePreference?: enum_color_scheme_enum | null | undefined;
  email?: string | null | undefined;
  fullName?: string | null | undefined;
  id?: string | null | undefined;
  last_seen?: any | null | undefined;
  organization?: organizations_obj_rel_insert_input | null | undefined;
  organizationId?: string | null | undefined;
  pushSubscriptions?: push_subscriptions_arr_rel_insert_input | null | undefined;
  role?: string | null | undefined;
  subOrganization?: sub_organizations_obj_rel_insert_input | null | undefined;
  subOrganizationId?: any | null | undefined;
  suspended_state?: enum_users_axe_suspended_state_enum | null | undefined;
};
export type push_subscriptions_arr_rel_insert_input = {
  data: ReadonlyArray<push_subscriptions_insert_input>;
  on_conflict?: push_subscriptions_on_conflict | null | undefined;
};
export type push_subscriptions_insert_input = {
  createdAt?: any | null | undefined;
  id?: any | null | undefined;
  subscription?: any | null | undefined;
  updatedAt?: any | null | undefined;
  user?: users_obj_rel_insert_input | null | undefined;
  userId?: string | null | undefined;
};
export type users_obj_rel_insert_input = {
  data: users_insert_input;
  on_conflict?: users_on_conflict | null | undefined;
};
export type users_on_conflict = {
  constraint: users_constraint;
  update_columns?: ReadonlyArray<users_update_column>;
  where?: users_bool_exp | null | undefined;
};
export type push_subscriptions_on_conflict = {
  constraint: push_subscriptions_constraint;
  update_columns?: ReadonlyArray<push_subscriptions_update_column>;
  where?: push_subscriptions_bool_exp | null | undefined;
};
export type sub_organizations_obj_rel_insert_input = {
  data: sub_organizations_insert_input;
  on_conflict?: sub_organizations_on_conflict | null | undefined;
};
export type sub_organizations_on_conflict = {
  constraint: sub_organizations_constraint;
  update_columns?: ReadonlyArray<sub_organizations_update_column>;
  where?: sub_organizations_bool_exp | null | undefined;
};
export type organizations_on_conflict = {
  constraint: organizations_constraint;
  update_columns?: ReadonlyArray<organizations_update_column>;
  where?: organizations_bool_exp | null | undefined;
};
export type axe_authors_on_conflict = {
  constraint: axe_authors_constraint;
  update_columns?: ReadonlyArray<axe_authors_update_column>;
  where?: axe_authors_bool_exp | null | undefined;
};
export type axe_legs_arr_rel_insert_input = {
  data: ReadonlyArray<axe_legs_insert_input>;
  on_conflict?: axe_legs_on_conflict | null | undefined;
};
export type axe_legs_insert_input = {
  axe?: axes_arr_rel_insert_input | null | undefined;
  axeId?: any | null | undefined;
  baseCurrDepo?: any | null | undefined;
  buySell?: enum_axe_buysell_enum | null | undefined;
  callPut?: enum_axe_callput_enum | null | undefined;
  ccyPair?: enum_axe_ccypair_enum | null | undefined;
  createdAt?: any | null | undefined;
  cut?: string | null | undefined;
  deliveryDate?: any | null | undefined;
  delta?: any | null | undefined;
  depoCcy1?: any | null | undefined;
  depoCcy1DayCount?: enum_axe_depoccy1daycount_enum | null | undefined;
  depoCcy2?: any | null | undefined;
  depoCcy2DayCount?: enum_axe_depoccy2daycount_enum | null | undefined;
  expiryDate?: any | null | undefined;
  fix?: string | null | undefined;
  forward?: any | null | undefined;
  forwardPoints?: any | null | undefined;
  gamma?: any | null | undefined;
  hedgeType?: enum_axe_hedgetype_enum | null | undefined;
  id?: any | null | undefined;
  minimumNotionalAmount?: number | null | undefined;
  notional?: number | null | undefined;
  notionalCurrency?: enum_currency_notional_enum | null | undefined;
  orderIndex?: number | null | undefined;
  premium?: number | null | undefined;
  premiumCurrency?: enum_currency_premium_enum | null | undefined;
  premiumDate?: any | null | undefined;
  premiumType?: enum_axe_premiumtype_enum | null | undefined;
  pricingCurrDepo?: any | null | undefined;
  pricingVolatility?: any | null | undefined;
  product?: enum_axe_product_enum | null | undefined;
  spot?: any | null | undefined;
  spotDate?: any | null | undefined;
  strike?: any | null | undefined;
  swaps?: any | null | undefined;
  tenor?: enum_tenor_enum | null | undefined;
  updatedAt?: any | null | undefined;
  vega?: any | null | undefined;
  volatility?: any | null | undefined;
};
export type axe_legs_on_conflict = {
  constraint: axe_legs_constraint;
  update_columns?: ReadonlyArray<axe_legs_update_column>;
  where?: axe_legs_bool_exp | null | undefined;
};
export type axe_tier_pricing_arr_rel_insert_input = {
  data: ReadonlyArray<axe_tier_pricing_insert_input>;
  on_conflict?: axe_tier_pricing_on_conflict | null | undefined;
};
export type axe_tier_pricing_insert_input = {
  axe?: axes_arr_rel_insert_input | null | undefined;
  axeId?: any | null | undefined;
  createdAt?: any | null | undefined;
  id?: any | null | undefined;
  pricingVolatility?: any | null | undefined;
  tier?: enum_tiers_enum | null | undefined;
  updatedAt?: any | null | undefined;
};
export type axe_tier_pricing_on_conflict = {
  constraint: axe_tier_pricing_constraint;
  update_columns?: ReadonlyArray<axe_tier_pricing_update_column>;
  where?: axe_tier_pricing_bool_exp | null | undefined;
};
export type user_tiers_insert_input = {
  axe?: axes_arr_rel_insert_input | null | undefined;
  axeId?: any | null | undefined;
  axe_tier?: axe_tier_pricing_obj_rel_insert_input | null | undefined;
  createdAt?: any | null | undefined;
  id?: any | null | undefined;
  isRemoved?: boolean | null | undefined;
  organizationId?: string | null | undefined;
  subOrganizationId?: any | null | undefined;
  tierId?: any | null | undefined;
  updatedAt?: any | null | undefined;
  userId?: string | null | undefined;
  user_main_tiering_user?: users_obj_rel_insert_input | null | undefined;
  user_organization?: organizations_obj_rel_insert_input | null | undefined;
  user_sub_organization?: sub_organizations_obj_rel_insert_input | null | undefined;
};
export type axe_tier_pricing_obj_rel_insert_input = {
  data: axe_tier_pricing_insert_input;
  on_conflict?: axe_tier_pricing_on_conflict | null | undefined;
};
export type createAxeDialogButtonMutation$variables = {
  axe: axes_insert_input;
  axePricingTiers: ReadonlyArray<axe_tier_pricing_insert_input>;
  userTiers: ReadonlyArray<user_tiers_insert_input>;
};
export type createAxeDialogButtonMutation$data = {
  readonly insert_axe_tier_pricing: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
  readonly insert_axes_one: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"createAxeDialogButtonFragment">;
  } | null | undefined;
  readonly insert_user_tiers: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type createAxeDialogButtonMutation = {
  response: createAxeDialogButtonMutation$data;
  variables: createAxeDialogButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "axe"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "axePricingTiers"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userTiers"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "axe"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "objects",
      "variableName": "axePricingTiers"
    }
  ],
  "concreteType": "axe_tier_pricing_mutation_response",
  "kind": "LinkedField",
  "name": "insert_axe_tier_pricing",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "axe_tier_pricing",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "objects",
      "variableName": "userTiers"
    }
  ],
  "concreteType": "user_tiers_mutation_response",
  "kind": "LinkedField",
  "name": "insert_user_tiers",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "user_tiers",
      "kind": "LinkedField",
      "name": "returning",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAxeDialogButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "axes",
        "kind": "LinkedField",
        "name": "insert_axes_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "createAxeDialogButtonFragment"
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAxeDialogButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "axes",
        "kind": "LinkedField",
        "name": "insert_axes_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "axe_authors",
            "kind": "LinkedField",
            "name": "axe_authors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "user",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subOrganizationId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "order_by",
                "value": {
                  "orderIndex": "asc"
                }
              }
            ],
            "concreteType": "axe_legs",
            "kind": "LinkedField",
            "name": "axe_legs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "product",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ccyPair",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "buySell",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tenor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expiryDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deliveryDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cut",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fix",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "strike",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "callPut",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notional",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "notionalCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "premium",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "premiumCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "spot",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "swaps",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "forward",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "volatility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hedgeType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minimumNotionalAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "delta",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "gamma",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vega",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pricingVolatility",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "premiumType",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": "axe_legs(order_by:{\"orderIndex\":\"asc\"})"
          }
        ],
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "3ad43df79feb36d91322a019927ecb2a",
    "id": null,
    "metadata": {},
    "name": "createAxeDialogButtonMutation",
    "operationKind": "mutation",
    "text": "mutation createAxeDialogButtonMutation(\n  $axe: axes_insert_input!\n  $axePricingTiers: [axe_tier_pricing_insert_input!]!\n  $userTiers: [user_tiers_insert_input!]!\n) {\n  insert_axes_one(object: $axe) {\n    id\n    ...createAxeDialogButtonFragment\n  }\n  insert_axe_tier_pricing(objects: $axePricingTiers) {\n    returning {\n      id\n    }\n  }\n  insert_user_tiers(objects: $userTiers) {\n    returning {\n      id\n    }\n  }\n}\n\nfragment createAxeDialogButtonAuthorFragment on axe_authors {\n  user {\n    id\n    subOrganizationId\n  }\n}\n\nfragment createAxeDialogButtonFragment on axes {\n  updatedAt\n  axe_authors {\n    __typename\n    ...createAxeDialogButtonAuthorFragment\n    ...profileBadgeAxeAuthorFragment\n    id\n  }\n  axe_legs(order_by: {orderIndex: asc}) {\n    product\n    ccyPair\n    buySell\n    tenor\n    expiryDate\n    deliveryDate\n    cut\n    fix\n    strike\n    callPut\n    notional\n    notionalCurrency\n    premium\n    premiumCurrency\n    spot\n    swaps\n    forward\n    volatility\n    hedgeType\n    minimumNotionalAmount\n    delta\n    gamma\n    vega\n    pricingVolatility\n    premiumType\n    id\n  }\n}\n\nfragment profileBadgeAxeAuthorFragment on axe_authors {\n  user {\n    id\n    fullName\n    subOrganizationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "13200d385a5ab6d7694b94c664fd0ead";

export default node;
