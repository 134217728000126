import { DocumentDuplicateIcon, TrashIcon } from '@heroicons/react/20/solid'
import {
  PopOverMenuButton,
  PopOverMenuItemWrapper,
  PopOverMenuLine
} from '../../../../popOverMenu/popOverMenu'

interface AxeLegPopoverProps {
  isDeletable?: boolean
  isDuplicable?: boolean
  onDeleteClick: () => void
  onDuplicateClick: () => void
}

export default function AxeLegPopover({
  isDeletable,
  isDuplicable,
  onDuplicateClick,
  onDeleteClick
}: AxeLegPopoverProps) {
  return (
    <PopOverMenuItemWrapper>
      {isDuplicable && (
        <PopOverMenuButton onClick={onDuplicateClick}>
          Duplicate
          <DocumentDuplicateIcon height="15px" width="15px" />
        </PopOverMenuButton>
      )}
      {isDuplicable && isDeletable && <PopOverMenuLine />}
      {isDeletable && (
        <PopOverMenuButton onClick={onDeleteClick}>
          Delete
          <TrashIcon height="15px" width="15px" />
        </PopOverMenuButton>
      )}
    </PopOverMenuItemWrapper>
  )
}
