'use client'

import React from 'react'
import { Popover } from '@headlessui/react'
import AxeLegPopover from '../axeLegPopover/axeLegPopover'

interface AxeLegPanelProps {
  onAxeLegDelete: () => void
  onAxeLegDuplicate: () => void
  isDeletable?: boolean
  isDuplicable?: boolean
}

export default function AxeLegPanel({
  onAxeLegDelete,
  onAxeLegDuplicate,
  isDeletable,
  isDuplicable
}: AxeLegPanelProps) {
  return (
    <Popover.Panel className="absolute z-10 top-[30px] left-[-150px]">
      {({ close }) => (
        <>
          <AxeLegPopover
            isDeletable={isDeletable}
            isDuplicable={isDuplicable}
            onDeleteClick={() => {
              onAxeLegDelete()
            }}
            onDuplicateClick={() => {
              onAxeLegDuplicate()
              close()
            }}
          />
        </>
      )}
    </Popover.Panel>
  )
}
