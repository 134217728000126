import { Popover } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import AxeLegPanel from '../forms/createAxe/components/axeLegPanel/axeLegPanel'
import classNames from 'classnames'
import { tableClassNames } from '../table/table'
import { legSchema } from '../buttons/createAxeDialogButton/schema.yup'
import { AxeFormData } from '../dialog/editAxeDialog/editAxeDialog'
import { RFQFormValues } from '../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'

interface TableHeaderProps {
  isSticky?: boolean
  maxColumns?: number
  onDelete?: (index: number) => void
  onDuplicate?: (index: number) => void
  showSummaryOnly?: boolean
  totalColumns: number
  values: AxeFormData | RFQFormValues
}

function TableHeader({
  isSticky,
  maxColumns = 1,
  onDelete,
  onDuplicate,
  showSummaryOnly,
  totalColumns = 1,
  values
}: TableHeaderProps): JSX.Element {
  return (
    // Enable sticky behavior for the table header and add a background color to prevent table contents from bleeding through.
    <div
      className={classNames(' bg-white dark:bg-darkBlue', {
        'sticky top-0 z-10': isSticky
      })}
      data-testid="table-header"
    >
      {/* Separating the border and grid styles (below DL & DIV) aligns with the required structure of Table and TableRow. This ensures consistent column widths across both the table header and body. */}
      <dl
        className={classNames(
          tableClassNames.container,
          'border-r border-l border-l-transparent border-r-borderGray dark:border-r-lightBlue',
          'rounded-tr-md'
        )}
      >
        <div
          className={tableClassNames.rows(showSummaryOnly ? 1 : totalColumns)}
        >
          {/* Summary column */}
          {totalColumns > 1 && (
            <dt
              className={classNames(
                tableClassNames.headingCell,
                tableClassNames.cellHeight,
                'col-start-2', // Ensure the offset by 1 column to accommodate the row descriptor for summary column
                'border-t border-l border-borderGray dark:border-lightBlue rounded-tl-md',
                { 'rounded-tr-md': showSummaryOnly },
                tableClassNames.cellState.default,
                tableClassNames.summaryCell
              )}
            >
              <div className={tableClassNames.stringCell}>Summary</div>
            </dt>
          )}
          {/* Leg columns */}
          {!showSummaryOnly &&
            Array.from({ length: totalColumns }, (_, index) => {
              const isFirstColumn = totalColumns === 1 && index === 0
              const isLastColumn = index + 1 === totalColumns
              const title = `Leg ${index + 1}`
              const isDeletable = totalColumns > 1
              const isLegValid = legSchema.isValidSync(values.legs[index])
              const isDuplicable = totalColumns < maxColumns && isLegValid

              const showMenu =
                (isDeletable && onDelete) || (isDuplicable && onDuplicate)

              return (
                <dt
                  key={index}
                  className={classNames(
                    tableClassNames.headingCell,
                    tableClassNames.cellHeight,
                    { 'col-start-2': isFirstColumn }, // Offset by 1 column to accommodate the row descriptor when this is first column
                    'border-t border-borderGray dark:border-lightBlue',
                    { 'border-l rounded-tl-md': isFirstColumn },
                    { 'rounded-tr-md': isLastColumn },
                    tableClassNames.cellState.default
                  )}
                >
                  <div className={tableClassNames.stringCell}>{title}</div>

                  {showMenu && (
                    <Popover className="grow-0 relative">
                      <Popover.Button
                        // w-[22px] aligns popover button with subsequent field controls
                        className={`h-full flex items-center justify-center w-[23px]`}
                        aria-label={`Leg ${index + 1} options`}
                      >
                        <EllipsisVerticalIcon height="19px" width="19px" />
                      </Popover.Button>
                      <AxeLegPanel
                        isDeletable={isDeletable}
                        isDuplicable={isDuplicable}
                        onAxeLegDelete={() => onDelete?.(index)}
                        onAxeLegDuplicate={() => onDuplicate?.(index)}
                      />
                    </Popover>
                  )}
                </dt>
              )
            })}
        </div>
      </dl>
    </div>
  )
}

export default TableHeader
