import React from 'react'
import { useField } from 'formik'
import { NumericInputProps } from '../input'
import Numeric from '../numeric/numeric'
import isNil from 'lodash/isNil'
import { calculateNotionalRatio } from '../../tableRow/components/summaryCell/summaryCell'
import { extractArrayReferenceFromFieldName } from '../../../utils/extractArrayReferenceFromFieldName/extractArrayReferenceFromFieldName'

const MinimalNotionalAmount = (
  props: NumericInputProps & { useFastKeys?: boolean }
) => {
  const { arrayIndex } = extractArrayReferenceFromFieldName(props.name)
  const sourceFieldIndex = 0

  const [, , currentFieldHelper] = useField(props.name)
  const [minimumNotionalField1] = useField(
    `legs[${sourceFieldIndex}].minimumNotionalAmount`
  )
  const [notionalField1] = useField(`legs[0].notional`)
  const [notionalField2] = useField(`legs[1].notional`)
  const [notionalField3] = useField(`legs[2].notional`)

  React.useEffect(() => {
    // Execute this effect only when there are legs, and the current field is not the source field
    if (isNil(arrayIndex) || arrayIndex === sourceFieldIndex) return

    // Calculate ratios using notional values
    const notionalRatios = [
      notionalField1.value,
      notionalField2.value,
      notionalField3.value
    ].map(calculateNotionalRatio)

    const currentRatio = notionalRatios[arrayIndex]

    // Verify the presence of a valid ratio for this leg to calculate minimum notional amount
    if (!Number.isNaN(currentRatio)) {
      currentFieldHelper.setValue(minimumNotionalField1.value * currentRatio)
    }
  }, [
    notionalField1.value,
    notionalField2.value,
    notionalField3.value,
    minimumNotionalField1.value,
    currentFieldHelper,
    arrayIndex
  ])

  return (
    <Numeric
      {...props}
      disabled={
        props.disabled ||
        (Boolean(arrayIndex) && arrayIndex !== sourceFieldIndex)
      }
      decimalScale={3}
    />
  )
}

export default MinimalNotionalAmount
