import { Disclosure as HeadlessDisclosure } from '@headlessui/react'
import React from 'react'

type ButtonProps = React.FC<
  React.PropsWithChildren<React.HTMLProps<HTMLButtonElement>>
>

interface DisclosureProps extends React.HTMLProps<HTMLDivElement> {
  buttonContent: (arg: ButtonProps) => JSX.Element
  panelContent: React.ReactNode
  className?: string
}

const Disclosure = React.forwardRef(
  (
    { buttonContent, panelContent, className }: DisclosureProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <HeadlessDisclosure
        ref={ref}
        as="div"
        role="accordion"
        className={className}
      >
        {buttonContent(HeadlessDisclosure.Button as ButtonProps)}
        <HeadlessDisclosure.Panel className="text-textGray">
          {panelContent}
        </HeadlessDisclosure.Panel>
      </HeadlessDisclosure>
    )
  }
)

Disclosure.displayName = 'Disclosure'

export default Disclosure
