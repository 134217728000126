import React from 'react'
import { CurrencyCode } from '../../types/currency.enum'
import removeDuplicateClasses from '../../utils/removeDuplicateClasses/removeDuplicateClasses'
import Numeric from './numeric/numeric'
import NumericCurrency, {
  NumericCurrencyInput
} from './numericCurrency/numericCurrency'
import Strike from './strike/strike'
import Delta from './delta/delta'
import classNames from 'classnames'

import DateTenor, { DateTenorProps } from './dateTenor/dateTenor'
import { AxeFormLegData } from '../dialog/editAxeDialog/editAxeDialog'
import { DotKeys } from '../../types/DotKeys'
import { useField } from 'formik'
import Premium from './premium/premium'
import { NumericFormatProps } from 'react-number-format'
import Swaps from './swaps/swaps'
import Forward from './forward/forward'
import { RFQDialogFormValues } from '../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import Spot from './spot/spot'
import MinimalNotionalAmount from './minimalNotionalAmount/minimalNotionalAmount'
import { tableClassNames } from '../table/table'
import PartyBasedNumeric from './partyBasedNumeric/partyBasedNumeric'

export type FieldArrayName = 'legs'

export type VanillaFormNames =
  | DotKeys<Omit<AxeFormLegData, 'axe_authors'>>
  | 'axeAuthor'

export enum InputVariant {
  DEFAULT,
  CELL
}

// Omit label so that we can make it required
export interface InputProps<
  T extends string = VanillaFormNames | keyof RFQDialogFormValues
> extends Omit<
    React.HTMLProps<HTMLInputElement>,
    'label' | 'value' | 'name' | 'id'
  > {
  currency?: CurrencyCode
  label?: string
  className?: string
  format?: string
  value?: string
  name: T | FieldArrayFieldName<T>
  currencySelector?: boolean
  fieldArrayPrefix?: FieldArrayPrefix
}

export type FieldArrayFieldName<
  T extends string = VanillaFormNames | keyof RFQDialogFormValues
> = `${FieldArrayName}[${number}].${T}`

export type FieldArrayPrefix = `${FieldArrayName}[${number}]`

export type PricingVolatility =
  | 'pricingVolatility'
  | 'pricingVolatilityT2'
  | 'pricingVolatilityT3'

export interface NumericInputProps<
  T extends string =
    | VanillaFormNames
    | keyof RFQDialogFormValues
    | PricingVolatility
> extends Omit<
    NumericFormatProps,
    'label' | 'value' | 'name' | 'type' | 'onValueChange' | 'defaultValue'
  > {
  currency?: CurrencyCode
  label?: string
  className?: string
  format?: string
  value?: string
  name: T | FieldArrayFieldName<T>
  currencySelector?: boolean
  type: 'number'
  fieldArrayPrefix?: FieldArrayPrefix
  isRefreshable?: boolean
  onValueChange?: (value: number) => void
  overrideValue?: number
}

export interface DateProps extends Omit<InputProps, 'value'> {
  type: 'date'
  value?: Date
}

export interface NumericInput
  extends Omit<NumericInputProps, 'value' | 'onChange'> {
  value?: string | number
}

type InputTypes =
  | InputProps
  | NumericInput
  | NumericCurrencyInput
  | DateProps
  | DateTenorProps

const isNumericCurrencyInput = (
  field: InputTypes
): field is NumericCurrencyInput =>
  field.type === 'number' && !!field.currencySelector

export const isNumericInput = (field: InputTypes): field is NumericInput => {
  return field.type === 'number'
}

const isStrikeNumericInput = (field: InputTypes): field is NumericInput => {
  return isNumericInput(field) && field.name.endsWith('strike')
}

const isSwapsNumericInput = (field: InputTypes): field is NumericInput => {
  return isNumericInput(field) && field.name.endsWith('swaps')
}

const isForwardNumericInput = (field: InputTypes): field is NumericInput => {
  return isNumericInput(field) && field.name.endsWith('forward')
}

const isSpotNumericInput = (field: InputTypes): field is NumericInput => {
  return isNumericInput(field) && field.name.endsWith('spot')
}

const isMinimumNotionalAmountInput = (
  field: InputTypes
): field is NumericInput => {
  return isNumericInput(field) && field.name.endsWith('minimumNotionalAmount')
}

const isPremiumNumericCurrency = (
  field: InputTypes
): field is NumericCurrencyInput => {
  return isNumericCurrencyInput(field) && field.name.endsWith('premium')
}

const isDeltaNumericInput = (field: InputTypes): field is NumericInputProps => {
  return (
    isNumericInput(field) &&
    (field.name.endsWith('delta') || field.name.endsWith('bsDelta'))
  )
}

const isGammaNumericInput = (field: InputTypes): field is NumericInputProps => {
  return isNumericInput(field) && field.name.endsWith('gamma')
}

const isVegaNumericInput = (field: InputTypes): field is NumericInputProps => {
  return isNumericInput(field) && field.name.endsWith('vega')
}

const isDateTenorInput = (field: InputTypes): field is DateTenorProps =>
  field.name.endsWith('expiryDate')

export default function Input({
  className,
  currency,
  placeholder,
  ...field
}: InputProps) {
  return (
    <div className="h-full w-full">
      <label htmlFor={field.name} className="visually-hidden">
        {field.name}
      </label>
      <div className="h-full flex">
        {currency && (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-textGray text-sm">{currency}</span>
          </div>
        )}
        <Field
          className={className}
          currency={currency}
          placeholder={placeholder}
          {...field}
        />
      </div>
    </div>
  )
}

const Field = ({
  className,
  placeholder,
  fieldArrayPrefix,
  ...field
}: InputProps) => {
  const [fieldProps] = useField(field.name)
  const value = fieldProps.value ?? ''

  const classes = removeDuplicateClasses(
    'block w-full border-baseGray dark:border-lightBlue focus:border-indigo-500 focus:ring-indigo-500 text-[12px] bg-transparent',
    className
  )

  if (isPremiumNumericCurrency(field)) {
    return (
      <Premium
        className={classes}
        placeholder={placeholder}
        fieldArrayPrefix={fieldArrayPrefix}
        {...field}
        value={value}
      />
    )
  }

  if (isNumericCurrencyInput(field)) {
    return (
      <NumericCurrency
        className={classes}
        placeholder={placeholder}
        fieldArrayPrefix={fieldArrayPrefix}
        {...field}
        value={value}
        useFastKeys={field.name.endsWith('notional')}
      />
    )
  }

  if (isDateTenorInput(field)) {
    return (
      <DateTenor
        className={classes}
        placeholder={placeholder}
        fieldArrayPrefix={fieldArrayPrefix}
        {...field}
        value={value}
      />
    )
  }

  if (isStrikeNumericInput(field)) {
    return (
      <Strike
        className={classes}
        placeholder={placeholder}
        fieldArrayPrefix={fieldArrayPrefix}
        {...(field as NumericInputProps)}
        value={value}
      />
    )
  }

  if (isDeltaNumericInput(field)) {
    return (
      <Delta
        className={classes}
        placeholder={placeholder}
        fieldArrayPrefix={fieldArrayPrefix}
        {...(field as NumericInputProps)}
        value={value}
      />
    )
  }

  if (isSwapsNumericInput(field)) {
    return (
      <Swaps
        className={classes}
        placeholder={placeholder}
        fieldArrayPrefix={fieldArrayPrefix}
        {...(field as NumericInputProps)}
        value={value}
      />
    )
  }

  if (isForwardNumericInput(field)) {
    return (
      <Forward
        className={classes}
        placeholder={placeholder}
        fieldArrayPrefix={fieldArrayPrefix}
        {...(field as NumericInputProps)}
        value={value}
      />
    )
  }

  if (isSpotNumericInput(field)) {
    return (
      <Spot
        className={classes}
        placeholder={placeholder}
        fieldArrayPrefix={fieldArrayPrefix}
        {...(field as NumericInputProps)}
        value={value}
      />
    )
  }

  if (isMinimumNotionalAmountInput(field)) {
    return (
      <MinimalNotionalAmount
        {...(field as NumericInputProps)}
        useFastKeys={(field as NumericInputProps).name.endsWith(
          'minimumNotionalAmount'
        )}
      />
    )
  }

  if (isGammaNumericInput(field) || isVegaNumericInput(field)) {
    return (
      <PartyBasedNumeric
        className={classes}
        placeholder={placeholder}
        fieldArrayPrefix={fieldArrayPrefix}
        {...(field as NumericInputProps)}
        value={value}
      />
    )
  }

  if (isNumericInput(field)) {
    return (
      <Numeric
        className={classes}
        placeholder={placeholder}
        {...(field as NumericInputProps)}
        value={value}
      />
    )
  }

  return (
    <input
      className={classNames(
        classes,
        'p-0 border-none bg-transparent text-[12px] disabled:text-textLightGray disabled:cursor-not-allowed',
        tableClassNames.cellHeight,
        tableClassNames.cellPadLeft
      )}
      placeholder={placeholder}
      id={(field as InputProps).name}
      {...(field as InputProps)}
      value={value}
    />
  )
}
