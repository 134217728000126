import ComboBox, { ComboBoxProps } from '../comboBox'
import useFieldSync from '../../../hooks/useFieldSync/useFieldSync'
import { InputVariant } from '../../input/input'

const CcyPairComboBox = (props: ComboBoxProps) => {
  const { isDisabled } = useFieldSync(props.name)

  return (
    <ComboBox
      {...props}
      disabled={props.disabled || isDisabled}
      variant={InputVariant.CELL}
    />
  )
}

export default CcyPairComboBox
