import { useField } from 'formik'
import ComboBox, { ComboBoxProps } from '../comboBox'
import React from 'react'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'

const HedgeTypeComboBox = ({ fieldArrayPrefix, ...props }: ComboBoxProps) => {
  const [field] = useField(props.name)
  const [, , premiumHelper] = useField(
    generateFieldName('premiumType', fieldArrayPrefix)
  )

  React.useEffect(() => {
    if (field.value === undefined) return

    premiumHelper.setValue(field.value)
  }, [field.value, premiumHelper])

  return <ComboBox {...props} />
}

export default HedgeTypeComboBox
