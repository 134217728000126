import React from 'react'
import { Axe_Legs, Axes } from '../../gql'
import { useFormikContext } from 'formik'
import { useInputStateContext } from '../inputStateContext/inputStateContext'
import toast from 'react-hot-toast'
import {
  tableRowGreeks,
  tableRows
} from '../../components/buttons/createAxeDialogButton/tableRows'
import { InputProps } from '../../components/input/input'
import { PostMessage } from '../../workers/marketDataWorker/marketDataWorker'
import isNumber from 'lodash/isNumber'
import merge from 'lodash/merge'
import roundToDecimal from '../../utils/roundToDecimal/roundToDecimal'
import convertFlatToNestedValues from './utils/convertFlatToNestedValues/convertFlatToNestedValues'
import calculateSpotSwapsForwardOnCcyPairChange from './utils/calculateSpotSwapsForwardOnCcyPairChange/calculateSpotSwapsForwardOnCcyPairChange'
import calculateSpotSwapsForwardOnExpiryDataChange from './utils/calculateSpotSwapsForwardOnExpiryDataChange/calculateSpotSwapsForwardOnExpiryDataChange'
import { AxeFormData } from '../../components/dialog/editAxeDialog/editAxeDialog'
import { extractArrayReferenceFromFieldName } from '../../utils/extractArrayReferenceFromFieldName/extractArrayReferenceFromFieldName'
import calculateSpotSwapsForwardOnRefreshAll from './utils/calculateSpotSwapsForwardOnRefreshAll/calculateSpotSwapsForwardOnRefreshAll'
import getRatioAdjustedDeltaValues from '../../utils/getRatioAdjustedDeltaValues/getRatioAdjustedDeltaValues'
import isEmpty from 'lodash/isEmpty'

interface GetMarketValuesParams {
  values: AxeFormData
  refreshAll?: boolean
  refreshField?: InputProps['name']
}

interface WorkerContextProps {
  worker?: Worker
  workerFetching: boolean
  workerError: boolean
  getMarketValues: ({
    values,
    refreshAll,
    refreshField
  }: GetMarketValuesParams) => void
  resetMarketValues: () => void
  setLastChangedField: (field: InputProps['name']) => void
}

export type MarketDataValues = Record<InputProps['name'], number | string>

export type AxeFormValues = Axes & { legs: Axe_Legs[]; deltaHedge?: number }

export const WorkerContext = React.createContext<WorkerContextProps>({
  worker: undefined,
  workerFetching: false,
  workerError: false,
  getMarketValues: () => undefined,
  resetMarketValues: () => undefined,
  setLastChangedField: () => undefined
})

export const useWorkerContext = () => React.useContext(WorkerContext)

const getTablePropsForInput = (key: string) =>
  [...tableRows, ...tableRowGreeks].find((row) =>
    key.endsWith(row.rowInputProps.name as string)
  )

export const calculateForward = (
  spot: number | string,
  swaps: number | string
) => {
  const spotValue = isNumber(spot) ? spot : parseFloat(spot)
  const swapsValue = isNumber(swaps) ? swaps : parseFloat(swaps)

  return roundToDecimal(spotValue + swapsValue, 5)
}

export const calculateSwaps = (
  spot: number | string,
  forward: number | string
) => {
  const spotValue = isNumber(spot) ? spot : parseFloat(spot)
  const forwardValue = isNumber(forward) ? forward : parseFloat(forward)

  return roundToDecimal(forwardValue - spotValue, 5)
}

const adjustDeltaValuesInlineWithDeltaHedge = (
  values: AxeFormValues,
  marketDataValues: MarketDataValues
): MarketDataValues => {
  // calculate the adjusted delta values if we have the delta hedge.
  // When the delta hedge is present, we calculate the ratio of the delta values across and the legs
  // and adjust the values so that the summary delta = deltaHedge
  const { deltaHedge } = values
  const deltaValues = Object.entries(marketDataValues).filter(([key]) =>
    key.endsWith('delta')
  )

  if (deltaHedge && !isEmpty(deltaValues)) {
    const deltaKeys = deltaValues.map(([key]) => key)
    const ratioAdjustedDeltaValues = getRatioAdjustedDeltaValues(
      deltaValues.map(([, value]) => value as number),
      deltaHedge
    )
    // Assign the adjusted delta values to the original leg key
    deltaKeys.forEach((key, index) => {
      marketDataValues[key as keyof MarketDataValues] =
        ratioAdjustedDeltaValues[index]
    })
  }

  return marketDataValues
}

export const leg1SpotFieldName: InputProps['name'] = 'legs[0].spot'

export default function WorkerContextProvider({
  children
}: React.PropsWithChildren) {
  const workerRef = React.useRef<Worker>()
  const { state, dispatch } = useInputStateContext()
  const { values, setValues } = useFormikContext<AxeFormValues>()
  const [workerFetching, setWorkerFetching] = React.useState(false)
  const [workerError, setWorkerError] = React.useState(false)
  const [marketDataValues, setMarketDataValues] =
    React.useState<MarketDataValues>()
  const [lastChangedField, setLastChangedField] =
    React.useState<InputProps['name']>()

  const processMarketDataValues = React.useCallback(
    (marketDataValues: MarketDataValues) => {
      return Object.entries(
        adjustDeltaValuesInlineWithDeltaHedge(values, marketDataValues)
      ).reduce<MarketDataValues>((acc, [key, value]) => {
        // Set flag on input fields that contain a fetched value. This value might not be displayed if the user has overtyped it
        // however we want to keep track of it so that we can reset the field to the fetched value if the user clicks the refresh button
        const fieldKey = values[key as keyof Axes]?.value ? `${key}.value` : key

        // If the form field can be "refreshed", we want to tell the app that it contains a fetched value
        // and therefor can be refreshed
        if (getTablePropsForInput(key)?.rowRefresh) {
          dispatch({
            type: 'set_contains_fetched_value',
            key: fieldKey as InputProps['name']
          })
        }

        // Temporary check to make sure an arrayIndex / sourceLegIndex exists so that the logic only applies when multi-leg axes are turned on
        const { arrayIndex: sourceLegIndex } =
          extractArrayReferenceFromFieldName(lastChangedField)

        // Trigger logic to update spot, swaps, and forward values for each leg when the ccyPair changes
        if (lastChangedField?.endsWith('ccyPair') && sourceLegIndex !== null) {
          // The values for spot/swaps/forward will be processed when handling the 'spot' key, so skip processing for swaps/forward keys to prevent multiple updates
          if (key.endsWith('swaps') || key.endsWith('forward')) return acc

          // For the spot field, calculate the spot/swaps/forward values
          if (key.endsWith('spot')) {
            return {
              ...acc,
              ...calculateSpotSwapsForwardOnCcyPairChange({
                dispatch,
                marketDataValues,
                values
              })
            }
          }

          // For non-spot fields, update the value directly without additional logic
        }

        // Trigger logic to update spot, swaps, and forward values for each leg when the expiryDate changes, or if the spot/swaps/forward values trigger the change via a refresh
        if (
          ['expiryDate', 'tenor', 'spot', 'swaps', 'forward'].some((suffix) =>
            lastChangedField?.endsWith(suffix)
          ) &&
          sourceLegIndex !== null
        ) {
          // The values for spot/swaps/forward will be processed when handling the 'spot' key, so skip processing for swaps/forward keys to prevent multiple updates
          if (key.endsWith('swaps') || key.endsWith('forward')) return acc

          // For the spot field, calculate the spot/swaps/forward values
          if (key.endsWith('spot')) {
            return {
              ...acc,
              ...calculateSpotSwapsForwardOnExpiryDataChange({
                dispatch,
                lastChangedField: lastChangedField as InputProps['name'],
                marketDataValues,
                state,
                values
              })
            }
          }

          // For non-spot fields, update the value directly without additional logic
        }

        // The 'refresh all' action replaces all values with market data, despite any overtyped state
        if (
          lastChangedField?.endsWith('refreshAll') &&
          sourceLegIndex !== null
        ) {
          // The values for spot/swaps/forward will be processed when handling the 'spot' key, so skip processing for swaps/forward keys to prevent multiple updates
          if (key.endsWith('swaps') || key.endsWith('forward')) return acc

          // For the spot field, calculate the spot/swaps/forward values
          if (key.endsWith('spot')) {
            return {
              ...acc,
              ...calculateSpotSwapsForwardOnRefreshAll({
                dispatch,
                marketDataValues,
                sourceLegIndex,
                state,
                values
              })
            }
          }

          // For non-spot fields, update the value directly without additional logic
        }

        // Skip updating the value if the key has been overtyped, except if this key was the last one changed or if a refresh is required for all fields
        if (
          state[fieldKey]?.overTyped &&
          !lastChangedField?.endsWith(key) &&
          !lastChangedField?.endsWith('refreshAll')
        )
          return acc

        // Round the value to the nearest whole number if the key is either 'premium' or 'delta'.
        if (fieldKey.endsWith('premium') || fieldKey.endsWith('delta')) {
          acc[key as keyof MarketDataValues] = Math.round(value as number)

          return acc
        }

        acc[key as keyof MarketDataValues] = value

        return acc
      }, {} as MarketDataValues)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, lastChangedField, JSON.stringify(state), values] // TODO - remove JSON.stringify(state) from dependency array
  )

  // When a worker fetches and sets new market data, trigger this effect to update the form state with the new values.
  // The process involves several steps to handle specific field interactions - such as spot/swaps/forwards and currency pair updates.
  React.useEffect(() => {
    if (!marketDataValues) return

    const processedMarketDataValues = processMarketDataValues(marketDataValues)
    const updatedValues = convertFlatToNestedValues(processedMarketDataValues)
    const mergedValues = merge({}, values, updatedValues)

    setValues(mergedValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketDataValues])

  const getCachedMarketValues = React.useCallback(
    (values: Axes, lastChangedField?: InputProps['name']) => {
      if (!workerRef.current) return

      workerRef.current.postMessage({
        values,
        useCache: true,
        lastChangedField
      })
    },
    [workerRef]
  )

  const getMarketValues = React.useCallback(
    ({ values, refreshAll = false, refreshField }: GetMarketValuesParams) => {
      if (!workerRef.current) return

      const newLastChangedField = refreshAll
        ? ('legs[0].refreshAll' as InputProps['name'])
        : refreshField

      setLastChangedField(newLastChangedField)

      workerRef.current.postMessage({
        values,
        useCache: false,
        lastChangedField: newLastChangedField,
        refreshAll
      })
    },
    [workerRef]
  )

  React.useEffect(() => {
    getCachedMarketValues(values, lastChangedField)
  }, [getCachedMarketValues, lastChangedField, values])

  React.useEffect(() => {
    if (!window.Worker) return
    workerRef.current = new Worker(
      new URL(
        '../../workers/marketDataWorker/marketDataWorker',
        import.meta.url
      )
    )

    workerRef.current.onmessage = (e: MessageEvent<PostMessage>) => {
      if (e && e.data) {
        const { fetching, formData, error, requestError } = e.data

        if (error) {
          toast.error('Fetching market data failed. Please refresh.', {
            duration: 4000
          })
        }

        // maintain state in context if requestError of fetching return undefined
        setWorkerError((stateRequestError) =>
          requestError === undefined ? stateRequestError : requestError
        )
        setWorkerFetching((stateFetchingValue) =>
          fetching === undefined ? stateFetchingValue : fetching
        )

        formData &&
          setMarketDataValues(
            formData as unknown as Record<InputProps['name'], string>
          )
      }
    }

    return () => {
      workerRef.current?.terminate()
    }
  }, [])

  const resetMarketValues = () => setMarketDataValues(undefined)

  return (
    <WorkerContext.Provider
      value={{
        workerError,
        workerFetching,
        resetMarketValues,
        worker: workerRef.current,
        getMarketValues,
        setLastChangedField
      }}
    >
      {children}
    </WorkerContext.Provider>
  )
}
