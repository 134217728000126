// If max and/or min is defined, prevent the user from entering a value outside of the range
const isInsideDefinedRange = (
  floatValue?: number,
  max?: string | number,
  min?: string | number
): boolean => {
  if (floatValue === undefined) return true

  if (max !== undefined && floatValue > Number(max)) {
    return false
  }

  if (min !== undefined && floatValue < Number(min)) {
    return false
  }

  return true
}

export default isInsideDefinedRange
