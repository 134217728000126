import { Tier } from '../../components/tierSelector/tierSelector'
import { user_tiers_insert_input } from '../../components/buttons/createAxeDialogButton/__generated__/createAxeDialogButtonMutation.graphql'

export const createAxeUserTiersForAxe = (
  axeId: string,
  userId: string,
  tiers: Tier[],
  removedSubOrgIds: string[]
): user_tiers_insert_input[] => {
  const userTiers: user_tiers_insert_input[] = []
  tiers.forEach((tier) => {
    tier.entities.forEach((entity) => {
      entity.members.forEach((member) => {
        userTiers.push({
          tierId: tier.id,
          userId: userId,
          subOrganizationId: member.id,
          organizationId: member.organizationId || '',
          axeId,
          isRemoved: removedSubOrgIds.includes(member.id)
        })
      })
    })
  })

  return userTiers
}
