import { useField } from 'formik'
import NumericCurrency, {
  NumericCurrencyInput
} from '../numericCurrency/numericCurrency'
import React from 'react'
import { useWorkerContext } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import { usePrevious } from 'react-use'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import { getPremiumSuffix } from '../../tableRow/components/summaryCell/summaryCell'
import { invertSignUnlessInAuthorSubOrg } from '../../../utils/axeCalculations/axeCalculations'
import { usePartyBasedValue } from '../../../hooks/usePartyBasedValue/usePartyBasedValue'

/**
 * Calculations used here:
 *
 * CCY1 Notional and CCY1 Premium = Premium %CCY1 = (Premium CCY1 / Notional CCY1) * 100
 * CCY2 Notional and CCY1 Premium = Premium CCY1 Pips = (Premium CCY1 / Notional CCY2)*(Decimals in CCY1CCY2)
 *
 * CCY1 Notional and CCY2 Premium = Premium CCY2 Pips = (Premium CCY2 / Notional CCY1)/(Decimals in CCY1CCY2)
 * CCY2 Notional and CCY2 Premium = Premium %CCY2 = (Premium CCY2 / Notional CCY2) * 100
 *
 * From formulae found here: https://docs.google.com/spreadsheets/d/1JWNRHwgWPE5vIAmYZKSGSRsnxurMcLvb
 */

/**
 *  Note - The request was to display this primarily as the percentage value, however
 *  we receive the market data value for the premium as a currency value and to convert it at source to a percentage
 *  and then back again to a currency value would result in a loss of precision. As a result, we display the value as
 *  1,000,000 (0.100%)
 */
const Premium = ({ fieldArrayPrefix, ...props }: NumericCurrencyInput) => {
  const [notionalField] = useField(
    generateFieldName('notional', fieldArrayPrefix)
  )
  const [notionalCurrencyField] = useField(
    generateFieldName('notionalCurrency', fieldArrayPrefix)
  )
  const [premiumCurrencyField] = useField(
    generateFieldName('premiumCurrency', fieldArrayPrefix)
  )
  const [premiumField] = useField(
    generateFieldName('premium', fieldArrayPrefix)
  )
  const [ccyPairField] = useField(
    generateFieldName('ccyPair', fieldArrayPrefix)
  )
  const [suffix, setSuffix] = React.useState<string | undefined>(undefined)
  const prevPremium = usePrevious(premiumField?.value)
  const { workerFetching } = useWorkerContext()
  const premium = premiumField?.value

  const overrideValue = usePartyBasedValue({
    currentValue: premium,
    calculatePartyBasedValue: ({ currentValue, isInAuthorSubOrg }) => {
      return invertSignUnlessInAuthorSubOrg(currentValue, isInAuthorSubOrg)
    }
  })

  React.useEffect(() => {
    // we know that a change in currency will cause a change in the premium
    // so check that it changes before we update the suffix so that we don't
    // get a flash of the wrong value
    if (
      premium === undefined ||
      (!overrideValue && (premium === prevPremium || workerFetching))
    )
      return

    const suffix = getPremiumSuffix({
      ccyPair: ccyPairField?.value,
      notional: notionalField?.value,
      notionalCurrency: notionalCurrencyField?.value,
      premium: overrideValue || premium,
      premiumCurrency: premiumCurrencyField?.value
    })

    setSuffix(suffix)
  }, [
    ccyPairField?.value,
    notionalCurrencyField?.value,
    notionalField?.value,
    overrideValue,
    premium,
    premiumCurrencyField?.value,
    prevPremium,
    props.disabled,
    workerFetching
  ])

  return (
    <NumericCurrency
      {...props}
      fieldArrayPrefix={fieldArrayPrefix}
      value={premiumField?.value}
      disableNumeric
      suffix={suffix}
      overrideValue={overrideValue}
    />
  )
}

export default Premium
