import isArray from 'lodash/isArray'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import { FieldNamePathArray } from '../../formDataProcessor'

const isFieldNamePathArray = (
  path: unknown[] | undefined
): path is FieldNamePathArray =>
  isArray(path) && path[0] === 'legs' && isNumber(path[1]) && isString(path[2])

export default isFieldNamePathArray
