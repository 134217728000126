import { NumberFormatValues } from 'react-number-format'
import { InputProps, NumericInputProps } from '../input'
import { useField } from 'formik'
import toFixedWithoutZeros from '../../../utils/toFixedWithoutZeros/toFixedWithoutZeros'
import IncrementInputWrapper from '../incremementInputWrapper/incremementInputWrapper'
import isInsideDefinedRange from '../helpers/isInsideDefinedRange'
import { useWorkerContext } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import isNil from 'lodash/isNil'
import RefreshButton from '../../buttons/refreshButton/refreshButton'
import { NumericBase } from './base/numericBase'
import NumericFastKeysBase from './base/numericFastKeysBase'

export const getPrecision = (value: number): number => {
  const decimalPlaces = value.toString().split('.')[1]?.length || 0

  return decimalPlaces
}

export const formatIncrementDecrementValue = (
  fieldValue: number,
  step: string | number
) => parseFloat(toFixedWithoutZeros(fieldValue, getPrecision(Number(step))))

function Numeric({
  className,
  placeholder,
  step = 0,
  max,
  min,
  name,
  disabled,
  onBlur,
  onValueChange,
  onFocus,
  suffix,
  prefix,
  decimalScale,
  isRefreshable,
  useFastKeys,
  overrideValue
}: NumericInputProps & { useFastKeys?: boolean }) {
  const [field, , helpers] = useField<number>(name)
  const { setLastChangedField } = useWorkerContext()

  /**
   * We can use onValueChange (see https://s-yadav.github.io/react-number-format/docs/props#onvaluechange-values-sourceinfo--)
   * to return the unformatted numeric value to Formik, otherwise we're sending the string formatted value
   */
  const handleValueChange = (values: NumberFormatValues): void => {
    if (
      !isNil(values.floatValue) &&
      !isNaN(values.floatValue) &&
      isInsideDefinedRange(values.floatValue, max, min)
    ) {
      setLastChangedField(field.name as InputProps['name'])
      onValueChange?.(values.floatValue)

      // If the field has an override value, then we don't want to update the field value as this repeatedly triggering state updates
      if (overrideValue) return
      helpers.setValue(values.floatValue)
    }
  }

  const fieldWithOverrideValue = {
    ...field,
    value: overrideValue ?? field.value
  }

  return (
    <>
      <IncrementInputWrapper
        className={className}
        step={step}
        max={max}
        min={min}
        name={name}
        disabled={disabled}
      >
        {(onChange) => (
          <>
            {useFastKeys ? (
              <NumericFastKeysBase
                disabled={disabled}
                refreshable={isRefreshable}
                placeholder={placeholder}
                field={fieldWithOverrideValue}
                max={max}
                min={min}
                decimalScale={decimalScale}
                onValueChange={handleValueChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                allowed={({ floatValue }) =>
                  isInsideDefinedRange(floatValue, max, min)
                }
                thousandSeparator=","
                helpers={helpers}
              />
            ) : (
              <NumericBase
                disabled={disabled}
                refreshable={isRefreshable}
                placeholder={placeholder}
                field={fieldWithOverrideValue}
                max={max}
                min={min}
                suffix={suffix}
                prefix={prefix}
                decimalScale={decimalScale}
                onValueChange={handleValueChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                allowed={({ floatValue }) =>
                  isInsideDefinedRange(floatValue, max, min)
                }
              />
            )}
          </>
        )}
      </IncrementInputWrapper>
      {isRefreshable && !disabled && (
        <RefreshButton
          name={field.name as InputProps['name']}
          // right-[22px] is to cater for the increment controls
          className={`absolute right-[22px] top-0`}
        />
      )}
    </>
  )
}

export default Numeric
