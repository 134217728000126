import { InputProps, InputVariant } from '../input'
import classNames from 'classnames'
import { generateOptionsFromStringEnum } from '../../buttons/createAxeDialogButton/tableRows'
import { useField } from 'formik'
import { DateTime, DurationLikeObject } from 'luxon'
import React from 'react'
import { Enum_Tenor_Enum } from '../../../gql'
import { normalizeTenorNames } from '../../../utils/normalizeTenorNames/normalizeTenorNames'
import { InputSelectOption } from '../../../types/inputSelect'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import { useWorkerContext } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import ComboBox from '../../comboBox/comboBox'
import { tableClassNames } from '../../table/table'

export interface DateTenorProps extends Omit<InputProps, 'value'> {
  value?: string | null
}

/**
 * Tenors e.g. 1D, 2D, 1W, 1M, 6M, 1Y need to be sorted in the correct order
 * @param a ListBoxOption
 * @param b ListBoxOption
 */
const sortTenors = (a: InputSelectOption, b: InputSelectOption) => {
  let aValue = 0,
    bValue = 0

  if (a.name.endsWith('D')) {
    aValue = parseInt(a.name.slice(0, -1)) * 100
  } else if (a.name.endsWith('W')) {
    aValue = parseInt(a.name.slice(0, -1)) * 1000
  } else if (a.name.endsWith('M')) {
    aValue = parseInt(a.name.slice(0, -1)) * 10000
  } else if (a.name.endsWith('Y')) {
    aValue = parseInt(a.name.slice(0, -1)) * 100000
  }

  if (b.name.endsWith('D')) {
    bValue = parseInt(b.name.slice(0, -1)) * 100
  } else if (b.name.endsWith('W')) {
    bValue = parseInt(b.name.slice(0, -1)) * 1000
  } else if (b.name.endsWith('M')) {
    bValue = parseInt(b.name.slice(0, -1)) * 10000
  } else if (b.name.endsWith('Y')) {
    bValue = parseInt(b.name.slice(0, -1)) * 100000
  }

  return aValue - bValue
}

const tenorOptions = [
  {
    name: 'Tenor',
    unavailable: true
  },
  ...normalizeTenorNames(generateOptionsFromStringEnum(Enum_Tenor_Enum)).sort(
    sortTenors
  )
]

const setDateValueFromTenor = (value: string, tenor?: string) => {
  if (tenor === Enum_Tenor_Enum.ON) {
    return DateTime.now().plus({ days: 1 }).toISODate()
  }

  const { dateValue, identifier } =
    /(?<dateValue>\d+)(?<identifier>\w+)/gi.exec(tenor || '')?.groups || {}

  switch (identifier) {
    case 'D':
      return DateTime.now()
        .plus({ days: parseInt(dateValue) })
        .toFormat('yyyy-MM-dd')
    case 'W':
      return DateTime.now()
        .plus({ weeks: parseInt(dateValue) })
        .toFormat('yyyy-MM-dd')
    case 'M':
      return DateTime.now()
        .plus({ months: parseInt(dateValue) })
        .toFormat('yyyy-MM-dd')
    case 'Y':
      return DateTime.now()
        .plus({ years: parseInt(dateValue) })
        .toFormat('yyyy-MM-dd')
    default:
      return value
  }
}

const setTenorValueFromDate = (value: string) => {
  const date = DateTime.fromISO(value)
  const now = DateTime.now().startOf('day')
  const diff = date.diff(now, ['days', 'weeks', 'months', 'years'])

  // 7 days is 1 week etc, hence the max values
  const units: {
    unit: keyof DurationLikeObject
    max: number
    format: string
  }[] = [
    { unit: 'days', max: 6, format: 'D' },
    { unit: 'weeks', max: 3, format: 'W' },
    { unit: 'months', max: 11, format: 'M' },
    { unit: 'years', max: 10, format: 'Y' }
  ]

  const result = units.find(({ unit, max }) => {
    const value = diff.as(unit)

    return value >= 1 && value % 1 === 0 && value <= max
  })

  if (result) {
    const value = `t_${diff.as(result.unit)}${result.format}`

    return value === 't_1D' ? Enum_Tenor_Enum.ON : value
  }

  return ''
}

export default function DateTenor({
  placeholder,
  value,
  className,
  fieldArrayPrefix,
  ...field
}: DateTenorProps) {
  const [tenorFieldProps, , tenorHelpers] = useField(
    generateFieldName('tenor', fieldArrayPrefix)
  )
  const [expiryDateFieldProps, , expiryDateHelpers] = useField(
    generateFieldName('expiryDate', fieldArrayPrefix)
  )
  const { setLastChangedField } = useWorkerContext()

  React.useEffect(() => {
    if (tenorFieldProps.value || expiryDateFieldProps.value) {
      const fieldValue = setDateValueFromTenor(
        expiryDateFieldProps.value,
        tenorFieldProps.value
      )

      if (expiryDateFieldProps.value !== fieldValue) {
        expiryDateHelpers.setValue(fieldValue)
      }
    }
    // ignoring as helpers isn't memoized, see https://github.com/jaredpalmer/formik/issues/2982
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryDateFieldProps.value, tenorFieldProps.value, value])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLastChangedField(field.name as InputProps['name'])

    expiryDateHelpers.setValue(e.target.value)
    // when the expiry date is set to a tenor value, the tenor value should be set
    tenorHelpers.setValue(setTenorValueFromDate(e.target.value))
  }

  /**
   * Remove the tenor input when this field is disabled
   * as the tenor aids input UX, but the expiry date is the important value for display
   */
  return (
    <div className={classNames(className, 'flex w-full h-full pl-0 py-0')}>
      {!field.disabled && (
        <div className="shrink-0 basis-[64px] border-r-[1px] dark:border-r-lightBlue border-r-borderGray">
          <ComboBox
            options={tenorOptions}
            name={generateFieldName('tenor', fieldArrayPrefix)}
            disabled={field.disabled}
            variant={InputVariant.CELL}
          />
        </div>
      )}
      <input
        {...field}
        id={field.name}
        className={classNames(
          'flex-grow p-0 border-none bg-transparent text-[12px] disabled:text-textLightGray disabled:cursor-not-allowed',
          tableClassNames.cellHeight,
          tableClassNames.cellPadLeft,
          tableClassNames.cellPadRight
        )}
        aria-label="Choose Expiration Date"
        placeholder={placeholder}
        name={field.name}
        value={expiryDateFieldProps.value || ''}
        onChange={handleChange}
      />
    </div>
  )
}
