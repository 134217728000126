import classNames from 'classnames'
import Search, { SearchVariant } from '../../../components/search/search'

export enum TierSearchVariant {
  DEFAULT,
  REMOVED
}

export const TierSearch = ({
  name,
  clients,
  variant = TierSearchVariant.DEFAULT,
  setQuery,
  query
}: {
  name: string
  clients: number
  setQuery: React.Dispatch<React.SetStateAction<string>>
  query: string
  variant?: TierSearchVariant
}) => {
  return (
    <div
      className={classNames(
        { 'mx-2': variant === TierSearchVariant.DEFAULT },
        'bg-lightGray dark:bg-darkBlue rounded-xl p-4  flex-row'
      )}
    >
      <div className="flex ">
        <span className="flex text-sm font-bold text-center">{name}</span>
        <span className="flex text-sm ml-1 text-textGray dark:text-white">
          | {clients} Clients
        </span>
      </div>
      <Search
        onInputChange={(event) => {
          setQuery(event.target.value)
        }}
        name="search"
        variant={SearchVariant.Filter}
        placeholder={`Search ${name} groups/clients`}
        value={query}
      />
    </div>
  )
}
