import { axe_tier_pricing_insert_input } from '../../components/buttons/createAxeDialogButton/__generated__/createAxeDialogButtonMutation.graphql'
import { TierNames } from '../../components/forms/createAxe/createAxeTiers'
import { Tier } from '../../components/tierSelector/tierSelector'
import { Enum_Tiers_Enum } from '../../gql'

const getTierId = (tiers: Tier[], tierName: string): string => {
  return tiers.find((tier) => tier.name === tierName)?.id || ''
}

export const createAxePricingTiers = (
  axeId: string,
  pricingVolatility: number,
  pricingVolatilityT2: number,
  pricingVolatilityT3: number,
  tiers: Tier[]
): axe_tier_pricing_insert_input[] => {
  return [
    {
      id: getTierId(tiers, TierNames.Tier1),
      axeId,
      pricingVolatility: pricingVolatility,
      tier: Enum_Tiers_Enum.T_1
    },
    {
      id: getTierId(tiers, TierNames.Tier2),
      axeId,
      pricingVolatility: pricingVolatilityT2,
      tier: Enum_Tiers_Enum.T_2
    },
    {
      id: getTierId(tiers, TierNames.Tier3),
      axeId,
      pricingVolatility: pricingVolatilityT3,
      tier: Enum_Tiers_Enum.T_3
    }
  ]
}
