/* eslint-disable  no-unused-vars, camelcase */

import {
  Enum_Axe_Buysell_Enum,
  Enum_Axe_Callput_Enum,
  Enum_Axe_Ccypair_Enum,
  Enum_Axe_Hedgetype_Enum,
  Enum_Axe_Product_Enum
} from '../../../gql'
import { Row, RowInputValue } from '../../table/table'
import { ListBoxProps } from '../../listBox/listBox'
import { InputSelectOption } from '../../../types/inputSelect'
import { ComboBoxProps } from '../../comboBox/comboBox'
import { NumericInputProps, VanillaFormNames } from '../../input/input'
import capitalize from 'lodash/capitalize'

export interface VanillaFieldRow
  extends Omit<Row<VanillaFormNames>, 'rowInputProps'> {
  rowInputProps:
    | RowInputValue<VanillaFormNames>
    | ListBoxProps
    | ComboBoxProps
    | NumericInputProps
}

type StringEnum = { [s: string]: string }

const isUpperCase = (str: string): boolean => /\d|[A-Z]/.test(str)

export const generateOptionsFromStringEnum = <T extends StringEnum>(
  enumType: T
): Array<InputSelectOption> => {
  return Object.values<string>(enumType).map((value) => {
    return {
      name: isUpperCase(value) ? value : capitalize(value),
      type: isUpperCase(value) ? value : capitalize(value),
      value
    }
  })
}

// Vanilla should be the first option in the product dropdown
const generateProductOptions = <T extends StringEnum>(
  enumType: T
): Array<InputSelectOption> => {
  const options = generateOptionsFromStringEnum(enumType)
  const filteredOptions = options.filter(
    (option) => option.value !== Enum_Axe_Product_Enum.Vanilla
  )
  filteredOptions.unshift({
    name: 'European Vanilla',
    value: Enum_Axe_Product_Enum.Vanilla
  })

  return filteredOptions
}

export const tableRows: Array<VanillaFieldRow> = [
  {
    rowDescriptor: 'Product',
    rowInputProps: {
      name: 'product',
      placeholder: 'Select Product',
      type: 'comboBox',
      options: [
        {
          name: 'Select Product',
          unavailable: true
        },
        ...generateProductOptions(Enum_Axe_Product_Enum)
      ]
    }
  },
  {
    rowDescriptor: 'CCY Pair',
    rowInputProps: {
      name: 'ccyPair',
      placeholder: 'Select Currency Pair',
      type: 'comboBox',
      options: [
        {
          name: 'Select Currency Pair',
          unavailable: true
        },
        ...generateOptionsFromStringEnum(Enum_Axe_Ccypair_Enum)
      ]
    }
  },
  {
    rowDescriptor: 'Direction',
    rowInputProps: {
      name: 'buySell',
      placeholder: 'Select Direction',
      type: 'comboBox',
      options: [
        {
          name: 'Select Direction',
          unavailable: true
        },
        ...generateOptionsFromStringEnum(Enum_Axe_Buysell_Enum)
      ]
    }
  },
  {
    rowDescriptor: 'Expiration Date',
    rowInputProps: {
      name: 'expiryDate',
      placeholder: '',
      type: 'date'
    }
  },
  {
    rowDescriptor: 'Delivery Date',
    rowDisabled: true,
    rowInputProps: {
      name: 'deliveryDate',
      placeholder: '',
      type: 'date'
    }
  },
  {
    rowDescriptor: 'Cut',
    rowDisabled: true,
    rowInputProps: {
      name: 'cut',
      placeholder: 'Select Cut',
      type: 'comboBox',
      options: []
    }
  },
  {
    rowDescriptor: 'Fix',
    rowDisabled: true,
    rowHidden: true,
    rowInputProps: {
      name: 'fix',
      placeholder: 'Select Fix',
      type: 'comboBox',
      options: []
    }
  },
  {
    rowDescriptor: 'Strike',
    rowInputProps: {
      name: 'strike',
      type: 'number',
      step: 0.01,
      placeholder: '',
      min: 0
    }
  },
  {
    rowDescriptor: 'Option',
    rowInputProps: {
      name: 'callPut',
      placeholder: 'Select Option',
      type: 'listBox',
      options: [
        {
          name: 'Select Option',
          unavailable: true
        },
        ...generateOptionsFromStringEnum(Enum_Axe_Callput_Enum)
      ]
    }
  },
  {
    rowDescriptor: 'Notional',
    rowInputProps: {
      currencySelector: true,
      name: 'notional',
      type: 'number',
      step: 1000000,
      placeholder: '',
      min: 0,
      decimalScale: 0
    }
  },
  {
    rowDescriptor: 'Spot',
    rowRefresh: true,
    rowInputProps: {
      name: 'spot',
      type: 'number',
      step: 0.01,
      placeholder: '',
      min: 0
    }
  },
  {
    rowDescriptor: 'Swaps',
    rowRefresh: true,
    rowInputProps: {
      name: 'swaps',
      type: 'number',
      step: 0.01,
      placeholder: ''
    }
  },
  {
    rowDescriptor: 'Forward',
    rowRefresh: true,
    rowInputProps: {
      name: 'forward',
      type: 'number',
      step: 0.01,
      placeholder: '',
      min: 0
    }
  },
  {
    rowDescriptor: 'Mid. Volatility',
    rowInputProps: {
      name: 'volatility',
      type: 'number',
      step: 0.1,
      placeholder: '',
      min: 0,
      suffix: '%'
    }
  },
  {
    rowDescriptor: 'Pricing Volatility',
    rowInputProps: {
      name: 'pricingVolatility',
      type: 'number',
      step: 0.1,
      placeholder: '',
      min: 0,
      suffix: '%'
    }
  },
  {
    rowDescriptor: 'Hedge Type',
    rowInputProps: {
      name: 'hedgeType',
      placeholder: 'Select Hedge Type',
      type: 'comboBox',
      options: [
        {
          name: 'Select Hedge Type',
          unavailable: true
        },
        ...generateOptionsFromStringEnum(Enum_Axe_Hedgetype_Enum)
      ]
    }
  },
  {
    rowDescriptor: 'Min. Notional',
    rowInputProps: {
      name: 'minimumNotionalAmount',
      type: 'number',
      step: 100000,
      placeholder: '',
      decimalScale: 0
    }
  }
]

export const tableRowGreeks: Array<VanillaFieldRow> = [
  {
    rowDescriptor: 'Premium',
    rowInputProps: {
      currencySelector: true,
      name: 'premium',
      type: 'number',
      placeholder: ''
    }
  },
  {
    rowDescriptor: 'Premium Date',
    rowDisabled: true,
    rowInputProps: {
      name: 'premiumDate',
      placeholder: '',
      type: 'date'
    }
  },
  {
    rowDescriptor: 'Delta',
    rowRefresh: true,
    rowInputProps: {
      name: 'delta',
      type: 'number',
      step: 10000,
      placeholder: '',
      decimalScale: 0
    }
  },
  {
    rowDescriptor: 'Gamma',
    rowDisabled: true,
    rowInputProps: {
      name: 'gamma',
      type: 'number',
      placeholder: ''
    }
  },
  {
    rowDescriptor: 'Vega',
    rowDisabled: true,
    rowInputProps: {
      name: 'vega',
      type: 'number',
      placeholder: ''
    }
  }
]
