'use client'
import React, { useEffect, useRef } from 'react'
import ClientSideLayout from './clientSideLayout'

// This is a global state to keep track of the current URL and if the user has made any changes to the page. It is a plain object because we can't use react context here.
export const urlState = {
  currentUrl: '',
  isDirty: false
}

const TopLevelLeavePageConfirmation: React.FC<
  React.PropsWithChildren & { theme: string | undefined }
> = ({ children, theme }) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const isForward = useRef(false)

  useEffect(() => {
    const popStateHandler = (event: PopStateEvent) => {
      //we need to handle second popstate event after calling history.forward() on cancel
      if (isForward.current) {
        isForward.current = false
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()

        return
      }

      if (urlState.isDirty) {
        event.preventDefault()
        event.stopPropagation()
        event.stopImmediatePropagation()
        isForward.current = true
        urlState.currentUrl = window.location.href.replace(
          window.location.origin,
          ''
        )
        setIsDialogOpen(true)
      }
    }

    window.addEventListener('popstate', popStateHandler)

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (urlState.isDirty) {
        event.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('popstate', popStateHandler)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  return (
    <ClientSideLayout
      theme={theme}
      isDialogOpen={isDialogOpen}
      setIsDialogOpen={setIsDialogOpen}
    >
      {children}
    </ClientSideLayout>
  )
}

export default TopLevelLeavePageConfirmation
