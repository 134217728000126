import { useField } from 'formik'
import { InputProps, NumericInputProps } from '../input'
import Numeric from '../numeric/numeric'
import React from 'react'
import { useInputStateContext } from '../../../context/inputStateContext/inputStateContext'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import useFieldSync from '../../../hooks/useFieldSync/useFieldSync'
import { calculateForward } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import { extractArrayReferenceFromFieldName } from '../../../utils/extractArrayReferenceFromFieldName/extractArrayReferenceFromFieldName'

const Spot = ({ fieldArrayPrefix, ...props }: NumericInputProps) => {
  const { state, dispatch } = useInputStateContext()

  const { arrayIndex } = extractArrayReferenceFromFieldName(props.name)

  const [spotField] = useField(props.name)
  const [swapsField] = useField(generateFieldName('swaps', fieldArrayPrefix))
  const [forwardField, , forwardHelper] = useField(
    generateFieldName('forward', fieldArrayPrefix)
  )

  const isSpotChanging = React.useMemo(
    // Only the first leg should modify the spot
    () => {
      // Temporary fix to ensure functionality for single-leg axes. Once this functionality is removed this can revert to: 'legs[0].spot'
      const key = fieldArrayPrefix ? 'legs[0].spot' : 'spot'

      return Boolean(state[key]?.changing)
    },
    [fieldArrayPrefix, state]
  )

  React.useEffect(() => {
    if (isSpotChanging) {
      const value = calculateForward(spotField.value, swapsField.value)

      if (isNaN(value) || value === parseFloat(forwardField.value)) return

      forwardHelper.setValue(value)

      dispatch({
        type: 'unset_reset_request',
        key: forwardField.name as InputProps['name']
      })
    }
  }, [
    dispatch,
    forwardField.name,
    forwardField.value,
    forwardHelper,
    isSpotChanging,
    spotField.value,
    swapsField.value
  ])

  const { isDisabled } = useFieldSync(props.name)

  return (
    <Numeric
      {...props}
      isRefreshable={arrayIndex === null || arrayIndex === 0}
      onFocus={() =>
        dispatch({
          type: 'set_to_changing',
          key: spotField.name as InputProps['name']
        })
      }
      onBlur={() =>
        dispatch({
          type: 'unset_from_changing',
          key: spotField.name as InputProps['name']
        })
      }
      disabled={props.disabled || isDisabled}
    />
  )
}

export default Spot
