import React from 'react'
import ComboBox, { ComboBoxProps } from '../comboBox'
import { useField } from 'formik'
import { usePrevious } from 'react-use'
import cuts, { CutCurrencies } from '../../../utils/cuts/cuts'
import { InputSelectOption } from '../../../types/inputSelect'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import useFieldSync from '../../../hooks/useFieldSync/useFieldSync'
import { InputVariant } from '../../input/input'

const CutComboBox = ({ fieldArrayPrefix, ...props }: ComboBoxProps) => {
  const [ccyPairField] = useField(
    generateFieldName('ccyPair', fieldArrayPrefix)
  )
  const [options, setOptions] = React.useState(props.options)
  const previousCcyPair = usePrevious(ccyPairField.value)

  React.useEffect(() => {
    if (!ccyPairField.value || ccyPairField.value === previousCcyPair) return

    const ccyPairCuts = cuts[ccyPairField.value as CutCurrencies]
    let options: InputSelectOption[] = []

    if (!ccyPairCuts) {
      options = [
        {
          name: 'Error: No cuts found for this currency pair',
          value: 'error'
        }
      ]
    } else {
      options = ccyPairCuts.map((cut: string) => ({
        name: cut,
        value: cut
      }))
    }

    setOptions([...options])
  }, [ccyPairField.value, options, previousCcyPair])

  const { isDisabled } = useFieldSync(props.name)

  return (
    <ComboBox
      {...props}
      options={options}
      disabled={props.disabled || isDisabled}
      variant={InputVariant.CELL}
    />
  )
}

export default CutComboBox
