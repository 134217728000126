import { useField } from 'formik'
import { InputProps, NumericInputProps } from '../input'
import Numeric from '../numeric/numeric'
import { useInputStateContext } from '../../../context/inputStateContext/inputStateContext'
import React from 'react'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import useFieldSyncBasedOnExpiryDate from '../../../hooks/useFieldSyncBasedOnExpiryDate/useFieldSyncBasedOnExpiryDate'
import { calculateForward } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'

const Swaps = ({ fieldArrayPrefix, ...props }: NumericInputProps) => {
  const { state, dispatch } = useInputStateContext()
  const [spotField] = useField(generateFieldName('spot', fieldArrayPrefix))
  const [forwardField, , forwardHelper] = useField(
    generateFieldName('forward', fieldArrayPrefix)
  )
  const [swapsField] = useField(generateFieldName('swaps', fieldArrayPrefix))

  const { isDisabled } = useFieldSyncBasedOnExpiryDate(props.name)

  const isSwapsChanging = React.useMemo(
    () => Boolean(state[swapsField.name]?.changing),
    [swapsField.name, state]
  )

  React.useEffect(() => {
    if (isSwapsChanging && !isDisabled) {
      const value = calculateForward(spotField.value, swapsField.value)

      if (isNaN(value) || value === parseFloat(forwardField.value)) return

      forwardHelper.setValue(value)

      dispatch({
        type: 'unset_reset_request',
        key: forwardField.name as InputProps['name']
      })
    }
  }, [
    dispatch,
    forwardField.name,
    forwardField.value,
    forwardHelper,
    isDisabled,
    isSwapsChanging,
    spotField.value,
    swapsField.value
  ])

  return (
    <Numeric
      {...props}
      isRefreshable={true}
      onFocus={() =>
        dispatch({
          type: 'set_to_changing',
          key: swapsField.name as InputProps['name']
        })
      }
      onBlur={() =>
        dispatch({
          type: 'unset_from_changing',
          key: swapsField.name as InputProps['name']
        })
      }
      disabled={props.disabled || isDisabled}
    />
  )
}

export default Swaps
