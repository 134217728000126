import { ButtonVariant } from '../../buttons/button/button'
import ConfirmationDialog from '../../dialog/confirmationDialog/confirmationDialog'

interface ConfirmCancelAxeDialogProps {
  onConfirm: () => void
  onCancel: () => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ConfirmCancelAxeDialog({
  onConfirm,
  onCancel,
  open,
  setOpen
}: ConfirmCancelAxeDialogProps) {
  return (
    <ConfirmationDialog
      message="Are you sure you want to cancel? All changes for this axe will be lost permanently."
      onCancel={onCancel}
      onConfirm={() => onConfirm()}
      open={open}
      setOpen={setOpen}
      title="Cancel?"
      confirmButtonVariant={ButtonVariant.ALERT}
    />
  )
}
