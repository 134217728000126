import classNames from 'classnames'
import { useEventClient } from '../../../context/analyticsContext/analyticsContext'

interface BaseProps {
  icon?: React.ReactNode
  iconPosition?: 'leading' | 'trailing'
}

interface ButtonProps
  extends BaseProps,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  as?: 'button'
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

interface SpanProps extends BaseProps, React.HTMLAttributes<HTMLSpanElement> {
  as: 'span'
  onClick?: never
}

export type TextButtonProps = ButtonProps | SpanProps

export default function TextButton({
  as = 'button',
  children,
  icon,
  iconPosition = 'leading',
  ...restProps
}: TextButtonProps) {
  const Component = as
  const { clickEvent } = useEventClient()
  let props = restProps

  if (restProps.onClick) {
    props = {
      ...restProps,
      onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        clickEvent && clickEvent(event)
        restProps.onClick && restProps.onClick(event)
      }
    }
  } else {
    props = {
      ...restProps,
      onClick: clickEvent
    }
  }

  return (
    <Component
      {...props}
      className={classNames('text-[14px] ', props.className, {
        'text-lightBlue': !props.disabled,
        'text-textLightGray cursor-not-allowed': props.disabled
      })}
    >
      <span className="flex gap-1 items-center">
        {iconPosition === 'leading' ? (
          <>
            {icon}
            <span>{children}</span>
          </>
        ) : (
          <>
            <span>{children}</span>
            {icon}
          </>
        )}
      </span>
    </Component>
  )
}
