import Dialog from '../dialog'
import { Form, Formik } from 'formik'
import { graphql } from 'relay-runtime'
import React from 'react'
import { TextArea } from '../../TextArea/textArea'
import Button, { ButtonSize, ButtonVariant } from '../../buttons/button/button'
import ComboBox from '../../comboBox/comboBox'
import DialogInput from '../../input/dialogInput'
import * as Yup from 'yup'
import { useFragment } from 'react-relay'
import { suspendUserDialogFragment$key } from './__generated__/suspendUserDialogFragment.graphql'

import { EMAIL_TYPE } from '../../../types/emailTypes'
import useEmailApi from '../../../hooks/useEmailApi/useEmailApi'

const SuspendUserDialogFragment = graphql`
  fragment suspendUserDialogFragment on usersConnection {
    edges {
      node {
        fullName
        email
      }
    }
  }
`

interface SuspendUserDialogProps {
  setOpenSuspend: React.Dispatch<React.SetStateAction<boolean>>
  openSuspend: boolean
  allSubOrgs: { name: string; id: string }[]
  currentUser: suspendUserDialogFragment$key
}
const schema = Yup.object().shape({
  reporter: Yup.string().required('Field is required'),
  reason: Yup.string().required('Field is required'),
  name: Yup.string().required('Field is required')
})

export default function SuspendUserDialog({
  setOpenSuspend,
  openSuspend,
  allSubOrgs,
  currentUser
}: SuspendUserDialogProps) {
  const [submitting, setSubmitting] = React.useState(false)
  const user = useFragment(SuspendUserDialogFragment, currentUser)
  const { email, fullName } = user.edges[0].node
  const sendEmail = useEmailApi({ setSubmitting, setOpen: setOpenSuspend })

  return (
    <Dialog open={openSuspend} setOpen={setOpenSuspend} title={'Suspend User'}>
      <div className="flex flex-col min-h-[400px]">
        <Formik
          initialValues={{
            name: '',
            reason: '',
            reporter: fullName || ''
          }}
          validationSchema={schema}
          onSubmit={async (values) => {
            await sendEmail({
              emailType: EMAIL_TYPE.SUSPEND,
              name: values.name,
              reason: values.reason,
              reporter: `${values.reporter} ${email}`
            })
            setSubmitting(true)
          }}
          onReset={() => {
            setOpenSuspend(false)
          }}
        >
          {({ errors, touched }) => (
            <Form className="h-full text-sm">
              <div className="flex flex-col gap-6 mt-3">
                <DialogInput
                  name={'reporter'}
                  label={'User'}
                  disabled
                  placeholder={fullName || ''}
                />
                <div className="flex flex-col rounded-lg">
                  <label htmlFor={'name'} className="mb-2">
                    Participant Name
                  </label>
                  <ComboBox<'name'>
                    className="rounded-lg border-borderGray dark:border-darkBlueGray dark:bg-darkBlueGray border-[1px] text-sm"
                    name="name"
                    options={[
                      {
                        unavailable: true,
                        value: '',
                        name: 'Choose participant',
                        id: ''
                      },
                      ...allSubOrgs.map((subOrg) => ({
                        value: subOrg.name,
                        name: subOrg.name,
                        id: subOrg.id
                      }))
                    ]}
                  />
                  {touched.name && errors.name && (
                    <div className="text-red mt-2">{errors.name}</div>
                  )}
                </div>
                <TextArea
                  name={'reason'}
                  error={
                    touched.reason && errors.reason ? errors.reason : undefined
                  }
                  label={'Help us understand'}
                />
                <div className="flex pt-6 pb-2 justify-end">
                  <Button
                    className="w-[84px] mr-2"
                    onClick={() => {
                      setOpenSuspend(false)
                    }}
                    size={ButtonSize.DEFAULT}
                    styleVariant={ButtonVariant.SECONDARY}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" disabled={submitting}>
                    Suspend
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  )
}
