import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useField } from 'formik'
import classNames from 'classnames'
import { InputSelectOption, InputSelectProps } from '../../types/inputSelect'
import { useWorkerContext } from '../../context/marketDataWorkerContext/marketDataWorkerContext'
import { InputProps } from '../input/input'
import { tableClassNames } from '../table/table'

export interface ListBoxProps extends InputSelectProps {
  type?: 'listBox'
}

export default function ListBox({
  options,
  className,
  disabled,
  ...restProps
}: ListBoxProps) {
  const [field, , helper] = useField(restProps.name)
  const { setLastChangedField } = useWorkerContext()
  const selected = options.find((option) => option.value === field.value)

  return (
    <div className={classNames(className, 'w-full h-full')}>
      <Listbox
        // @ts-expect-error className doesn't exist on ListBox props
        className="flex h-full"
        value={field.value ?? ''}
        name={field.name}
        onChange={(option: InputSelectOption) => {
          setLastChangedField(field.name as InputProps['name'])
          helper.setValue(option.value)
        }}
        disabled={disabled}
      >
        {({ value }) => (
          <div className="relative">
            <Listbox.Label className="visually-hidden">
              {field.name}
            </Listbox.Label>
            <Listbox.Button
              className={classNames(
                {
                  'bg-white dark:text-white dark:bg-darkBlue':
                    !disabled && !selected?.unavailable,
                  'bg-disabledInputGray dark:bg-darkBlue text-textLightGray dark:text-textLightGray':
                    disabled,
                  [tableClassNames.cellPadRight]: disabled,
                  [tableClassNames.cellControlPad]: !disabled,
                  'dark:text-textLightGray dark:bg-darkBlue text-textLightGray':
                    selected?.unavailable
                },
                'relative w-full cursor-default rounded-lg text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 text-[12px]',
                tableClassNames.cellPadLeft
              )}
            >
              {selected?.name && (
                <span
                  className={`block truncate ${
                    field.value?.unavailable ? 'opacity-40' : ''
                  }`}
                >
                  {selected?.name}
                </span>
              )}
              {!disabled && (
                <span className="pointer-events-none absolute inset-y-0 right-[2px] flex items-center dark:text-white text-black">
                  <ChevronUpDownIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              )}
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 min-w-full max-h-60 overflow-auto rounded-md bg-white dark:bg-darkBlueGray py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-[12px]">
                {options.map((option, optionId) => (
                  <Listbox.Option
                    key={optionId}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-4',
                        {
                          'bg-fadedBlue dark:bg-royalBlue dark:text-white text-deepBlue':
                            active,
                          'text-textGray dark:text-white': !active,
                          'bg-fadedBlue/40 dark:bg-royalBlue/40':
                            value === option.value
                        }
                      )
                    }
                    value={option}
                    disabled={option.unavailable}
                  >
                    {({ selected }) => (
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        } ${option.unavailable ? 'opacity-40' : ''}`}
                      >
                        {option.name}
                      </span>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  )
}
