import { enum_axe_buysell_enum } from '../../components/rfqBlotterRow/__generated__/rfqBlotterTableRowRfqFragment.graphql'
import { Enum_Axe_Buysell_Enum, Enum_Axe_Callput_Enum } from '../../gql'
import toFixedWithoutZeros from '../toFixedWithoutZeros/toFixedWithoutZeros'
import formatDateFromISO, {
  FormatDateFromISOFormat
} from '../formatDateFromISO/formatDateFromISO'
import { RFQLegFormValues } from '../../components/forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import isUndefined from 'lodash/isUndefined'

/**
 * Calculates remaining notional by subtracting traded notional from total notional.
 */
export const calculateRemainingNotional = (
  notional: number | null | undefined,
  tradedNotional: number | null | undefined
) => (notional ?? 0) - (tradedNotional ?? 0)

/**
 * Calculates adjusted minimum notional amount based on remaining notional and minimum notional amount.
 * If remaining notional is less than minimum notional amount, remaining notional is returned; else, the minimum notional amount is returned.
 */
export const calculateAdjustedMinimumNotionalAmount = (
  remainingNotional: number,
  minimumNotionalAmount: number
) =>
  (remainingNotional ?? 0) < (minimumNotionalAmount ?? 0)
    ? remainingNotional
    : minimumNotionalAmount

/**
 * Returns the direction based on the supplied party. If party isInAuthorSubOrg return direction as is, else return the opposite direction.
 */
export const getPartyBuySell = (
  isInAuthorSubOrg: boolean,
  buySell: enum_axe_buysell_enum | null | undefined
) => {
  if (!buySell) return null

  if (isInAuthorSubOrg) return buySell

  return buySell === Enum_Axe_Buysell_Enum.Buy
    ? Enum_Axe_Buysell_Enum.Sell
    : Enum_Axe_Buysell_Enum.Buy
}

/**
 * Converts a value to million format (without suffix).
 */
export const getMIOValue = (value: number | null | undefined) =>
  typeof value === 'number'
    ? toFixedWithoutZeros(Number(value) / 1000000, 2)
    : null

/**
 * Summarises an array of row values into an identical or concatenated value.
 * If all values in the array are the same, the first value is returned. Otherwise, the values are joined together.
 */
export const summariseToIdenticalOrConcatenated = (
  rowValues: string[],
  // isCompact removes spaces that surround the separator
  isCompact?: boolean
) => {
  let allSame = true
  const firstRowValue = rowValues[0]

  const resultValues = [firstRowValue]

  // Starting the loop from the second value, check if the current value is equal to the first value
  rowValues.slice(1).forEach((rowValue) => {
    resultValues.push(rowValue)

    if (rowValue !== firstRowValue) {
      allSame = false
    }
  })

  return allSame ? firstRowValue : joinSummaryValues(resultValues, isCompact)
}

/**
 * Summarises an array of dates by returning a formatted date string or a custom label.
 */
export const summariseDates = (
  rowValues: string[] | undefined,
  options?: {
    format?: FormatDateFromISOFormat
    summaryLabel?: string
  }
) => {
  if (isUndefined(rowValues)) return ''

  return rowValues.every((value) => value === rowValues[0])
    ? formatDateFromISO(rowValues[0], options?.format)
    : options?.summaryLabel || 'Custom'
}

/**
 * Inverts the sign of a number.
 */
export const invertSign = (num: number) => (num === 0 ? 0 : num * -1)

/**
 * Inverts the sign of a number unless the person is an axe author.
 */
export const invertSignUnlessInAuthorSubOrg = (
  num: number,
  isInAuthorSubOrg: boolean
) => (isInAuthorSubOrg ? num : invertSign(num))

/**
 * Checks if a number is positive.
 */
export const isPositive = (num: number) => num > 0

/**
 * Shortens the given buy/sell string by returning only the first character in uppercase.
 */
export const shortenBuySell = (buySell: Enum_Axe_Buysell_Enum) => {
  return buySell.charAt(0).toUpperCase()
}

/**
 * Shortens the given call/put string by returning only the first character in uppercase.
 */
export const shortenCallPut = (callPut: Enum_Axe_Callput_Enum) => {
  return callPut.charAt(0).toUpperCase()
}

/**
 * Formats a number value in millions (mio) and returns the formatted string with the `mio` suffix.
 */
export const getFormattedMio = (value: number | null | undefined) => {
  if (value === null || value === undefined) return ''

  return `${getMIOValue(value)}mio`
}

export type RowValues = {
  [key in keyof RFQLegFormValues]: RFQLegFormValues[key][]
}

/**
 * Groups values for each specified field across all legs into corresponding arrays. EG:
 * {
 *   expiryDate: ['2022-01-01', '2023-01-02'],
 *   notional: [1000000, 2000000],
 * }
 */
export function getRowValues(
  legs: RFQLegFormValues[],
  rowNames: Array<keyof RFQLegFormValues>
): RowValues {
  return legs.reduce(
    (acc, leg) => {
      rowNames.forEach((rowName) => {
        if (!acc[rowName]) {
          acc[rowName] = []
        }

        const value = leg[rowName]

        acc[rowName]?.push(value as string | number)
      })

      return acc
    },
    rowNames.reduce(
      (acc, rowName) => ({ ...acc, [rowName]: [] }),
      {} as RowValues
    )
  )
}

/**
 * Simply joins values in supplied array separated by a '/'
 */
export const joinSummaryValues = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowValues: any[] | undefined,
  // isCompact removes spaces that surround the separator
  isCompact?: boolean
) => {
  if (isUndefined(rowValues)) return ''

  const separator = isCompact ? '/' : ' / '

  return rowValues.join(separator)
}
