import get from 'lodash/get'
import set from 'lodash/set'
import { InputProps } from '../../../input/input'
import { InputStateContextProps } from '../../../../context/inputStateContext/inputStateContext'
import { FormData } from '../config'

interface SetSameAcrossAllLegsParams {
  inputStateContext: InputStateContextProps
  path: (number | string)[]
  values: FormData
}

const setSameAcrossAllLegs = ({
  inputStateContext: { dispatch, state },
  path,
  values
}: SetSameAcrossAllLegsParams) => {
  const [arrayName, , fieldName] = path
  const valuesToUpdate = {} as FormData

  // Generate an array of indices starting from the second leg.
  // For example, if there are 3 legs, the array will be [1, 2]. If there is only 1 leg, the array will be empty.
  const legIndexesToUpdate = Array.from(
    { length: values.legs.length - 1 },
    (_, i) => i + 1
  )

  if (legIndexesToUpdate.length > 0) {
    const fieldValue = get(values, [arrayName, 0, fieldName])

    // For each leg to update, set the field value to match the first leg's value and mark the field as disabled.
    legIndexesToUpdate.forEach((legIndexToUpdate) => {
      set(valuesToUpdate, [arrayName, legIndexToUpdate, fieldName], fieldValue)

      const name =
        `${arrayName}[${legIndexToUpdate}].${fieldName}` as InputProps['name']

      if (state[name]?.isDisabled) return

      dispatch({ type: 'set_is_disabled', key: name })
    })
  }

  return valuesToUpdate
}

export default setSameAcrossAllLegs
