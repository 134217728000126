'use client'

import React from 'react'
import Table from '../../table/table'
import {
  tableRowGreeks,
  tableRows
} from '../../buttons/createAxeDialogButton/tableRows'
import ButtonGroup from '../../../app/dashboard/trade/components/buttonGroup'
import Button, { ButtonVariant } from '../../buttons/button/button'
import { FieldArray, Form, FormikErrors, useFormikContext } from 'formik'
import RefreshAllButton from '../../buttons/refreshAllButton/refreshAllButton'
import UsersListBox from '../../listBox/usersListBox/usersListBox'
import { usersListBoxFragment$key } from '../../listBox/usersListBox/__generated__/usersListBoxFragment.graphql'
import { AxeFormData } from '../../dialog/editAxeDialog/editAxeDialog'
import { ArrowsPointingInIcon, PlusIcon } from '@heroicons/react/20/solid'
import TextButton from '../../buttons/textButton/textButton'
import PopOverWithText from '../../popOverWithText/popOverWithText'
import { Variant } from '../../popOver/popOver'
import TableHeader from '../../tableHeader/tableHeader'
import formatDateFromISO from '../../../utils/formatDateFromISO/formatDateFromISO'
import { leg } from '../../buttons/createAxeDialogButton/createAxeDialogButton'
import useUpdateSearchParams from '../../../hooks/useUpdateSearchParams/useUpdateSearchParams'
import omit from 'lodash/omit'

interface CreateAxeFormProps {
  readonly setOpen: React.Dispatch<React.SetStateAction<boolean>>
  readonly isMutationInFlight: boolean
  readonly formRef: React.RefObject<HTMLFormElement>
  readonly usersConnection?: usersListBoxFragment$key | usersListBoxFragment$key
  readonly setPanelIndex?: React.Dispatch<React.SetStateAction<number>>
  readonly validateForm: () => Promise<FormikErrors<AxeFormData>>
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleReset: () => void
}

export default function CreateAxeForm({
  setOpen,
  isMutationInFlight,
  formRef,
  usersConnection,
  setPanelIndex,
  validateForm,
  setIsDialogOpen,
  handleReset
}: CreateAxeFormProps) {
  const { isValid, values, dirty } = useFormikContext<AxeFormData>()

  const searchParamsUpdater = useUpdateSearchParams()

  const handleClick = React.useCallback(() => {
    if (dirty) {
      setIsDialogOpen(true)
    } else {
      setOpen(false)
      searchParamsUpdater({ axeId: '', rfqId: '' })
    }
  }, [setIsDialogOpen, dirty, setOpen, searchParamsUpdater])

  const [showSummaryOnly, setShowSummaryOnly] = React.useState(false)
  const disabled = isMutationInFlight

  const maxAxeLegs = 3
  const totalAxeLegs = values.legs?.length || 1

  /**
   * Relationship between spot/forward/swaps is handled on the client.
   * Changes to spot and forward values affects each other.
   * Changes to the swap impact the forward. See the following files:
   * src/components/input/spot/spot.tsx
   * src/components/input/forward/forward.tsx
   * src/components/input/swaps/swaps.tsx
   */

  return (
    <>
      <Form
        className="flex"
        role="form"
        ref={formRef}
        onReset={() => {
          if (!dirty) handleReset()
        }}
      >
        <FieldArray name="legs">
          {({ push, remove }) => (
            <div className="grow flex flex-col gap-4 max-h-[calc(100vh-135px)] min-h-[300px] -mx-3">
              <div className="flex justify-end shrink-0 px-3 z-20 gap-4">
                {totalAxeLegs > 1 && (
                  <TextButton
                    className="grow-0"
                    icon={
                      <ArrowsPointingInIcon
                        height={14}
                        width={14}
                        aria-label="contract icon"
                      />
                    }
                    onClick={() => setShowSummaryOnly(!showSummaryOnly)}
                    type="button"
                  >
                    {showSummaryOnly ? 'Maximise table' : 'Minimise table'}
                  </TextButton>
                )}
                {isValid ? (
                  <TextButton
                    className="grow-0"
                    icon={
                      <PlusIcon height={14} width={14} aria-label="plus icon" />
                    }
                    onClick={() => {
                      if (showSummaryOnly) {
                        setShowSummaryOnly(false)
                      }
                      push(leg)
                    }}
                    type="button"
                    disabled={totalAxeLegs >= maxAxeLegs}
                  >
                    Add new leg
                  </TextButton>
                ) : (
                  <PopOverWithText
                    button={
                      <TextButton
                        as="span"
                        className="grow-0"
                        icon={
                          <PlusIcon
                            height={14}
                            width={14}
                            aria-label="plus icon"
                          />
                        }
                      >
                        Add new leg
                      </TextButton>
                    }
                    popOverText={`Complete leg ${totalAxeLegs} before adding a new leg`}
                    popperOptions={{
                      placement: 'left'
                    }}
                    variant={Variant.ERROR}
                  />
                )}
              </div>
              <div className="flex flex-col overflow-y-auto">
                <div className="px-3 relative">
                  <TableHeader
                    maxColumns={maxAxeLegs}
                    onDelete={(index) => {
                      remove(index)
                    }}
                    onDuplicate={(index) => {
                      push(omit(values.legs[index], 'id'))
                    }}
                    totalColumns={totalAxeLegs}
                    showSummaryOnly={showSummaryOnly}
                    values={values}
                    isSticky
                  />
                  <div className="flex flex-col gap-5">
                    <Table
                      rows={tableRows}
                      isUnderHeader
                      totalColumns={totalAxeLegs}
                      fieldArrayName="legs"
                      showSummaryOnly={showSummaryOnly}
                    />
                    <Table
                      rows={tableRowGreeks}
                      totalColumns={totalAxeLegs}
                      fieldArrayName="legs"
                      showSummaryOnly={showSummaryOnly}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-between shrink-0 px-3 mt-2">
                {/* pl-[9px] is to cater for the table cell horizontal padding + border width */}
                <div className="flex flex-col gap-1 pl-[9px]">
                  <RefreshAllButton />
                  {values.updatedAt && (
                    <p className="text-xs font-semibold text-textLightGray">
                      Last updated:{' '}
                      {formatDateFromISO(values.updatedAt, 'shortDateTime')}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-4">
                  {!!usersConnection && (
                    <div className="flex flex-row justify-center items-center gap-2">
                      <span className="font-semibold text-sm flex-shrink-0">
                        Axed By:
                      </span>
                      <UsersListBox
                        isDisabled={true}
                        name="axeAuthor"
                        users={usersConnection}
                      />
                    </div>
                  )}
                  <ButtonGroup
                    className="justify-end"
                    buttons={[
                      <Button
                        key="resetForm"
                        type="button"
                        onClick={handleClick}
                        styleVariant={ButtonVariant.SECONDARY}
                      >
                        Cancel
                      </Button>,
                      <Button
                        key="proceedForm"
                        onClick={async () => {
                          const validationResult = await validateForm()

                          if (Object.keys(validationResult).length === 0) {
                            setPanelIndex && setPanelIndex(1)
                          }
                        }}
                        disabled={disabled}
                        type="submit"
                      >
                        Proceed
                      </Button>
                    ]}
                  />
                </div>
              </div>
            </div>
          )}
        </FieldArray>
      </Form>
    </>
  )
}
