import ConfirmationDialog from '../confirmationDialog/confirmationDialog'
import { ButtonVariant } from '../../buttons/button/button'

interface ConfirmCancelAxeDialogProps {
  onConfirm: () => void
  onCancel: () => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ConfirmLeavePageDialog({
  onConfirm,
  onCancel,
  open,
  setOpen
}: ConfirmCancelAxeDialogProps) {
  return (
    <ConfirmationDialog
      message="Are you sure you want to leave this page? All changes will be lost permanently."
      onCancel={onCancel}
      onClose={onCancel}
      onConfirm={() => onConfirm()}
      open={open}
      setOpen={setOpen}
      title="Cancel?"
      confirmButtonVariant={ButtonVariant.ALERT}
    />
  )
}
