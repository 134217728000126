/**
 * @generated SignedSource<<7f2fcf353e49cba6320b32453798addd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type clientSideLayoutQuery$variables = {
  id: string;
};
export type clientSideLayoutQuery$data = {
  readonly users_connection: {
    readonly " $fragmentSpreads": FragmentRefs<"colorSchemeContextFragment" | "colorSchemeForwardRefFragment">;
  };
};
export type clientSideLayoutQuery = {
  response: clientSideLayoutQuery$data;
  variables: clientSideLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "clientSideLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "colorSchemeForwardRefFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "colorSchemeContextFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "clientSideLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "colorSchemePreference",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a75dea84f6949d46f1149a900b2b7aa4",
    "id": null,
    "metadata": {},
    "name": "clientSideLayoutQuery",
    "operationKind": "query",
    "text": "query clientSideLayoutQuery(\n  $id: String!\n) {\n  users_connection(where: {id: {_eq: $id}}) {\n    ...colorSchemeForwardRefFragment\n    ...colorSchemeContextFragment\n  }\n}\n\nfragment colorSchemeContextFragment on usersConnection {\n  edges {\n    node {\n      colorSchemePreference\n      id\n    }\n  }\n}\n\nfragment colorSchemeForwardRefFragment on usersConnection {\n  edges {\n    node {\n      colorSchemePreference\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "294797232d5ecb00ae93c52bf026db14";

export default node;
