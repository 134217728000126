import { ArrowPathIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'

interface RefreshIconProps {
  isDisabled: boolean
  isFetching: boolean
  styleVariant?: IconVariant
}

export enum IconVariant {
  PRIMARY = 'primary'
}

export default function RefreshIcon({
  isDisabled,
  isFetching,
  styleVariant
}: RefreshIconProps) {
  return (
    <ArrowPathIcon
      data-testid="refresh-icon"
      title="Refresh icon"
      className={classNames(
        { 'animate-spin': isFetching },
        { 'opacity-20': isDisabled },
        { 'opacity-100': !isDisabled },
        {
          'dark:text-lightBlue text-royalBlue':
            styleVariant === IconVariant.PRIMARY
        },
        'h-5 w-5 transition'
      )}
    />
  )
}
