'use client'

import React from 'react'
import { mergeRefs } from 'react-merge-refs'
import { useDrop } from 'react-dnd'
import { ItemTypes } from '../../../components/tierSelector/constants'
import { useTierSelectorContext } from '../../../components/tierSelector/hooks/tierSelectorContext'
import {
  TierDisclosureVariants,
  ModifiedEntity,
  modifyTiersAfterDropEvent,
  ModifiedMember
} from '../../../components/tierSelector/tierDisclosure/tierDisclosure'
import {
  Entity,
  EntityDisclosure,
  queriedEntities
} from '../../../components/tierSelector/tierList/tierList'
import {
  Tier,
  generateRemovedTierFromState
} from '../../../components/tierSelector/tierSelector'
import { TierSearch, TierSearchVariant } from './tierSearch'
import { PreloadedQuery } from 'react-relay'
import useSubOrgUserData from '../../../components/tierSelector/hooks/useSubOrgUserData'
import { useSubOrgUserDataSubOrganizationUsersQuery } from '../../../components/tierSelector/hooks/__generated__/useSubOrgUserDataSubOrganizationUsersQuery.graphql'

interface TierColumnPros {
  name: string
  entities: Entity[]
  id: string
  setTiersLists: React.Dispatch<React.SetStateAction<Tier[]>>
  isSales: boolean
  subOrgUsersQueryRef: PreloadedQuery<useSubOrgUserDataSubOrganizationUsersQuery>
}

export const TierColumn = ({
  name,
  entities,
  id,
  setTiersLists,
  isSales,
  subOrgUsersQueryRef
}: TierColumnPros) => {
  const buildEntityWithUserData = useSubOrgUserData(subOrgUsersQueryRef)
  const entitiesWithMemberUsers = buildEntityWithUserData(entities)
  const [tierQuery, setTierQuery] = React.useState<string>('')
  const { dispatch } = useTierSelectorContext()
  const [, dropEntity] = useDrop(
    () => ({
      accept: ItemTypes.ENTITY,
      drop: (item: ModifiedEntity) =>
        modifyTiersAfterDropEvent(item, id, dispatch, setTiersLists),
      canDrop: () => !isSales
    }),
    [entitiesWithMemberUsers]
  )

  const [, dropUser] = useDrop(
    () => ({
      accept: ItemTypes.MEMBER,
      canDrop: () => !isSales,
      drop: (item: ModifiedMember) =>
        modifyTiersAfterDropEvent(item, id, dispatch, setTiersLists)
    }),
    [entitiesWithMemberUsers]
  )

  const filteredClients = queriedEntities(entitiesWithMemberUsers, tierQuery)

  return (
    <div className="flex flex-col" ref={mergeRefs([dropEntity, dropUser])}>
      <TierSearch
        name={name}
        clients={filteredClients.length}
        setQuery={setTierQuery}
        query={tierQuery}
      />
      <div className="bg-lightGray dark:bg-darkBlue rounded-xl p-4 mx-2 mt-2 flex-row flex-grow">
        {filteredClients.map((entity, index) => (
          <div
            key={index}
            className="bg-white dark:bg-darkBlueGray rounded-xl p-2 mb-4 "
          >
            <EntityDisclosure
              key={index}
              {...entity}
              query={tierQuery}
              tierId={id}
              variant={TierDisclosureVariants.DEFAULT}
              isSales={isSales}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export const RemovedTierColumn = ({
  name,
  id,
  tiers
}: {
  name: string
  id: string
  tiers: Tier[]
}) => {
  const {
    state: { removed }
  } = useTierSelectorContext()
  const [tierQuery, setTierQuery] = React.useState<string>('')
  const removedEntities: Entity[] = generateRemovedTierFromState(removed, tiers)

  return (
    <div className="flex flex-col mt-4">
      <TierSearch
        name={name}
        clients={removedEntities.length}
        variant={TierSearchVariant.REMOVED}
        setQuery={setTierQuery}
        query={tierQuery}
      />
      <div className="bg-lightGray dark:bg-darkBlue rounded-xl p-4  mt-2 flex-row flex-grow min-h-[500px]">
        {removedEntities.map((entity, index) => (
          <div
            key={index}
            className="bg-white dark:bg-darkBlueGray rounded-xl p-2 mb-4 "
          >
            <EntityDisclosure
              key={index}
              name={entity.name}
              id={entity.id}
              members={entity.members}
              query={''}
              tierId={id}
              variant={TierDisclosureVariants.REMOVED}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
