import inputStateReducer, {
  ReducerAction,
  InputStateReducerState
} from './inputStateReducer'
import React from 'react'

export interface InputStateContextProps {
  state: InputStateReducerState
  dispatch: React.Dispatch<ReducerAction>
}

export const InputStateContext = React.createContext<InputStateContextProps>({
  state: {},
  dispatch: () => undefined
})

export const useInputStateContext = () => React.useContext(InputStateContext)

export default function InputStateContextProvider({
  children,
  initialInputState = {}
}: React.PropsWithChildren<{ initialInputState?: InputStateReducerState }>) {
  const [state, dispatch] = React.useReducer(
    inputStateReducer,
    initialInputState
  )

  return (
    <InputStateContext.Provider
      value={{
        state,
        dispatch
      }}
    >
      {children}
    </InputStateContext.Provider>
  )
}
