import React from 'react'
import { Entity } from './tierList/tierList'
import TierDisclosure, {
  TierDisclosureVariants
} from './tierDisclosure/tierDisclosure'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import TierSelectorContextProvider, {
  OrganizationState,
  useTierSelectorContext
} from './hooks/tierSelectorContext'

export interface Tier {
  name: string
  id: string
  entities: Entity[]
}

interface TierSelectorProps {
  tiers: Tier[]
}

const findAllMembersThatExistInState = (
  entity: Entity,
  stateEntity: OrganizationState
) =>
  entity.members.filter((member) =>
    stateEntity.entities?.find(
      (stateMember) =>
        stateMember.id === member.id ||
        stateMember.id === member.subOrganizationId
    )
  )

/**
 * Generate the "Removed" tier from the state
 * @param state
 * @param tiers
 */
export const generateRemovedTierFromState = (
  removedState: OrganizationState[],
  tiers: Tier[]
): Entity[] => {
  return (
    removedState
      .map((entityState) => {
        let cachedEntity: Entity | null = null

        for (const tier of tiers) {
          const foundEntity = tier.entities.find(
            (entity) => entity.id === entityState.id
          )

          if (foundEntity && cachedEntity) {
            cachedEntity.members = [
              ...cachedEntity.members,
              ...findAllMembersThatExistInState(foundEntity, entityState)
            ]
          }

          // if the entity is found, filter the members to only include the ones that are in the state
          if (foundEntity && !cachedEntity) {
            cachedEntity = {
              ...foundEntity,
              members: findAllMembersThatExistInState(foundEntity, entityState)
            }
          }
        }

        return cachedEntity
      })
      // type assertion required as filter still returns (Entity | null)[] instead of Entity[]
      .filter((entity) => entity) as Entity[]
  )
}

export default function TierSelector({ tiers }: TierSelectorProps) {
  const [tierLists, setTiersLists] = React.useState<Tier[]>(tiers)

  return (
    <DndProvider debugMode backend={HTML5Backend}>
      <TierSelectorContextProvider tiers={tiers} removedSubOrgs={[]}>
        <div className="flex flex-col gap-4">
          {tierLists.map((tier) => (
            <TierDisclosure
              {...tier}
              setTiers={setTiersLists}
              tierId={tier.id}
              key={tier.id}
            />
          ))}
        </div>
        <RemovedTierDisclosure tiers={tierLists} />
      </TierSelectorContextProvider>
    </DndProvider>
  )
}

export function RemovedTierDisclosure({ tiers }: TierSelectorProps) {
  const {
    state: { removed }
  } = useTierSelectorContext()
  const removedEntities: Entity[] = generateRemovedTierFromState(removed, tiers)

  return (
    <div className="mt-4">
      <TierDisclosure
        tierId="removed"
        name="Removed"
        id="removed"
        entities={removedEntities}
        variant={TierDisclosureVariants.REMOVED}
      />
    </div>
  )
}
