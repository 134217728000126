/**
 * @generated SignedSource<<5e092127116d05d12a84811af40c1ce3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createAxeDialogButtonAuthorFragment$data = {
  readonly user: ReadonlyArray<{
    readonly id: string;
    readonly subOrganizationId: any | null | undefined;
  }>;
  readonly " $fragmentType": "createAxeDialogButtonAuthorFragment";
};
export type createAxeDialogButtonAuthorFragment$key = {
  readonly " $data"?: createAxeDialogButtonAuthorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createAxeDialogButtonAuthorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createAxeDialogButtonAuthorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "users",
      "kind": "LinkedField",
      "name": "user",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subOrganizationId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "axe_authors",
  "abstractKey": null
};

(node as any).hash = "fb081c1b43a4c8bc827e973742cc1e8d";

export default node;
