import get from 'lodash/get'
import { InputProps } from '../../../../../../components/input/input'
import { InputStateContextProps } from '../../../../../inputStateContext/inputStateContext'
import {
  MarketDataValues,
  leg1SpotFieldName,
  calculateForward,
  AxeFormValues
} from '../../../../marketDataWorkerContext'

interface CalculateSpotSwapsForwardUsingMarketSwapsParams {
  dispatch: InputStateContextProps['dispatch']
  marketDataValues: MarketDataValues
  sourceLegIndex: number
  values: AxeFormValues
}

const calculateSpotSwapsForwardUsingMarketSwaps = ({
  dispatch,
  marketDataValues,
  sourceLegIndex,
  values
}: CalculateSpotSwapsForwardUsingMarketSwapsParams) => {
  const sourceLegSwapsName: InputProps['name'] = `legs[${sourceLegIndex}].swaps`
  const sourceLegForwardName: InputProps['name'] = `legs[${sourceLegIndex}].forward`

  const spotValueFromFormik = get(values, leg1SpotFieldName)
  const swapsValueFromService = marketDataValues[sourceLegSwapsName]

  dispatch({ type: 'unset_reset_request', key: sourceLegSwapsName })
  dispatch({ type: 'unset_reset_request', key: sourceLegForwardName })

  return {
    [sourceLegSwapsName]: swapsValueFromService,
    [sourceLegForwardName]: calculateForward(
      spotValueFromFormik,
      swapsValueFromService
    )
  }
}

export default calculateSpotSwapsForwardUsingMarketSwaps
