/**
 * @generated SignedSource<<39d1cc87d095459bd0e37aef83e92b2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type tradersListQuery$variables = {
  subOrganizationId: any;
};
export type tradersListQuery$data = {
  readonly users_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly fullName: string | null | undefined;
        readonly id: string;
        readonly subOrganizationId: any | null | undefined;
      };
    }>;
  };
};
export type tradersListQuery = {
  response: tradersListQuery$data;
  variables: tradersListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subOrganizationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Literal",
                "name": "role",
                "value": {
                  "_eq": "Trader"
                }
              },
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "_eq",
                    "variableName": "subOrganizationId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "subOrganizationId"
              }
            ],
            "kind": "ObjectValue",
            "name": "_and"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "usersConnection",
    "kind": "LinkedField",
    "name": "users_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "usersEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "users",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subOrganizationId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "tradersListQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "tradersListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "625c74333d2a23f4d7b278d5504427d7",
    "id": null,
    "metadata": {},
    "name": "tradersListQuery",
    "operationKind": "query",
    "text": "query tradersListQuery(\n  $subOrganizationId: uuid!\n) {\n  users_connection(where: {_and: {role: {_eq: \"Trader\"}, subOrganizationId: {_eq: $subOrganizationId}}}) {\n    edges {\n      node {\n        id\n        fullName\n        subOrganizationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d4a48be67b4f6345228c4603f182354";

export default node;
