import { RFQDialogFormValues } from '../../components/forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import {
  FieldArrayPrefix,
  InputProps,
  VanillaFormNames
} from '../../components/input/input'

export default function generateFieldName(
  fieldName: VanillaFormNames | keyof RFQDialogFormValues,
  fieldArrayPrefix?: FieldArrayPrefix | null
): InputProps['name'] {
  return fieldArrayPrefix ? `${fieldArrayPrefix}.${fieldName}` : fieldName
}
