import { InputProps } from '../../../../../../components/input/input'
import { InputStateContextProps } from '../../../../../inputStateContext/inputStateContext'
import {
  MarketDataValues,
  leg1SpotFieldName,
  AxeFormValues
} from '../../../../marketDataWorkerContext'
import { Axe_Legs } from '../../../../../../gql'
import calculateSwapsForwardUsingSpot from '../../../calculateSwapsForwardUsingSpot/calculateSwapsForwardUsingSpot'

interface CalculateSetSpotSwapsForwardUsingMarketSpotParams {
  dispatch: InputStateContextProps['dispatch']
  marketDataValues: MarketDataValues
  sourceLegIndex: number
  state: InputStateContextProps['state']
  values: AxeFormValues
}

const calculateSpotSwapsForwardUsingMarketSpot = ({
  marketDataValues,
  sourceLegIndex,
  values,
  state,
  dispatch
}: CalculateSetSpotSwapsForwardUsingMarketSpotParams) => {
  const sourceLegSpotName = `legs[${sourceLegIndex}].spot` as InputProps['name']

  const sourceLegSpotValueFromService = marketDataValues[sourceLegSpotName]

  // Loop over each leg and update spot/swaps/forward values
  return values.legs.reduce(
    (acc: MarketDataValues, leg: Axe_Legs, legIndex: number) => {
      const swapsName: InputProps['name'] = `legs[${legIndex}].swaps`
      const forwardName: InputProps['name'] = `legs[${legIndex}].forward`

      // Update the leg 1 spot value using the current leg's spot value from the market data service
      if (legIndex === sourceLegIndex) {
        acc[leg1SpotFieldName] = sourceLegSpotValueFromService
      }

      // For the leg that triggered the change, if market data exists, then update swaps and forward with market data, clear any overtyped state and don't apply any further logic
      if (legIndex === sourceLegIndex && marketDataValues[swapsName]) {
        acc[swapsName] = marketDataValues[swapsName]
        acc[forwardName] = marketDataValues[forwardName]

        dispatch({ type: 'unset_reset_request', key: swapsName })
        dispatch({ type: 'unset_reset_request', key: forwardName })

        return acc
      }

      return {
        ...acc,
        ...calculateSwapsForwardUsingSpot({
          dispatch,
          forwardName,
          legData: leg,
          spotValue: sourceLegSpotValueFromService,
          state,
          swapsName
        })
      }
    },
    {} as MarketDataValues
  )
}

export default calculateSpotSwapsForwardUsingMarketSpot
