import { FieldInputProps, useField } from 'formik'
import { ComboBoxProps } from '../comboBox/comboBox'
import { VanillaFormNames, InputProps, NumericInputProps } from '../input/input'
import { ListBoxProps } from '../listBox/listBox'
import TableRow, { tableValueIsString } from '../tableRow/tableRow'
import React from 'react'
import CCYPairPrecision from '../../utils/ccyPairPrecision/ccyPairPrecision'
import { enum_axe_ccypair_enum } from '../rfqBlotterRow/__generated__/rfqBlotterTableRowRfqFragment.graphql'
import { usePrevious } from 'react-use'
import cuts, { CutCurrencies } from '../../utils/cuts/cuts'
import fixes from '../../utils/fixes/fixes'
import { RFQDialogFormValues } from '../forms/rfq/rfqAxeFormWrapper/rfqAxeFormWrapper'
import { FieldArrayName } from '../input/input'
import classNames from 'classnames'
import filterHedgeTypeOptions from './utils/filterHedgeTypeOptions/filterHedgeTypeOptions'

export type AllPossibleKeys = Record<
  keyof RFQDialogFormValues | VanillaFormNames,
  unknown
>

export interface RowInputValue<T extends keyof AllPossibleKeys>
  extends Omit<InputProps<T>, 'label'> {
  currencySelector?: boolean
  disableCurrencySelector?: boolean
  suffix?: string
}

export enum RowState {
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  DEFAULT = 'DEFAULT'
}

export interface Row<T extends keyof AllPossibleKeys> {
  rowDescriptor: string
  rowInputProps:
    | RowInputValue<T>
    | ComboBoxProps
    | ListBoxProps
    | NumericInputProps
    | string // a table can display form inputs or strings
  rowDisabled?: boolean // A disabled row has specific styling, so applies to the whole row, not just the input
  rowRefresh?: boolean // Allow the user to refresh this value with market data after over-typing
  rowHidden?: boolean // Will prevent the row from being rendered
  rowState?: RowState // Will change the row's border color
}

interface TableProps<T extends keyof AllPossibleKeys> {
  hasButton?: boolean
  isUnderHeader?: boolean
  readonly?: boolean
  rows: Array<Row<T>>
  totalColumns?: number
  fieldArrayName?: FieldArrayName
  showSummaryOnly?: boolean
  collapseRowDescriptors?: boolean
  className?: string
}

const rowsWithModifiablePrecision = ['strike', 'spot', 'forward', 'swaps']

const addPrecisionToInput = (
  row: Row<keyof AllPossibleKeys>,
  ccyPairField: FieldInputProps<CutCurrencies>
) => {
  if (
    !tableValueIsString(row.rowInputProps) &&
    rowsWithModifiablePrecision.includes(row.rowInputProps.name)
  ) {
    const ccyPairPrecisionValues =
      CCYPairPrecision[ccyPairField.value as enum_axe_ccypair_enum]
    // Strike and spot/forward have different precision values
    const step = row.rowInputProps.name.endsWith('strike')
      ? ccyPairPrecisionValues.strikeTogglePrecision
      : ccyPairPrecisionValues.spotForwardTogglePrecision

    return {
      ...row,
      rowInputProps: {
        ...row.rowInputProps,
        // swaps toggle precision is spotForwardTogglePrecision + 2 d.p.
        step: row.rowInputProps.name.endsWith('swaps') ? step / 100 : step
      }
    }
  }

  return row
}

const showHideCutFix = (
  row: Row<keyof AllPossibleKeys>,
  ccyPairField: FieldInputProps<CutCurrencies>
) => {
  if (
    tableValueIsString(row.rowInputProps) ||
    !['cut', 'fix'].includes(row.rowInputProps.name)
  )
    return row

  const isACutCurrency = !!Object.keys(cuts).includes(ccyPairField.value)
  const isAFixCurrency = !!Object.keys(fixes).includes(ccyPairField.value)

  if (row.rowInputProps.name.endsWith('cut')) {
    return {
      ...row,
      rowDisabled: isAFixCurrency,
      rowHidden: isAFixCurrency
    }
  }

  if (row.rowInputProps.name.endsWith('fix')) {
    return {
      ...row,
      rowDisabled: isACutCurrency,
      rowHidden: isACutCurrency
    }
  }
}

export const gridCols = {
  row: [
    'grid-cols-axeForm1Col', // Single leg / summary only
    'grid-cols-axeForm2Col', // Two legs and summary
    'grid-cols-axeForm3Col', // Three legs and summary
    'grid-cols-axeFormAutoCol' // Single leg / auto sized rowDescriptors
  ]
}

export const tableClassNames = {
  container: 'text-[12px] text-darkBlueGray dark:text-white',
  table:
    'divide-y divide-borderGray dark:divide-lightBlue border border-borderGray dark:border-lightBlue',
  rows: (columns: number) => {
    return classNames(
      'divide-x divide-borderGray dark:divide-lightBlue',
      'grid',
      [gridCols.row[columns - 1]]
    )
  },
  headingCell: 'font-semibold flex justify-between',
  stringCell:
    'px-[8px] h-full flex justify-between items-center whitespace-nowrap', // cell padding 8px
  cellHeight: 'leading-[28px]',
  cellPadLeft: 'pl-[8px]', // cell padding 8px
  cellPadRight: 'pr-[8px]', // cell padding 8px
  cellControlPad: 'pr-[22px]', // cell control width 22px
  cellState: {
    default: 'bg-lightGray dark:bg-darkBlue',
    disabled:
      'bg-fadedBlue dark:bg-deepBlue text-textGray dark:text-textLightGray',
    warning:
      'bg-fadedYellow dark:bg-processYellow text-textGray dark:text-white',
    success: 'bg-fadedGreen dark:bg-successGreen text-textGray dark:text-white'
  },
  summaryCell: 'bg-disabledInputGray dark:bg-darkBlue text-textLightGray'
}

export default function Table<T extends keyof AllPossibleKeys>({
  isUnderHeader,
  readonly,
  rows,
  totalColumns = 1,
  fieldArrayName,
  showSummaryOnly = false,
  collapseRowDescriptors,
  className
}: TableProps<T>) {
  const [tableRows, setTableRows] = React.useState<Row<T>[]>(rows)

  // Currently hardcoded to select the first row. If this needs to adapt based on the first completed ccyPair field,
  // this can be revised. Note: Any changes should take into account the upcoming feature (OPT-779) related to shared data
  // handling across multiple legs and associated form logic considerations.
  const [ccyPairField] = useField(
    fieldArrayName ? `${fieldArrayName}[0].ccyPair` : 'ccyPair'
  )
  const prevValue = usePrevious(ccyPairField.value)

  // We need to update the rows in RFQ, but the useEffect won't trigger if ccypair is the same
  // So we need to set the rows again when the rows prop changes
  React.useEffect(() => {
    setTableRows(rows)
  }, [rows])

  // When the ccy pair changes:
  // 1. Modify the precision of the strike and spot/forward inputs
  // 2. Show or hide the cut/fix field
  React.useEffect(() => {
    if (ccyPairField.value === prevValue || !ccyPairField.value) return

    const newRows = tableRows
      .map((row) => addPrecisionToInput(row, ccyPairField))
      .map((row) => filterHedgeTypeOptions(row, ccyPairField))
      .map((row) => showHideCutFix(row, ccyPairField))

    setTableRows(newRows as Row<T>[])
  }, [ccyPairField, ccyPairField.value, prevValue, tableRows])

  return (
    <dl
      className={classNames(
        tableClassNames.container,
        tableClassNames.table,
        'rounded-tl-md rounded-b-md',
        { 'rounded-tr-md': !isUnderHeader },
        className
      )}
    >
      {tableRows
        .filter((row) => !row.rowHidden)
        .map(
          (
            {
              rowDisabled = false,
              rowDescriptor,
              rowInputProps,
              rowRefresh = false,
              rowState = RowState.DEFAULT
            },
            index,
            array
          ) => {
            return (
              <TableRow
                rowState={rowState}
                rowDisabled={rowDisabled}
                rowDescriptor={rowDescriptor}
                rowInputProps={rowInputProps}
                rowRefresh={rowRefresh}
                index={index}
                key={index}
                array={array}
                readonly={readonly}
                totalColumns={totalColumns}
                fieldArrayName={fieldArrayName}
                isUnderHeader={isUnderHeader}
                showSummaryOnly={showSummaryOnly}
                collapseRowDescriptors={collapseRowDescriptors}
              />
            )
          }
        )}
    </dl>
  )
}
