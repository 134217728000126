import { useField } from 'formik'
import React from 'react'
import { InputProps } from '../../components/input/input'
import { extractArrayReferenceFromFieldName } from '../../utils/extractArrayReferenceFromFieldName/extractArrayReferenceFromFieldName'
import isNil from 'lodash/isNil'
import { useInputStateContext } from '../../context/inputStateContext/inputStateContext'

/**
 * Custom React hook to sync the value of a field with the first field in its field array. It provides an isDisabled
 * flag to disable the field if it is not the source field.
 */
const useFieldSync = (currentFieldName: InputProps['name']) => {
  const { state, dispatch } = useInputStateContext()

  const { arrayIndex, fieldName } =
    extractArrayReferenceFromFieldName(currentFieldName)
  const [currentField, , currentFieldHelper] = useField(currentFieldName)
  const [sourceField] = useField(`legs[0].${fieldName}`)

  React.useEffect(() => {
    if (isNil(arrayIndex) || arrayIndex === 0) return

    if (currentField.value === sourceField.value) return

    currentFieldHelper.setValue(sourceField.value)

    if (
      !state[currentField.name]?.containsFetchedValue &&
      state[sourceField.name]?.containsFetchedValue
    ) {
      dispatch({
        type: 'set_contains_fetched_value',
        key: currentField.name as InputProps['name']
      })
    }

    // If the source field is overtyped, then set this field to over-typed
    if (
      !state[currentField.name]?.overTyped &&
      state[sourceField.name]?.overTyped
    ) {
      dispatch({
        type: 'set_over_typed',
        key: currentField.name as InputProps['name']
      })
    }

    // If this field is over-typed, and the source field IS NOT overtyped, then unset the over-typed flag on this field
    if (
      state[currentField.name]?.overTyped &&
      !state[sourceField.name]?.overTyped
    ) {
      dispatch({
        type: 'unset_reset_request',
        key: currentField.name as InputProps['name']
      })
    }
  }, [
    arrayIndex,
    currentField.name,
    currentField.value,
    currentFieldHelper,
    dispatch,
    sourceField.name,
    sourceField.value,
    state
  ])

  return {
    isDisabled: !isNil(arrayIndex) && arrayIndex > 0
  }
}

export default useFieldSync
