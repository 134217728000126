import { useEffect, useState } from 'react'
import { Listbox } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useLazyLoadQuery } from 'react-relay'
import { graphql } from 'relay-runtime'
import {
  tradersListQuery,
  tradersListQuery$data
} from './__generated__/tradersListQuery.graphql'
import {
  ProfileBadgeContent,
  Variant
} from '../../../components/profileBadge/profileBadge'
import classNames from 'classnames'
import { useColorSchemeContext } from '../../../context/colorSchemeContext/colorSchemeContext'

const TradersListQuery = graphql`
  query tradersListQuery($subOrganizationId: uuid!) {
    users_connection(
      where: {
        _and: {
          role: { _eq: "Trader" }
          subOrganizationId: { _eq: $subOrganizationId }
        }
      }
    ) {
      edges {
        node {
          id
          fullName
          subOrganizationId
        }
      }
    }
  }
`

const TradersList = ({
  onTraderSelect,
  subOrganizationId
}: {
  onTraderSelect: (data: { id: string; fullName: string }) => void
  subOrganizationId: string
}) => {
  const { colorScheme } = useColorSchemeContext()

  const data = useLazyLoadQuery<tradersListQuery>(TradersListQuery, {
    subOrganizationId
  }) as tradersListQuery$data

  const traders = data.users_connection.edges

  const [selected, setSelected] = useState(traders[0])

  useEffect(() => {
    if (traders.length > 0) {
      onTraderSelect({
        id: traders[0].node.id,
        fullName: traders[0].node.fullName || ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="z-10">
      <Listbox
        value={selected}
        onChange={(value) => {
          onTraderSelect({
            id: value.node.id,
            fullName: value.node.fullName || ''
          })
          setSelected(value)
        }}
      >
        <Listbox.Button
          className={classNames(
            'relative cursor-default rounded-lg  border-lightBlue border-[1px] ' +
              'min-w-[200px] py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500' +
              ' focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm',
            { 'bg-overlayBlue': colorScheme === 'DARK' },
            { 'bg-white': colorScheme === 'LIGHT' }
          )}
        >
          <span className="block truncate text-sm font-normal">
            {selected?.node?.fullName || 'No traders data'}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="h-5 w-5 text-gray-400 fill-lightBlue" />
          </span>
        </Listbox.Button>

        <Listbox.Options
          className={classNames(
            'absolute z-10 mt-1 max-h-60 border-[1px] min-w-[200px] border-borderGray/50 overflow-auto rounded-md py-1 text-base shadow-lg focus:outline-none sm:text-sm',
            { 'bg-overlayBlue': colorScheme === 'DARK' },
            { 'bg-white': colorScheme === 'LIGHT' }
          )}
        >
          {traders?.map((person, index) => (
            <Listbox.Option
              key={person.node.id}
              className={classNames(
                'cursor-default select-none text-gray-900 font-normal px-4 ',
                { 'hover:bg-lightBlue': colorScheme === 'DARK' },
                { 'hover:bg-fadedBlue': colorScheme === 'LIGHT' }
              )}
              value={person}
            >
              <div
                className={classNames(
                  'flex items-center justify-start gap-2 border-b-borderGray/50 border-b-[1px] h-[36px]',
                  { 'border-b-0': index === traders.length - 1 }
                )}
                title={person.node.fullName || ''}
              >
                <ProfileBadgeContent
                  fullName={person.node.fullName || ''}
                  authorId={person.node.id}
                  userSubOrganizationId={person.node.subOrganizationId || ''}
                  variant={Variant.SMALL}
                />
                <div className={classNames('truncate', {})}>
                  {person.node.fullName}
                </div>
              </div>
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}

export default TradersList
