import set from 'lodash/set'
import { MarketDataValues } from '../../marketDataWorkerContext'

const convertFlatToNestedValues = (values: Partial<MarketDataValues>) => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    set(acc, key, value)

    return acc
  }, {})
}

export default convertFlatToNestedValues
