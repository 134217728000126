import {
  InputProps,
  FieldArrayPrefix,
  FieldArrayName
} from '../../components/input/input'

export function extractArrayReferenceFromFieldName(
  lastChangedField?: InputProps['name']
) {
  const regex = /^((\w+)\[(\d+)\])?\.(\w+)$/
  const match = lastChangedField?.match(regex)

  if (match) {
    return {
      arrayPrefix: match[1] as FieldArrayPrefix,
      arrayName: match[2] as FieldArrayName,
      arrayIndex: parseInt(match[3], 10),
      fieldName: match[4]
    }
  } else {
    return {
      arrayPrefix: null,
      arrayName: null,
      arrayIndex: null,
      fieldName: null
    }
  }
}
