import { generateOptionsFromStringEnum } from '../../components/buttons/createAxeDialogButton/tableRows'
import { Enum_Tenor_Enum } from '../../gql'

/**
 * Due to the way enumeration values are defined by Facebook/GraphQl spec
 * we need to generate the human-readable values for the tenor
 * https://github.com/strapi/strapi/issues/7904
 * @param array
 */
export const normalizeTenorNames = (
  array: ReturnType<typeof generateOptionsFromStringEnum>
) => {
  return array.map((option) => {
    return {
      ...option,
      name: normalizeTenorName(option.name)
    }
  })
}

export const normalizeTenorName = (name: string) => {
  if (name === Enum_Tenor_Enum.ON) {
    return 'O/N'
  }

  return name && name.substring(2)
}
