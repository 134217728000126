import { diff } from 'deep-diff'
import { useFormikContext } from 'formik'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import React, { useRef } from 'react'
import { AxeFormData } from '../dialog/editAxeDialog/editAxeDialog'
import isFieldNamePathArray from './utils/isFieldNamePathArray/isFieldNamePathArray'
import config from './config/config'
import isDiffCausedByAddingNewLeg from './utils/isDiffCausedByAddingNewLeg/isDiffCausedByAddingNewLeg'
import useProcessFieldConfig from './hooks/useProcessFieldConfig/useProcessFieldConfig'

export type FieldNamePathArray = ['legs', number, string]

export type LegPathArray = ['legs']

export type ValidPathArray = FieldNamePathArray | LegPathArray

/**
 * The FormDataProcessor component is responsible for updating data when form values change.
 * It listens for changes in the form and applies the appropriate data manipulation logic.
 */
const FormDataProcessor = ({ children }: { children: React.ReactNode }) => {
  const { setValues, values } = useFormikContext<AxeFormData>()
  const processFieldConfig = useProcessFieldConfig(config)

  const prevValues = useRef(values)

  /**
   * This effect listens for changes in form values and applies data manipulation logic based on the configuration and
   * updates the form values accordingly.
   */
  React.useEffect(() => {
    const valuesDiff = diff(prevValues.current, values)

    // If there are differences between the previous and current values.
    if (valuesDiff && !isEmpty(valuesDiff)) {
      const valuesToUpdate = {}

      valuesDiff.forEach(({ kind, path }) => {
        if (!isFieldNamePathArray(path)) return

        const arrayName = path[0]

        // If the value change is caused by adding a new leg, loop over the first leg and execute all onValuesDiff functions for each field.
        if (isDiffCausedByAddingNewLeg(path, kind)) {
          Object.keys(values.legs[0]).forEach((fieldName) => {
            // The path is the looped field on the first leg.
            const processedField = processFieldConfig([arrayName, 0, fieldName])
            merge(valuesToUpdate, processedField?.values)
          })
        } else {
          const processedField = processFieldConfig(path)
          merge(valuesToUpdate, processedField?.values)
        }
      })

      if (!isEmpty(valuesToUpdate)) {
        const newValues = merge({}, values, valuesToUpdate)
        setValues(newValues)
        prevValues.current = newValues
      } else {
        prevValues.current = values
      }
    }
  }, [prevValues, processFieldConfig, setValues, values])

  return <>{children}</>
}

export default FormDataProcessor
