import React from 'react'
import { useWorkerContext } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'
import { useInputStateContext } from '../../../context/inputStateContext/inputStateContext'
import { useFormikContext } from 'formik'
import RefreshIcon from '../../icons/refreshIcon/refreshIcon'
import { AxeFormData } from '../../dialog/editAxeDialog/editAxeDialog'

export default function RefreshAllButton() {
  const { values } = useFormikContext<AxeFormData>()
  const { getMarketValues, workerFetching } = useWorkerContext()
  const { dispatch } = useInputStateContext()
  const [isFetching, setIsFetching] = React.useState(false)

  const handleRefreshAll = React.useCallback(() => {
    setIsFetching(true)

    dispatch({ type: 'set_reset_request_all', key: 'all' })
    getMarketValues({
      refreshAll: true,
      values
    })
  }, [dispatch, getMarketValues, values, setIsFetching])

  React.useEffect(() => {
    if (!workerFetching) {
      setIsFetching(false)
    }
  }, [workerFetching, setIsFetching])

  // use F9 to refresh all
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'F9') {
        handleRefreshAll()
      }
    }
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleRefreshAll])

  const isDisabled = !values?.legs[0].ccyPair

  return (
    <button
      type="button"
      name="Refresh all data"
      className="gap-1 text-sm font-semibold flex min-h-[30px] items-center"
      onClick={handleRefreshAll}
      disabled={isDisabled}
    >
      <span>Refresh Data</span>
      <RefreshIcon isDisabled={isDisabled} isFetching={isFetching} />
    </button>
  )
}
