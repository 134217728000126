/**
 * @generated SignedSource<<641533df9ebfb02c0a6e50511a3ae4f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createAxeDialogButtonQuery$variables = {
  subOrganizationId: any;
  userId: string;
};
export type createAxeDialogButtonQuery$data = {
  readonly user_main_tiering_connection: {
    readonly " $fragmentSpreads": FragmentRefs<"createAxeDialogButtonTiersFragment">;
  };
  readonly users_connection: {
    readonly " $fragmentSpreads": FragmentRefs<"usersListBoxFragment">;
  };
};
export type createAxeDialogButtonQuery = {
  response: createAxeDialogButtonQuery$data;
  variables: createAxeDialogButtonQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subOrganizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "subOrganizationId"
          }
        ],
        "kind": "ObjectValue",
        "name": "subOrganizationId"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "userId"
          }
        ],
        "kind": "ObjectValue",
        "name": "ownerId"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createAxeDialogButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "usersListBoxFragment"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user_main_tieringConnection",
        "kind": "LinkedField",
        "name": "user_main_tiering_connection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "createAxeDialogButtonTiersFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createAxeDialogButtonQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "usersConnection",
        "kind": "LinkedField",
        "name": "users_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "usersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "users",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullName",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "user_main_tieringConnection",
        "kind": "LinkedField",
        "name": "user_main_tiering_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_main_tieringEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user_main_tiering",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organizationId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subOrganizationId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRemoved",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "organizations",
                    "kind": "LinkedField",
                    "name": "user_main_tiering_organization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayName",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "sub_organizations",
                    "kind": "LinkedField",
                    "name": "user_main_tiering_subOrganization",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3024317a222216fa12fcffb1809c10a7",
    "id": null,
    "metadata": {},
    "name": "createAxeDialogButtonQuery",
    "operationKind": "query",
    "text": "query createAxeDialogButtonQuery(\n  $subOrganizationId: uuid!\n  $userId: String!\n) {\n  users_connection(where: {subOrganizationId: {_eq: $subOrganizationId}}) {\n    ...usersListBoxFragment\n  }\n  user_main_tiering_connection(where: {ownerId: {_eq: $userId}}) {\n    ...createAxeDialogButtonTiersFragment\n  }\n}\n\nfragment createAxeDialogButtonTiersFragment on user_main_tieringConnection {\n  edges {\n    node {\n      id\n      organizationId\n      tier\n      subOrganizationId\n      isRemoved\n      user_main_tiering_organization {\n        displayName\n        id\n      }\n      user_main_tiering_subOrganization {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment usersListBoxFragment on usersConnection {\n  edges {\n    node {\n      fullName\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "83906484a0732109b238229a318a30f6";

export default node;
