import { useField } from 'formik'
import Numeric from '../numeric/numeric'
import { NumericInputProps } from '../input'
import { invertSignUnlessInAuthorSubOrg } from '../../../utils/axeCalculations/axeCalculations'
import { usePartyBasedValue } from '../../../hooks/usePartyBasedValue/usePartyBasedValue'

const PartyBasedNumeric = ({
  fieldArrayPrefix,
  ...props
}: NumericInputProps) => {
  const [deltaField] = useField(props.name)
  const delta = deltaField?.value

  const overrideValue = usePartyBasedValue({
    currentValue: delta,
    calculatePartyBasedValue: ({ currentValue, isInAuthorSubOrg }) => {
      return invertSignUnlessInAuthorSubOrg(currentValue, isInAuthorSubOrg)
    }
  })

  return (
    <Numeric {...props} isRefreshable={true} overrideValue={overrideValue} />
  )
}

export default PartyBasedNumeric
