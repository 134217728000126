import {
  CacheConfig,
  Environment,
  GraphQLResponse,
  Network,
  Observable,
  QueryResponseCache,
  RecordSource,
  RequestParameters,
  Store,
  Variables
} from 'relay-runtime'
import { Client, createClient } from 'graphql-ws'
import Cookies from 'js-cookie'
import { GraphQLClient } from 'graphql-request'
const IS_SERVER = typeof window === typeof undefined
const CACHE_TTL = 5 * 1000 // 5 seconds, to resolve preloaded results

let subscriptionsClient: Client
let environment: Environment

export async function networkFetch(
  request: RequestParameters,
  variables: Variables,
  endpoint: string
): Promise<GraphQLResponse> {
  let headers = {}

  if (IS_SERVER) return { data: {} }

  const token = Cookies.get('accessToken')
  headers = {
    'content-type': 'application/json',
    Authorization: `Bearer ${token}`
  }

  /**
   * Commenting this out because:
   * 1. It's calling hasura with the admin secret. The benefit of this "server" call
   * is that it allows rendering of data on the server.
   * 2. We currently do not know how to access the request/response from within next js
   * in order to get the cookies which were set in the middleware before this was run.
   * 3. We can't access user data for server side rendering.
   */
  // else {
  // headers = {
  //   'content-type': 'application/json',
  //   'x-hasura-admin-secret': 'myadminsecretkey'
  // }
  //}

  const graphqlClient = new GraphQLClient(endpoint as string)
  const json: { errors?: [] } = await graphqlClient.request(
    request.text as string,
    variables,
    headers
  )

  // GraphQL returns exceptions (for example, a missing required variable) in the "errors"
  // property of the response. If any exceptions occurred when processing the request,
  // throw an error to indicate to the developer what went wrong.
  if (Array.isArray(json.errors)) {
    console.error(json.errors)
    throw new Error(
      `Error fetching GraphQL query '${
        request.name
      }' with variables '${JSON.stringify(variables)}': ${JSON.stringify(
        json.errors
      )}`
    )
  }

  return { data: json }
}

export const responseCache: QueryResponseCache | null = IS_SERVER
  ? null
  : new QueryResponseCache({
      size: 100,
      ttl: CACHE_TTL
    })

function createNetwork(httpEndpoint: string, wsEndpoint: string) {
  if (!IS_SERVER) {
    subscriptionsClient = createClient({
      retryAttempts: Infinity,
      shouldRetry: () => true,
      keepAlive: 10000,
      url: wsEndpoint,
      connectionParams: async () => {
        const token = Cookies.get('accessToken')

        return {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      }
    })
  }

  async function fetchResponse(
    params: RequestParameters,
    variables: Variables,
    cacheConfig: CacheConfig
  ) {
    const isQuery = params.operationKind === 'query'
    const cacheKey = params.id ?? params.cacheID
    const forceFetch = cacheConfig && cacheConfig.force

    if (responseCache !== null && isQuery && !forceFetch) {
      const fromCache = responseCache.get(cacheKey, variables)

      if (fromCache !== null) {
        return Promise.resolve(fromCache)
      }
    }

    return networkFetch(params, variables, httpEndpoint)
  }

  function subscribe(operation: RequestParameters, variables: Variables) {
    const obs = Observable.create<GraphQLResponse>((sink) => {
      if (!operation.text) {
        return sink.error(new Error('Operation text cannot be empty'))
      }
      const sub = subscriptionsClient?.subscribe(
        {
          operationName: operation.name,
          query: operation.text,
          variables
        },
        {
          next: (data) => {
            const result = data as GraphQLResponse

            return sink.next(result)
          },
          error: sink.error,
          complete: sink.complete
        }
      )

      return sub
    })

    return obs
  }

  if (IS_SERVER) {
    return Network.create(fetchResponse)
  }

  return Network.create(fetchResponse, subscribe)
}

function createEnvironment(httpEndpoint: string, wsEndpoint: string) {
  return new Environment({
    network: createNetwork(httpEndpoint, wsEndpoint),
    store: new Store(RecordSource.create()),
    isServer: IS_SERVER
  })
}

export function getCurrentEnvironment(
  httpEndpoint: string,
  wsEndpoint: string
) {
  if (environment) {
    return environment
  }
  environment = createEnvironment(httpEndpoint, wsEndpoint)

  return environment
}
