import React from 'react'
import { useField } from 'formik'
import { usePrevious } from 'react-use'
import isUndefined from 'lodash/isUndefined'

interface UsePartyBasedValueProps<T> {
  currentValue: T
  calculatePartyBasedValue: (args: {
    currentValue: T
    isInAuthorSubOrg: boolean
  }) => T
}

export function usePartyBasedValue<T>({
  currentValue,
  calculatePartyBasedValue
}: UsePartyBasedValueProps<T>) {
  const [overrideValue, setOverrideValue] = React.useState<T>()
  const prevOverrideValue = usePrevious(overrideValue)

  const [userSubOrg] = useField('userSubOrganizationId')
  const [ownerSubOrg] = useField('ownerSubOrganizationId')
  const isInAuthorSubOrg = userSubOrg.value === ownerSubOrg.value

  React.useEffect(() => {
    if (
      isUndefined(userSubOrg.value) ||
      isUndefined(ownerSubOrg.value) ||
      isInAuthorSubOrg
    )
      return

    const correctedValue = calculatePartyBasedValue({
      currentValue,
      isInAuthorSubOrg
    })

    if (
      currentValue !== correctedValue &&
      prevOverrideValue !== correctedValue
    ) {
      setOverrideValue(correctedValue)
    }
  }, [
    userSubOrg.value,
    ownerSubOrg.value,
    calculatePartyBasedValue,
    currentValue,
    isInAuthorSubOrg,
    prevOverrideValue
  ])

  return overrideValue
}
