import React from 'react'
import {
  FieldArrayPrefix,
  InputProps,
  NumericInputProps,
  VanillaFormNames
} from '../input'
import { useField, useFormikContext } from 'formik'
import ListBox from '../../listBox/listBox'
import { Axes, Enum_Currency_Premium_Enum } from '../../../gql'
import classNames from 'classnames'
import generateFieldName from '../../../utils/generateFieldName/generateFieldName'
import get from 'lodash/get'
import Numeric from '../numeric/numeric'

const useParseCurrenciesFromCcyPair = (
  fieldArrayPrefix?: FieldArrayPrefix
): Enum_Currency_Premium_Enum[] | false => {
  const { values } = useFormikContext<Axes>()

  const value = get(values, generateFieldName('ccyPair', fieldArrayPrefix))

  /*
   * Every time this function runs, it creates a new array which gets passed to the useEffect below. Because the
   * dependency array there does a diff on the previous and next value, if I make the currency array the dependency it
   * will change on every rerender as this function changes the reference to the array on every pass. To make it stop
   * doing that, I have memoize it.
   */
  const currencies = React.useMemo(() => {
    return (
      value?.length === 6 && [
        value.slice(0, 3) as Enum_Currency_Premium_Enum,
        value.slice(3, 6) as Enum_Currency_Premium_Enum
      ]
    )
  }, [value])

  return currencies
}

export interface NumericCurrencyInput extends Omit<NumericInputProps, 'name'> {
  name: string
  disableCurrencySelector?: boolean
  disableNumeric?: boolean
  useFastKeys?: boolean
}

/**
 * This component can be used for any fields which have their currencies defined by the ccyPair field.
 * The data structure must define a field for the numeric value and a field for the currency
 * with the currency following the numeric value field name with the suffix "Currency".
 * E.g. if the numeric value field name is "notional", the currency field name must be "notionalCurrency".
 */
export default function NumericCurrency({
  className,
  name,
  disableCurrencySelector,
  disableNumeric,
  disabled,
  fieldArrayPrefix,
  useFastKeys,
  ...field
}: NumericCurrencyInput) {
  const [, , currencyHelpers] = useField(`${name}Currency`)
  const currencies = useParseCurrenciesFromCcyPair(fieldArrayPrefix)

  React.useEffect(() => {
    if (currencies) {
      currencyHelpers.setValue(currencies[0])
    }
    // ignoring as helpers isn't memoized, see https://github.com/jaredpalmer/formik/issues/2982
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencies])

  return (
    <div className={classNames(className, 'flex w-full pl-0 py-0')}>
      <div
        className={classNames({
          'shrink-0 basis-[58px] border-r-[1px] dark:border-r-lightBlue border-r-borderGray':
            currencies
        })}
      >
        {currencies && (
          <ListBox
            disabled={disabled || disableCurrencySelector}
            options={currencies.map((currency) => ({
              name: currency,
              value: currency
            }))}
            name={`${name}Currency` as InputProps<VanillaFormNames>['name']}
          />
        )}
      </div>
      <Numeric
        {...field}
        useFastKeys={useFastKeys}
        disabled={disabled || disableNumeric}
        decimalScale={3}
        name={name as InputProps['name']}
      />
    </div>
  )
}
