import { EMAIL_TYPE } from '../../types/emailTypes'
import toast from 'react-hot-toast'
import logBrowserError from '../../utils/logBrowserError/logBrowserError'
import error from 'next/error'
import React, { useCallback } from 'react'

type UseEmailApi = {
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const useEmailApi = ({ setSubmitting, setOpen }: UseEmailApi) => {
  const sendEmail = useCallback(
    async (values: Record<string, string>) => {
      setSubmitting(true)

      try {
        const result = await fetch('/api/email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          body: JSON.stringify({
            emailType: EMAIL_TYPE.SEND_QUERY,
            ...values
          })
        })

        if (result.status === 200) {
          toast.success('Query sent successfully')
          setOpen(false)
        } else {
          toast.error('Query sent failed')
        }
      } catch (err) {
        logBrowserError(error, 'Email sending failed')
        toast.error('Query sent failed')
      } finally {
        setSubmitting(false)
      }
    },
    [setOpen, setSubmitting]
  )

  return sendEmail
}

export default useEmailApi
