import formatDateFromISO from '../../utils/formatDateFromISO/formatDateFromISO'
import classNames from 'classnames'
interface PopOverMenuDateProps {
  date: string
  label: string
}

export const PopOverMenuButton: React.FC<
  React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>
> = ({ children, disabled, ...restProps }) => (
  <button
    className={classNames(
      'flex py-2 text-[12px] font-normal justify-between items-center',
      { 'opacity-40 cursor-not-allowed': disabled }
    )}
    type="button"
    role="button"
    disabled={disabled}
    {...restProps}
  >
    {children}
  </button>
)

export const PopOverMenuDate: React.FC<PopOverMenuDateProps> = ({
  date,
  label
}) => (
  <span className="flex font-normal text-[10px] dark:text-baseGray py-1">
    {label}: {formatDateFromISO(date, 'dd-MMM-yy HH:mm')}
  </span>
)

export const PopOverMenuItemWrapper: React.FC<React.PropsWithChildren> = ({
  children
}) => (
  <div className="w-[190px] bg-white dark:bg-overlayBlue rounded-xl shadow-xl dark:shadow-deepBlue/80">
    <div className="flex text-md px-4 py-2">
      <div className="flex flex-col gap-1 w-full">{children}</div>
    </div>
  </div>
)

export const PopOverMenuLine: React.FC = () => (
  <span className="bg-baseGray dark:bg-lightBlue h-[1px] w-full opacity-20" />
)
