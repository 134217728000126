import { useField } from 'formik'
import { isNil } from 'lodash'
import React from 'react'
import { InputProps } from '../../components/input/input'
import { extractArrayReferenceFromFieldName } from '../../utils/extractArrayReferenceFromFieldName/extractArrayReferenceFromFieldName'
import { useInputStateContext } from '../../context/inputStateContext/inputStateContext'

/**
 * Custom React hook to sync the value of a field with a source field based on matching expiry dates.
 * The source field is determined by comparing the expiry date of the current leg with the expiry dates of other legs.
 * If the current leg's expiry date matches another leg's expiry date, the source field will come from the first leg
 * with the same expiry date. It provides an `isDisabled` flag to disable the field if it is not the source field.
 */
const useFieldSyncBasedOnExpiryDate = (
  currentFieldName: InputProps['name']
) => {
  const { state, dispatch } = useInputStateContext()

  const { arrayIndex, fieldName } =
    extractArrayReferenceFromFieldName(currentFieldName)
  const [isDisabled, setIsDisabled] = React.useState(false)

  const [field1, , field1Helper] = useField(`legs[0].${fieldName}`)
  const [field2, , field2Helper] = useField(`legs[1].${fieldName}`)
  const [field3, , field3Helper] = useField(`legs[2].${fieldName}`)
  const [expiryDateField1] = useField('legs[0].expiryDate')
  const [expiryDateField2] = useField('legs[1].expiryDate')
  const [expiryDateField3] = useField('legs[2].expiryDate')

  React.useEffect(() => {
    // Execute this effect only when there are legs
    if (isNil(arrayIndex)) return

    const expiryDateFieldValues = [
      expiryDateField1.value,
      expiryDateField2.value,
      expiryDateField3.value
    ]

    const fieldValues = [field1.value, field2.value, field3.value]

    const fieldHelpers = [field1Helper, field2Helper, field3Helper]

    const currentLegExpiryDateValue = expiryDateFieldValues[arrayIndex]
    const otherExpiryDateValues = expiryDateFieldValues.toSpliced(arrayIndex, 1)

    // Disable this field and set its value to match the field value of the first leg with the same expiry date as the current leg
    if (
      currentLegExpiryDateValue &&
      otherExpiryDateValues.includes(currentLegExpiryDateValue)
    ) {
      // Determine the index of the first leg with the same expiry date as the current leg and use this as the source field
      const sourceFieldIndex = expiryDateFieldValues.findIndex(
        (value) => value === currentLegExpiryDateValue
      )

      // If the current field is the source, then ensure this this field is enabled; otherwise, disable it and set its value to match the source field
      if (sourceFieldIndex === arrayIndex) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)

        fieldHelpers[arrayIndex].setValue(fieldValues[sourceFieldIndex])

        const currentFieldName = `legs[${arrayIndex}].${fieldName}`
        const sourceFieldName = `legs[${sourceFieldIndex}].${fieldName}`

        if (
          !state[currentFieldName]?.containsFetchedValue &&
          state[sourceFieldName]?.containsFetchedValue
        ) {
          dispatch({
            type: 'set_contains_fetched_value',
            key: currentFieldName as InputProps['name']
          })
        }

        // If the source field is overtyped, then set this field to over-typed
        if (
          !state[currentFieldName]?.overTyped &&
          state[sourceFieldName]?.overTyped
        ) {
          dispatch({
            type: 'set_over_typed',
            key: currentFieldName as InputProps['name']
          })
        }

        // If this field is over-typed, and the source field IS NOT overtyped, then unset the over-typed flag on this field
        if (
          state[currentFieldName]?.overTyped &&
          !state[sourceFieldName]?.overTyped
        ) {
          dispatch({
            type: 'unset_reset_request',
            key: currentFieldName as InputProps['name']
          })
        }
      }
    } else {
      // Ensure this field is enabled if its expiry date is unique among all legs
      setIsDisabled(false)
    }
  }, [
    arrayIndex,
    dispatch,
    expiryDateField1.value,
    expiryDateField2.value,
    expiryDateField3.value,
    field1.value,
    field1Helper,
    field2.value,
    field2Helper,
    field3.value,
    field3Helper,
    fieldName,
    state
  ])

  return {
    isDisabled
  }
}

export default useFieldSyncBasedOnExpiryDate
