import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'

export interface StepperProps {
  panels: Array<React.ReactNode>
  panelIndex?: number
}

const PanelsWithMotion = ({
  panels,
  index
}: {
  index: number
  panels: Array<React.ReactNode>
}) => {
  return panels.map((panel, i) => (
    <React.Fragment key={i}>
      {index === i && (
        <motion.div
          initial={{
            opacity: 0
          }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          {panel}
        </motion.div>
      )}
    </React.Fragment>
  ))
}

const Stepper: React.FC<StepperProps> = ({ panels, panelIndex = 0 }) => {
  const index = panelIndex
    ? Math.max(0, Math.min(panelIndex, panels.length - 1))
    : 0

  return (
    <AnimatePresence initial={false}>
      <PanelsWithMotion index={index} panels={panels} />
    </AnimatePresence>
  )
}

export default Stepper
