import { PlusIcon, MinusIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { useField } from 'formik'
import { useAnimate, motion } from 'framer-motion'
import { useInputStateContext } from '../../../context/inputStateContext/inputStateContext'
import { InputProps, NumericInputProps } from '../input'
import { formatIncrementDecrementValue } from '../numeric/numeric'
import React from 'react'
import isInsideDefinedRange from '../helpers/isInsideDefinedRange'
import { useWorkerContext } from '../../../context/marketDataWorkerContext/marketDataWorkerContext'

interface IncrementInputWrapperProps
  extends Pick<
    NumericInputProps,
    'className' | 'step' | 'max' | 'min' | 'name' | 'disabled'
  > {
  children:
    | ((
        props: (e: React.SyntheticEvent<HTMLOrSVGElement>) => void
      ) => React.ReactNode)
    | React.ReactNode
}

const isDeltaValue = (value: string | number) =>
  typeof value !== 'number' && /^(100|[1-9][0-9]|[1-9])d$/i.test(value)

/*
 * Increment/decrement the delta value. Increase/decrease the value by 1d and stop at 99d or 1d
 */
const getDeltaFieldValue = (
  value: string | number,
  fieldName: string | null
) => {
  const delta = (value as string).slice(0, -1)
  const deltaNumber = Number(delta)

  return fieldName?.startsWith('increment')
    ? `${deltaNumber !== 99 ? deltaNumber + 1 : deltaNumber}d`
    : `${deltaNumber !== 1 ? deltaNumber - 1 : deltaNumber}d`
}

/**
 * Increment/decrement the numeric strike value.
 */
const getNumericFieldValue = (
  fieldName: string | null,
  fieldValue: number,
  step: string | number,
  min?: string | number,
  max?: string | number
) => {
  const newValue = fieldName?.startsWith('increment')
    ? Number(fieldValue || 0) + Number(step)
    : Number(fieldValue || 0) - Number(step)

  return !isInsideDefinedRange(newValue, max, min) ? fieldValue : newValue
}

function IncrementInputWrapper({
  className,
  step = 0,
  max,
  min,
  name,
  disabled,
  children
}: IncrementInputWrapperProps) {
  const [scope, animate] = useAnimate()
  const [focus, setFocus] = React.useState(false)
  const [field, meta, helpers] = useField<number | string>(name)
  const { dispatch } = useInputStateContext()
  const { setLastChangedField } = useWorkerContext()
  const inputRef = React.useRef<HTMLInputElement>(null)

  /**
   * Change handler is used to increment/decrement the value
   * and to handle the input change event
   * @param e Change event
   */
  const onChange = (e: React.SyntheticEvent<HTMLOrSVGElement>) => {
    e.preventDefault()

    setLastChangedField(field.name as InputProps['name'])

    if (!meta.touched) {
      helpers.setTouched(true)
    }

    let fieldValue: string | number
    const fieldName =
      e.target instanceof HTMLButtonElement ? e.target.name : null

    if (isDeltaValue(field.value)) {
      fieldValue = getDeltaFieldValue(field.value, fieldName)
      helpers.setValue(fieldValue)

      return
    }

    fieldValue = Number(field.value)

    // if the value hasn't been filled, allow it to be incremented/decremented. For other NaN values, return.
    if (field.value !== '' && field.value !== undefined && isNaN(fieldValue)) {
      return
    }

    if (e.target instanceof HTMLButtonElement) {
      // If am empty string or number, increment by the step
      fieldValue = getNumericFieldValue(fieldName, fieldValue, step, min, max)
      const formattedValue = formatIncrementDecrementValue(fieldValue, step)
      helpers.setValue(formattedValue.toString())
    }

    dispatch({
      type: 'set_over_typed',
      key: name,
      payload: formatIncrementDecrementValue(fieldValue, step)
    })

    // Focus the input to set the cursor and fire onFocus props if needed
    inputRef.current?.focus()
  }

  const animateOpacity = (opacity: number) => {
    if (disabled) return
    animate(scope.current, { opacity }, { duration: 0.25 })
  }

  React.useEffect(() => {
    if (focus) {
      dispatch({
        type: 'set_to_changing',
        key: name
      })
    } else {
      dispatch({
        type: 'unset_from_changing',
        key: name
      })
    }
  }, [dispatch, focus, name])

  return (
    <span
      onFocus={() => {
        setFocus(true)
        animateOpacity(1)
      }}
      onBlur={() => {
        setFocus(false)
        animateOpacity(0.2)
      }}
      onMouseEnter={() => {
        if (focus) return
        animateOpacity(1)
      }}
      onMouseLeave={() => {
        if (focus) return
        animateOpacity(0.2)
      }}
      className={classNames(className, 'flex w-full ')}
    >
      {typeof children === 'function' ? children(onChange) : children}
      {!disabled && (
        <span ref={scope} className="flex flex-col justify-center opacity-20">
          <motion.button
            type="button"
            name={`increment ${field.name}`}
            whileHover={{
              scale: 1.3,
              transition: { duration: 0.3 }
            }}
            onClick={onChange}
            className="h-[14px] flex justify-center items-center pt-[2px]"
          >
            <PlusIcon className="h-[11px] w-[23px] pointer-events-none" />
            <span className="visually-hidden">{`increment ${field.name}`}</span>
          </motion.button>
          <motion.button
            type="button"
            name={`decrement ${field.name}`}
            whileHover={{
              scale: 1.3,
              transition: { duration: 0.3 }
            }}
            onClick={onChange}
            className="h-[14px] flex justify-center items-center pb-[2px]"
          >
            <MinusIcon className="h-[11px] w-[23px] pointer-events-none" />
            <span className="visually-hidden">{`decrement ${field.name}`}</span>
          </motion.button>
        </span>
      )}
    </span>
  )
}

export default IncrementInputWrapper
