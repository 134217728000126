import isEmpty from 'lodash/isEmpty'
import sum from 'lodash/sum'

const getRatioAdjustedDeltaValues = (
  deltaValues: Array<number>,
  deltaHedge: number
): Array<number> => {
  if (isEmpty(deltaValues)) throw new Error('deltaValues array cannot be empty')
  // on value change of deltaHedge, calculate the delta values so that they always equal the deltaHedge
  // i.e summary delta = deltaHedge
  const legDeltaTotal = deltaValues.reduce((acc, delta) => acc + delta, 0)
  const ratioValue = !legDeltaTotal ? 1 : (deltaHedge / legDeltaTotal) * -1

  const ratioAdjustedDeltaValues = deltaValues.map((delta) => {
    return Math.round(delta * ratioValue)
  })

  // For the last deltaValue, adjust it by the difference between deltaHedge and the sum of the deltaValues
  const sumOfDeltaValues = sum(ratioAdjustedDeltaValues)
  const adjustedDeltaValue = deltaHedge * -1 - sumOfDeltaValues
  ratioAdjustedDeltaValues[ratioAdjustedDeltaValues.length - 1] +=
    adjustedDeltaValue

  return ratioAdjustedDeltaValues
}

export default getRatioAdjustedDeltaValues
