import React from 'react'
import Disclosure from '../../disclosure/disclosure'
import TierList, {
  Entity,
  Member,
  tierIncludesQuery
} from '../tierList/tierList'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Tier } from '../tierSelector'
import classNames from 'classnames'
import {
  ReducerAction,
  useTierSelectorContext
} from '../hooks/tierSelectorContext'
import { ItemTypes } from '../constants'
import { useDrop } from 'react-dnd'
import { mergeRefs } from 'react-merge-refs'
import cloneDeep from 'lodash/cloneDeep'
import { useUser } from '@auth0/nextjs-auth0/client'
import { useEnvVariablesContext } from '../../../context/variablesContext/variablesContext'
import { hasSalesRole } from '../../../utils/roles/roles'

export enum TierDisclosureVariants {
  DEFAULT,
  REMOVED
}

export interface TierDisclosureProps extends Tier {
  readonly setTiers?: React.Dispatch<React.SetStateAction<Tier[]>>
  readonly tierId: string
  readonly variant?: TierDisclosureVariants
  readonly query?: string
}

const getTotalMembers = (entities: Entity[], query?: string) => {
  if (!query) {
    return entities.reduce((acc, entity) => {
      return acc + entity.members.length
    }, 0)
  }

  return entities.reduce((acc, entity) => {
    if (tierIncludesQuery(entity, query)) {
      const filteredMembers = entity.members.filter((member) =>
        member.name.toLowerCase().includes(query.toLowerCase())
      )

      return acc + filteredMembers.length
    }

    return acc
  }, 0)
}

export interface ModifiedEntity extends Entity {
  tierId: string
}

export interface ModifiedMember extends Member {
  tierId: string
  entityId: string
}

export const isEntity = (
  item: ModifiedMember | ModifiedEntity
): item is ModifiedEntity => {
  return 'members' in item
}

export const modifyTiersAfterDropEvent = (
  item: ModifiedEntity | ModifiedMember,
  tierId: string,
  dispatch: React.Dispatch<ReducerAction>,
  setTiers?: React.Dispatch<React.SetStateAction<Tier[]>>
) => {
  if (!setTiers) return

  const itemIsEntity = isEntity(item)
  let updatedTiers
  setTiers((tiers) => {
    const tiersArr: Tier[] = cloneDeep(tiers)

    const oldTierIndex = tiers.findIndex((tier) => tier.id === item.tierId)
    const newTierIndex = tiers.findIndex((tier) => tier.id === tierId)
    const oldTier = cloneDeep(tiers[oldTierIndex])
    const newTier = cloneDeep(tiers[newTierIndex])
    const isSameTier = oldTierIndex === newTierIndex

    if (!oldTier || !newTier || isSameTier) return tiers

    // If dragging an entity, update the old and new tiers
    if (itemIsEntity) {
      // check for duplicates
      const duplicateEntityIndex = newTier.entities.findIndex(
        (entity) => entity.id === item.id
      )

      if (duplicateEntityIndex > -1) {
        newTier.entities[duplicateEntityIndex].members.push(...item.members)
      } else {
        newTier.entities.push(item)
      }
      oldTier.entities = oldTier.entities.filter(
        (entity) => entity.id !== item.id
      )
    }

    if (!itemIsEntity) {
      const filterEntities = (entity: Entity) => entity.id === item.entityId

      const newEntityIndex = newTier.entities.findIndex(filterEntities)
      const oldEntityIndex = oldTier.entities.findIndex(filterEntities)

      // Add the member to the entity if it exists, otherwise create a new entity
      if (newEntityIndex > -1) {
        newTier.entities[newEntityIndex].members.push(item)
      } else {
        const entityCopy = cloneDeep(oldTier.entities[oldEntityIndex])
        entityCopy.members = [item]
        newTier.entities.push(entityCopy)
      }

      // Remove the member from the old entity
      if (oldEntityIndex > -1) {
        oldTier.entities[oldEntityIndex].members = oldTier.entities[
          oldEntityIndex
        ].members.filter((member) => member.id !== item.id)

        // Remove the entity if it has no members
        if (oldTier.entities[oldEntityIndex].members.length === 0) {
          oldTier.entities = oldTier.entities.filter(
            (entity) => entity.id !== item.entityId
          )
        }
      }
    }

    tiersArr[oldTierIndex] = oldTier
    tiersArr[newTierIndex] = newTier

    updatedTiers = tiersArr

    return tiersArr
  })

  if (updatedTiers) {
    // dispatch the new tiers to the context and update the checked state
    dispatch({
      type: 'refresh_tiers',
      payload: {
        tiers: updatedTiers
      }
    })
  }
}

export default function TierDisclosure({
  variant = TierDisclosureVariants.DEFAULT,
  ...props
}: TierDisclosureProps) {
  const { user = { sub: '', org_id: '' } } = useUser()
  const variablesContext = useEnvVariablesContext()

  const isSales = hasSalesRole(user, variablesContext?.rolesKey)

  const { dispatch } = useTierSelectorContext()
  const [, dropEntity] = useDrop(
    () => ({
      accept: ItemTypes.ENTITY,
      canDrop: () => variant === TierDisclosureVariants.DEFAULT,
      drop: (item: ModifiedEntity) =>
        modifyTiersAfterDropEvent(item, props.id, dispatch, props.setTiers)
    }),
    [props.entities]
  )
  const [, dropUser] = useDrop(
    () => ({
      accept: ItemTypes.MEMBER,
      canDrop: () => variant === TierDisclosureVariants.DEFAULT,
      drop: (item: ModifiedMember) =>
        modifyTiersAfterDropEvent(item, props.id, dispatch, props.setTiers)
    }),
    [props.entities]
  )

  const clientCount = getTotalMembers(props.entities, props.query)

  return (
    <Disclosure
      buttonContent={(Button) => (
        <Button
          className={classNames(
            {
              'opacity-30 pointer-events-none':
                variant === TierDisclosureVariants.REMOVED && clientCount === 0
            },
            'border-[1px] border-baseGray dark:border-lightBlue rounded-2xl flex justify-between items-center gap-2 pr-4 w-full shadow-sm'
          )}
        >
          <div className="flex justify-between items-center">
            <div
              className={classNames(
                'border-r-[1px] border-baseGray dark:border-lightBlue py-2 px-4 font-semibold text-md',
                {
                  'text-lightBlue': variant === TierDisclosureVariants.DEFAULT,
                  'text-red': variant === TierDisclosureVariants.REMOVED
                }
              )}
            >
              {props.name}
            </div>
            <span className="visually-hidden">-</span>
            <div className="pl-4 text-sm text-textLightGray">
              {clientCount} clients
            </div>
          </div>
          <ChevronDownIcon height="24px" width="24px" />
        </Button>
      )}
      panelContent={
        <div className="mt-4" ref={mergeRefs([dropEntity, dropUser])}>
          <TierList
            {...props}
            query={props.query}
            variant={variant}
            isSales={isSales}
          />
        </div>
      }
    />
  )
}
