import { graphql, useFragment } from 'react-relay/hooks'
import classNames from 'classnames'
import getIDFromBase64 from '../../utils/getIDFromBase64/getIDFromBase64'
import { useUser } from '@auth0/nextjs-auth0/client'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import { useColorSchemeContext } from '../../context/colorSchemeContext/colorSchemeContext'
import { profileBadgeAxeAuthorFragment$key } from './__generated__/profileBadgeAxeAuthorFragment.graphql'
import { profileBadgeUsersFragment$key } from './__generated__/profileBadgeUsersFragment.graphql'
import { getSubOrgOnClient } from '../../utils/getSubOrg/getSubOrg'

/**
 * These colors should be moved into the tailwind config
 * https://linear.app/optaxe/issue/OPT-328/move-profile-colours-into-tailwind-config
 */
export const authorNameColors = [
  //'bg-traderMoss',
  'bg-traderGreen',
  'bg-traderYellow',
  'bg-traderOrange',
  'bg-traderRed',
  'bg-traderMauve',
  'bg-traderPurple',
  'bg-traderBlue'
]

type ProfileBadgeProps = {
  axeAuthor?: profileBadgeAxeAuthorFragment$key | null
  user?: profileBadgeUsersFragment$key | null
  absolutePosition?: boolean
  authorColor?: string
  onClick?: (id: string) => void
}

export const profileBadgeAxeAuthorFragment = graphql`
  fragment profileBadgeAxeAuthorFragment on axe_authors {
    user {
      id
      fullName
      subOrganizationId
    }
  }
`

export const profileBadgeUsersFragment = graphql`
  fragment profileBadgeUsersFragment on users {
    id
    fullName
    subOrganizationId
  }
`

export const getInitialsFromName = (fullName?: string): string => {
  return fullName
    ? fullName
        .split(' ')
        .splice(0, 2)
        .map((word: string) => word.charAt(0))
        .join('')
        .toUpperCase()
    : ''
}

export enum Variant {
  DEFAULT,
  SMALL
}

export const ProfileBadgeContent = ({
  fullName,
  authorId,
  userSubOrganizationId,
  variant = Variant.DEFAULT
}: {
  fullName: string
  authorId: string
  userSubOrganizationId: string
  variant?: Variant
}) => {
  const { colorScheme } = useColorSchemeContext()
  const { user } = useUser()

  if (user && getSubOrgOnClient(user) !== userSubOrganizationId) {
    const color = colorScheme === 'DARK' ? 'white' : 'black'

    return (
      <div
        className={classNames(
          {
            'h-7 w-7 text-xs': variant === Variant.DEFAULT,
            'h-[20px] w-[20px] text-[9px] min-w-[20px]':
              variant === Variant.SMALL
          },
          'rounded-full flex justify-center items-center text-white font-semibold '
        )}
      >
        <UserCircleIcon color={color} className="opacity-30" />
      </div>
    )
  }
  const allNumbersFromId = authorId?.match(/\d+/g)?.join('')
  const authorColourIndex =
    parseInt(allNumbersFromId || '0') % authorNameColors.length
  const color = authorNameColors[authorColourIndex]

  return (
    <div
      className={classNames(
        {
          'h-7 w-7 text-xs': variant === Variant.DEFAULT,
          'h-[20px] w-[20px] text-[9px] ': variant === Variant.SMALL
        },
        'rounded-full flex justify-center items-center text-white font-semibold',
        color
      )}
    >
      {fullName && getInitialsFromName(fullName)}
    </div>
  )
}

export default function ProfileBadge({
  axeAuthor,
  user,
  onClick
}: ProfileBadgeProps) {
  const axeAuthorfragment = useFragment(
    profileBadgeAxeAuthorFragment,
    axeAuthor
  )
  const userfragment = useFragment(profileBadgeUsersFragment, user)
  const fragment = axeAuthorfragment?.user[0] || userfragment
  const axeAuthorFullName = fragment?.fullName as string
  const authorId: string = fragment?.id || ''
  const userOrganizationId = fragment?.subOrganizationId || ''

  return (
    <div
      className="flex justify-center cursor-pointer"
      onClick={() => onClick && onClick(getIDFromBase64(authorId))}
    >
      <ProfileBadgeContent
        fullName={axeAuthorFullName}
        authorId={authorId}
        userSubOrganizationId={userOrganizationId}
      />
    </div>
  )
}
